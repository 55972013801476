import React, {useEffect, useState} from 'react';
import './createProjectModal.scss'
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {RootState} from '../../reducers';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as offerActions from '../../actions/offers';
import * as clientActions from '../../actions/client'
import PlusIconLink from "../../assets/icons/plus.svg";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import Select from 'react-select';
import locationPinSvg from "../../assets/icons/pin.svg";
import {EInputTextType, Input} from "../../components/Input/Input";
import Tooltip from "rc-tooltip";
import {User} from "../../types/user.type";
import {ArticleInfomation} from "../../types/ArticleInformation.type";
import * as generalActions from '../../actions/generalActions';
import PICFile from "../../assets/icons/pic.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {SelectOption} from "../../types/selectOption.type";
import {Button, EButtonType} from "../../components/Button";
import {Project} from "../../types/Project.type";
import moment from "moment";
import {toast} from "react-toastify";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history: any;
    client: any;
    clientActions: any;
    actions: any;
    intl: any;
    measureUsers: User[];
    articles: ArticleInfomation[];
    closeModalFunction: (boolean) => void
    generalActions: any,
    getModuleData: ()=> void,
    clientId: string
    buttonLoader: boolean
}

const CreateProjectModal = (props: Props) => {
    const [projectDetails, setProjectDetails] = useState<Project[]>([{} as Project])
    const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>()

    useEffect(()=>{
        props.generalActions.fetchArticles();
    },[])

    useEffect(()=>{
        if(props.client?.clientProjects && (props.client?.clientProjects?.length !== 0)){
            setProjectDetails(props?.client?.clientProjects)
        }else{
            setProjectDetails([{} as Project])
        }
    },[props.client.clientProjects])

    function changeProjectDetails(name, value, projectIndex){
        let isValid = true
        if(name === 'measureDate'){
            let dateValue = new Date(value).getTime()
            let todayTime = new Date().getTime()
            let oneDayToMilliseconds = 86400000
            if(dateValue < (todayTime - oneDayToMilliseconds)){
                toast.error('Data nuk duhet te jete ne te kaluaren!')
                return
            }
        }else if (name === 'projectLocation'){
            [...value]?.forEach((letter)=>{
                if(/^[a-zA-Z]+$/.test(letter)){
                    isValid = false
                    return
                }else{
                    isValid = true
                }
            })
        }
        if(isValid){
            let tempArray = [
                ...projectDetails
            ]

            if(name === 'measureDate '){
                tempArray[projectIndex] = {
                    ...tempArray[projectIndex],
                    [name]: value
                }
            }else{
                tempArray[projectIndex] = {
                    ...tempArray[projectIndex],
                    [name]: value
                }
            }

            setProjectDetails(tempArray)
        }
    }

    // function getCurrentPosition(projectIndex: number) {
    //     let currentLocation = ''
    //     let tempArray = [
    //         ...projectDetails
    //     ]
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         currentLocation = `${position.coords.latitude},${position.coords.longitude}`
    //         tempArray[projectIndex] = {
    //             ...tempArray[projectIndex],
    //             projectLocation: currentLocation
    //         }
    //
    //         setProjectDetails(tempArray)
    //     });
    // }

    function getCurrentPosition(projectIndex: number) {
        let currentLocation = ''
        let tempArray = [
            ...projectDetails
        ]
        navigator.geolocation.getCurrentPosition(function (position) {
            currentLocation = `${position.coords.latitude},${position.coords.longitude}`
            tempArray[projectIndex] = {
                ...tempArray[projectIndex],
                projectLocation: currentLocation
            }

            setProjectDetails(tempArray)
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    function removeFile(fileId){
        props.actions?.removeFile('client', fileId).then(()=>{
            props.getModuleData()
        })
    }

    function addNewProject(){
        setProjectDetails((prevState => [
            ...prevState,
            {
                // shipmentDestinationId: 1
            } as Project
        ]))
        setSelectedProjectIndex(0)
    }

    function deleteProject(projectId, projectIndex){
        if(projectId){
            props.clientActions.deleteProject(projectId).then(()=>{
                props.getModuleData()
            })
        }
        let tempArray = projectDetails.filter((project, index)=> projectIndex !== index)
        setProjectDetails(tempArray)
    }

    function saveProjects(){
        let validForm = true
        projectDetails.forEach((project)=>{
            if(!project.projectName){
                toast.error('Emri i projektit eshte obligativ!')
                validForm = false
            }
            if(project.shipmentDestinationId){

            }else{
                toast.error('Destinacioni i projektit eshte obligativ!')
                validForm = false
            }
        })
        // projectDetails.forEach((project)=>{
        //     if(!(project?.orders && project?.orders?.length !== 0)){
        //         toast.error('Porosia eshte obligative!')
        //         validForm = false
        //     }
        // })
        if(validForm){
            props.clientActions.saveClientProjects(projectDetails)
            if(props.clientId){
                let projectsForCreation = []
                let projectsForEdit = []
                projectDetails?.forEach((project)=>{
                    if(!project.id){
                        projectsForCreation.push(project)
                    }else{
                        projectsForEdit.push(project)
                    }
                })
                projectsForCreation?.forEach((project, index)=>{
                    let formDataCreate = new FormData()
                    formDataCreate.append("clientId", props.clientId || '');
                    formDataCreate.append("shipmentDestinationId[]", project?.shipmentDestinationId ? project?.shipmentDestinationId : "1");
                    formDataCreate.append("projectName[]", project?.projectName);
                    formDataCreate.append("measureDate[]", moment(project?.measureDate).format('YYYY-MM-DD') || '');
                    formDataCreate.append("projectLocation[]", project?.projectLocation || '');
                    formDataCreate.append("address[]", project?.address || '');
                    formDataCreate.append("projectComment[]", project?.projectComment || project?.projectComment == 'undefined' ? project?.projectComment : '');
                    project?.orders?.forEach((order)=>{
                        formDataCreate.append(`orders[${index}][]`, order?.value);
                    })
                    project.projectFiles?.forEach((file)=>{
                        formDataCreate.append(`projectFiles[${index}][]`, file?.file);
                    })
                    if(project?.measureAssignee?.value){
                        formDataCreate.append("measureAssignee[]", project?.measureAssignee?.value);
                    }
                    props.clientActions.createProjects(formDataCreate).then((res)=>{
                        if(res?.data?.unique?.name){
                            toast.error(`Projekti "${res?.data?.unique?.name} eksiston, provoni perseri!"`)
                            return
                        }
                    })
                })
                projectsForEdit?.forEach((project)=>{
                    let formDataEdit = new FormData()

                    formDataEdit.append("clientId", props.clientId);
                    formDataEdit.append("projectName", project?.projectName);
                    formDataEdit.append("shipmentDestinationId", project?.shipmentDestinationId ? project?.shipmentDestinationId : "1");
                    formDataEdit.append("measureDate", project?.measureDate || '');
                    formDataEdit.append("projectLocation", project?.projectLocation || '');
                    formDataEdit.append("address", project?.address || '');
                    formDataEdit.append("projectComment", project?.projectComment || '');
                    project?.orders?.forEach((order)=>{
                        formDataEdit.append(`orders[]`, order?.value);
                    })
                    project.projectFiles?.forEach((file)=>{
                        if(file.file){
                            formDataEdit.append(`projectFiles[]`, file?.file);
                        }
                    })
                    if(project?.measureAssignee?.value){
                        formDataEdit.append("measureAssignee", project?.measureAssignee?.value);
                    }

                    props.clientActions.editProject(project?.id, formDataEdit)
                })

                props.getModuleData()
                props.closeModalFunction(false)
            }else{
                props.closeModalFunction(false)
            }
        }
    }

    return (
        <div className={'createProjectModalWrapper'}>
            <div className={'createProjectModalContainer'}>
                <div className={'createProjectModal'}>
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                props.closeModalFunction(false)
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                props.closeModalFunction(false)
                            }}/>
                        </div>
                    </HotkeyButton>
                    <div className={'modalTitle'}>
                        <h3><TranslatedText id="client.scanDocument" defaultMessage="Krijo projektet"/></h3>
                    </div>
                    <div className="projectModalButtonContainer">
                        <div className={'buttonWrapper pr-12'}>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className={'w-100'}
                                onClick={addNewProject}
                                buttonType={EButtonType.PurpleButton}>
                                <TranslatedText id="client.saveContact" defaultMessage="Shto projekt"/>
                            </Button>
                        </div>
                        <div className={'buttonWrapper pl-12'}>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className={'w-100'}
                                onClick={saveProjects}
                                buttonType={EButtonType.PurpleButton}>
                                <TranslatedText id="client.saveContact" defaultMessage="Ruaj projektet"/>
                            </Button>
                        </div>
                    </div>

                    <div className={'projectContainerWrapper'}>
                        {projectDetails && projectDetails.map((project, projectIndex) => {
                            return (
                                <div className={`row projectRow ${selectedProjectIndex === projectIndex ? '': 'collapsed'}`} key={projectIndex} onClick={()=>setSelectedProjectIndex(projectIndex)}>
                                    {projectIndex !== 0 && <hr style={{
                                        backgroundColor: '#252434',
                                        width: '80%',
                                        margin: '0 auto',
                                        opacity: '1',
                                        height: '3px',
                                        borderRadius: '2px'
                                    }}/>}
                                    {projectDetails.length > 1 &&
                                        <div className="x-sign" onClick={() => deleteProject(project?.id, projectIndex)}>
                                            <img src={PlusIconLink} alt="plus icon link"/>
                                        </div>}
                                    <div className="projectInputContainer">
                                        <Input
                                            value={project.projectName || ''}
                                            type="text"
                                            name={'projectName'}
                                            onChange={(name, value) => {
                                                changeProjectDetails(name, value, projectIndex)
                                            }}
                                            inputWrapperClass="w-100 mb-2"
                                            inputTextType={EInputTextType.SimpleInput}>
                                            <TranslatedText id="client.scanDocument" defaultMessage="Emri i projektit"/>
                                        </Input>
                                        <Input
                                            value={project.address || ''}
                                            type="text"
                                            name={'address'}
                                            onChange={(name, value) => {
                                                changeProjectDetails(name, value, projectIndex)
                                            }}
                                            inputWrapperClass="w-100 mb-2"
                                            inputTextType={EInputTextType.SimpleInput}>
                                            <TranslatedText id="client.scanDocument" defaultMessage="Adresa"/>
                                        </Input>
                                        <div className={'projectInput pr-12'}>
                                            <p className={'inputLabelOutside'}>
                                                <TranslatedText id="client.scanDocument" defaultMessage="Data e matjes"/>
                                            </p>
                                            {/*<Input*/}
                                            {/*    onChange={(name, value) => changeProjectDetails(name, value, projectIndex)}*/}
                                            {/*    name="measureDate"*/}
                                            {/*    value={project?.measureDate || ''}*/}
                                            {/*    disabled={project?.measureDate? true: false}*/}
                                            {/*    type="date"*/}
                                            {/*    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${project?.measureDate ? "pointerEventsNone" : 'does-not-have-data'}`}/>*/}
                                            <input type="date" className={`dateInputOfProjectCreationModal`}
                                                   value={project?.measureDate || ''}
                                                   onChange={(e) => changeProjectDetails('measureDate', e.target.value, projectIndex)}/>
                                        </div>

                                        <div className="projectInput pl-12">
                                            <p className={'inputLabelOutside'}><TranslatedText id="client.scanDocument"
                                                                                               defaultMessage="Pergjegjesi i matjes"/>
                                            </p>
                                            <MikaSelect
                                                options={props.measureUsers}
                                                value={project.measureAssignee || {} as SelectOption}
                                                setEntireOptionFunction={(value) => changeProjectDetails('measureAssignee', value, projectIndex)}
                                                placeholder={translatedText({
                                                    id: "client.measure",
                                                    defaultMessage: "Pergjegjesi i matjes"
                                                })}
                                                selectWrapperClassName="w-100 heightFitContent"/>
                                        </div>
                                        <div className="projectInput pr-12">
                                            <p className={'inputLabelOutside'}>
                                                <TranslatedText id="client.scanDocument" defaultMessage="Porosia"/>
                                            </p>
                                            <Select
                                                id="order"
                                                name="order"
                                                options={props.articles}
                                                closeMenuOnSelect={false}
                                                className={project?.orders && project?.orders ? "multiSelect select-input w-100 input-error" : "multiSelect select-input w-100"}
                                                classNamePrefix="react-select"
                                                isMulti
                                                value={project?.orders || ''}
                                                onChange={(value) => changeProjectDetails('orders', value, projectIndex)}
                                                placeholder={translatedText({
                                                    id: "client.order",
                                                    defaultMessage: "Porosia"
                                                })}/>
                                        </div>
                                        <div className={'projectInput'}>
                                            <Input
                                                inputWrapperClass={'heightFitContent pl-12 productLocationInputContainer pt-1'}
                                                name={'projectLocation'}
                                                value={project.projectLocation && (project.projectLocation != 'undefined') ? project.projectLocation : ''}
                                                onChange={(name, value) => changeProjectDetails(name, value, projectIndex)}
                                            >
                                                <TranslatedText id="client.createClient"
                                                                defaultMessage="Lokacioni i klientit"/>
                                                <Tooltip placement="bottom"
                                                         overlay={<span>Gjenero lokacionin momental</span>}>
                                                    <img src={locationPinSvg} alt="" className="pinLogoForMeasure"
                                                         onClick={() => getCurrentPosition(projectIndex)}/>
                                                </Tooltip>
                                                {project.projectLocation &&
                                                    <a href={`https://www.google.com/maps/?q=${project.projectLocation}`}
                                                       className={'googleMapsLocationLink'}
                                                       target='_blank'>{translatedText({
                                                        id: "client.clientLocationOnGoogleMaps",
                                                        defaultMessage: "Kliko per te pare lokacionin ne harte"
                                                    })}</a>}
                                            </Input>
                                        </div>
                                    </div>
                                    <Input
                                        name="projectComment"
                                        label="label"
                                        id="projectComment"
                                        defaultValue=''
                                        inputWrapperClass={"w-100 textarea-wrapper mobile"}
                                        type="textarea"
                                        value={project?.projectComment || ''}
                                        onChange={(name, value) => changeProjectDetails(name, value, projectIndex)}
                                    >
                                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                                        defaultMessage="Komenti i projektit"/>
                                    </Input>
                                    <div className={'d-flex w-100 mt-2 justify-content-between'}>
                                        <div className={'forInnerStock'}>
                                            <label htmlFor={`projectForInnerStock${projectIndex}`}><TranslatedText
                                                id="local"
                                                defaultMessage="Vendore"/></label>
                                            <input type="checkbox" checked={project?.shipmentDestinationId === 1}
                                                   onChange={() => changeProjectDetails('shipmentDestinationId', 1, projectIndex)}
                                                   id={`local${projectIndex}`}/>
                                        </div>
                                        <div className={'forInnerStock'}>
                                            <label htmlFor={`projectForExport${projectIndex}`}><TranslatedText
                                                id="forExport"
                                                defaultMessage="Per export"/></label>
                                            <input type="checkbox" checked={project?.shipmentDestinationId === 2}
                                                   onChange={() => changeProjectDetails('shipmentDestinationId', 2, projectIndex)}
                                                   id={`projectForExport${projectIndex}`}/>
                                        </div>
                                        <div className={'forInnerStock'}>
                                            <label htmlFor={`projectForInnerStock${projectIndex}`}><TranslatedText
                                                id="forInnerStock"
                                                defaultMessage="Per stok te brendshem"/></label>
                                            <input type="checkbox" checked={project?.shipmentDestinationId === 3}
                                                   onChange={() => changeProjectDetails('shipmentDestinationId', 3, projectIndex)}
                                                   id={`projectForInnerStock${projectIndex}`}/>
                                        </div>
                                    </div>
                                    <div className={'mt-3'}>
                                        <MikaFileInput value={project?.projectFiles || []}
                                                       multiple
                                                       setFiles={(value) => changeProjectDetails('projectFiles', value, projectIndex)}/>
                                        {project?.projectFiles && project?.projectFiles?.length !== 0 &&
                                            <div className={'productPictureContainer'}>
                                                {project?.projectFiles.map((pic, index) => {
                                                    return (
                                                        <div
                                                            className="d-flex align-items-center mb-2 cursor-pointer position-relative"
                                                            key={index}>
                                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                                            <a className={'m-0'} href={pic?.fileUrl}
                                                               target="_blank">{pic.fileName}</a>
                                                            <div className="fileRemovalButton"
                                                                 onClick={() => removeFile(pic?.id)}>
                                                                <img src={DifferentColoredPlus} alt="close"/>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        generalActions: bindActionCreators(generalActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(CreateProjectModal as any)));
