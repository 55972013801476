import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';

import './Login.scss'
import {Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';

import LoginBg from "../../assets/img/login-bg.png";
import LoginLogo from "../../assets/icons/MIKA_logo-01.svg";
import {withRouter} from 'react-router';
import Spinner from '../../components/Spinner';
import MikaTextInput from '../../components/Input/MikaTextInput/MikaTextInput';
import * as Yup from 'yup';
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import MikaPasswordInput from "../../components/Input/MikaPasswordInput/MikaPasswordInput";
import {LOGIN_VALIDATION} from "../../utils/validationConfigs";
import {forgotPassword} from "../../actions/generalActions";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";

interface Props {
    intl: any;
    actions: any;
    setLoading: boolean;
    user: any;
    history: any;
    loading: boolean;
    permissions: any;
    buttonLoader: boolean
}

interface State {
    isLoginForm: boolean;
    rememberMe: boolean;
    email: string;
    username: string;
    password: string;
}
interface LoginCredentials {
    username: string;
    password: string;
}

const Login = (props: Props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [isLoginForm, setIsLoginForm] = useState(true)

    useEffect(() => {
        localStorage?.removeItem('mikaSelectedLanguage')
        const isAuthenticated = props.user.isAuthenticated
        if (isAuthenticated) {
            props.actions.isLogin(true);
            props.actions.isAuthenticated(true);
            props.actions.setLoading(true);
            props.actions.getUserPermissionsAndResponsibilities().then((res)=>{
                let permissions = res?.data?.permissions

                if(Object.keys(permissions)[0] !== ("fullPrivileges") && Object.keys(permissions)[0] !== ("superAdmin")){
                    props.history.push(`/dashboard/${Object.keys(permissions)[0]}`)
                }else{
                    props.history.push(`/dashboard/contact`)
                }
            })
        }
    }, [props.user.isAuthenticated])

    function validationScheme() {
        return Yup.object().shape({
            username: LOGIN_VALIDATION,
            password: LOGIN_VALIDATION,
        });
    }

    function validationSchemeForgotPassword() {
        return Yup.object().shape({
            email: LOGIN_VALIDATION,
        });
    }

    function login(values: LoginCredentials) {
        const loginPayload = {
            username: values?.username,
            password: values?.password
        }
        props.actions.login(loginPayload)
    }

    const initialValues = {
        username: '',
        password: ''
    };

    const initialValuesForgotPassword = {
        email: ''
    };

    function loginForm() {
        return (
            <>
                {props.loading ? <Spinner isTransparent /> :
                    <div>
                        <p className="login-text medium-text">
                            <TranslatedText id="login.login_text"
                                              defaultMessage="Miresevini! Ju lutem hyni ne llogarin tuaj."/>
                        </p>
                        <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()}
                                           onSubmit={login}>
                            <MikaTextInput name="username" flat placeholder={translatedText({
                                id: "login.username",
                                defaultMessage: "Emri i perdoruesit"
                            })}/>
                            <MikaPasswordInput name="password" flat placeholder={translatedText({
                                id: "login.password",
                                defaultMessage: "Fjalekalimi"
                            })}/>
                            {/*<MikaSelect placeholder={'Gjuha'} options={[{label: "Shqip", value: 1},{label: 'English', value: 2}]} value={JSON.parse(localStorage?.getItem('mikaSelectedLanguage'))} setEntireOptionFunction={(selectedOption)=>localStorage?.setItem( 'mikaSelectedLanguage', JSON.stringify(selectedOption))}/>*/}
                            <span className="d-flex justify-content-between">
                                <Input
                                    type="checkbox"
                                    inputWrapperClass="checkbox-input toggle-checkbox"
                                    name="rememberMe"
                                    value={rememberMe}
                                    checked={rememberMe}
                                    onChange={(name, value) => setRememberMe(value)}
                                    checkboxLabel={translatedText({
                                        id: "login.rememberMe",
                                        defaultMessage: "Me mbaje ne mend"
                                    })}
                                />
                                <Button
                                    isLoading={props?.buttonLoader}
                                    isDisabled={props?.buttonLoader}
                                    onClick={() => {
                                        setIsLoginForm(false)
                                    }}
                                    className="forgot-password"
                                    buttonType={EButtonType.BasicLink}>
                                    <TranslatedText id="login.forgot_password"
                                                      defaultMessage="Keni harruar fjalekalimin?"/>
                                </Button>
                            </span>
                            <div className="col text-center">
                                {/*<button type="submit"></button>*/}
                                <Button
                                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                                    submit
                                    className="m-auto mt-5"
                                    buttonType={EButtonType.PurpleButton}>
                                    <TranslatedText id="login.login" defaultMessage="Kyquni"/>
                                </Button>
                            </div>
                        </FormikFormWrapper>
                    </div>}
            </>
        )
    }

    function sendEmail(values){
        let payload = {
            email: values.email
        }
        props.actions.forgotPassword(payload)
    }

    function changePasswordForm() {
        return (
            <>
            {props.loading ? <Spinner isTransparent /> :
            <div>
                <FormikFormWrapper onSubmit={sendEmail} initialValues={initialValuesForgotPassword} validationScheme={validationSchemeForgotPassword}>
                    <p className="login-text medium-text">
                        <TranslatedText id="login.reset_pw_text"
                                        defaultMessage="Sheno emailin tuaj dhe ne do te ju dergojme nje linke per te nderruar fjalekalimin tuaj."/>
                    </p>
                    <MikaTextInput name="email" flat placeholder={translatedText({
                        id: "login.email",
                        defaultMessage: "Email"
                    })}/>
                    <div className="col text-center">
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            submit={true}
                            className="m-auto mt-4"
                            buttonType={EButtonType.PurpleButton}>
                            <TranslatedText id="login.send_request" defaultMessage="Dergo kerkesen"/>
                        </Button>
                    </div>
                </FormikFormWrapper>
            </div>
            }
            </>
        )
    }

    return (
        <div className="container-fluid height111vh">
            <div className="height111vh loginContainer">
                <div className="left-side">
                    <img src={LoginBg} className="login-bg" alt="login bg"/>
                </div>
                <div className="right-side d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column">
                        <img className="login-logo" onClick={() => {
                            setIsLoginForm(true)
                        }} src={LoginLogo} alt="login logo"/>
                        {isLoginForm ? loginForm() : changePasswordForm()}
                    </div>
                    <p className="mini-text terms-policy">
                        <TranslatedText id="login.terms_and_privacy"
                                          defaultMessage="Termet e perdorimit. Politikat e privatesise"/>
                    </p>
                </div>
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Login as any)));
