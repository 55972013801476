import React, {useRef} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementsActions from '../../actions/agreements';
import * as offerActions from '../../actions/offers';
import * as measuresActions from '../../actions/measures'
import {useHistory, withRouter} from 'react-router';
import {Button, EButtonType} from '../../components/Button';
import PDFFile from '../../assets/icons/pdf-file.svg'
import PICFile from '../../assets/icons/pic.svg'
import './Agreements.scss'
import {EInputTextType, Input} from '../../components/Input/Input'
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Tooltip from "rc-tooltip";
import {Client} from '../../types/client.type';
import clearFormSvg from '../../assets/icons/archeology.svg'
import MikaSelect from '../../components/BbrosSelect/mikaSelect.component';
import Spinner from '../../components/Spinner';
import {SelectOption} from '../../types/selectOption.type';
import {FileUpload} from '../../types/fileUpload.type';
import {apiCaller} from '../../utils/apiCaller';
import ReactToPrint from 'react-to-print';
import FileIcon from '../../assets/icons/file-text.svg'
import {PrintableAgreement} from './PrintableAgreement'
import arrowDown from '../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg'
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import {useParams} from "react-router-dom";
import {AGREEMENT_STATUSES, isMobile} from "../../constants/constants";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {ParameterTypes} from '../../types/parameter.type';
import {formatNumberPrice, toFixedNumber} from '../../utils/react';
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import moment from "moment";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    offer: any;
    client: any;
    clientActions: any,
    actions: any,
    agreements: any
    agreementsActions: any,
    letter: string,
    clientsByModuleData: Array<Client>,
    allClients: SelectOption[],
    measuresActions: any,
    offerActions: any,
    measures: any,
    permissions: any,
    projectName: string
    buttonLoader: boolean
}

const Agreements = (props: Props) => {

    const language:any = TranslatedText

    const {agreementId, projectId} = useParams<ParameterTypes>();

    const componentRef = useRef()
    const history = useHistory()
    const moduleId = 4;

    const [prepaid, setPrepaid] = useState<any>(0)
    const [loading, setLoading] = useState<boolean>(false);
    const [leftDebt, setLeftDebt] = useState<number>(0)
    const [agreementTotal, setAgreementTotal] = useState<number>(0)
    const [agreementStatus, setAgreementStatus] = useState<SelectOption>({} as SelectOption)
    const [comment, setComment] = useState<string>('')
    const [finalAgreementFile, setFinalAgreementFile] = useState<FileUpload>({} as FileUpload);
    const [signature, setSignature] = useState<FileUpload>({} as FileUpload);
    const [cancelAgreementFile, setCancelAgreementFile] = useState<FileUpload>({} as FileUpload);
    const [annexes, setAnnexes] = useState<FileUpload[]>([] as FileUpload[]);
    const [searchedClient, setSearchedClient] = useState<string>()
    const [clients, setClients] = useState<SelectOption[]>([])
    const [offerId, setOfferId] = useState<number>(null)
    const [isDropDownInfoOpen, setIsDropDownInfoOpen] = useState(false);
    const [agreementAssets, setAgreementAssets] = useState([])
    const [cancellation, setCancellationFiles] = useState([])
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()

    useEffect(() => {
        props.actions.getAllClients(moduleId)
    }, [])

    useEffect(()=>{
        if(props?.agreements?.clientAgreement?.agreementStatus?.value === 5){
            if(agreementStatus?.value === null){
                toast.warning('Ju me kete aksion po e ktheni kete marrveshje ne prodhim !!!')
            }
        }
    },[agreementStatus])

    useEffect(()=>{
        props.actions.getAllProjectInfo('', projectId)
    },[projectId])

    useEffect(() => {
        setLoading(true)
        if (agreementId !== undefined) {
            props.agreementsActions.fetchClientAgreement(agreementId).then(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
            resetFields()
        }
    }, [agreementId])

    useEffect(() => {
        if (Object.keys(props.permissions).length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions?.fullPrivileges, props.permissions?.agreements)) {
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            props.history.push('/dashboard/agreements')
        }
    }, [props.permissions])

    useEffect(() => {
        let calculatedLeftDebt = agreementTotal - prepaid
        setLeftDebt(calculatedLeftDebt)
    }, [prepaid, agreementTotal])

    useEffect(() => {
        if (props?.agreements?.clientAgreement?.id) {
            props.clientActions.fetchClient(props?.agreements?.clientAgreement?.clientId, 3)
            setPrepaid(props.agreements?.clientAgreement?.prepaid)
            if(props.agreements?.clientAgreement?.start){
                setStartDate(new Date(props.agreements?.clientAgreement?.start))
            }else{
                const [day, month, year] = props.agreements?.clientAgreement?.productDates?.latestEndDate.split('/');
                const formattedDate = new Date(`${month}/${day}/${year} 00:00:00 GMT+0100`);
                setStartDate(formattedDate)
            }
            if(props.agreements?.clientAgreement?.end){
                setEndDate(new Date(props.agreements?.clientAgreement?.end))
            }else{
                const [day, month, year] = props.agreements?.clientAgreement?.productDates?.latestEndDate.split('/');
                const formattedDate = new Date(`${month}/${day}/${year} 00:00:00 GMT+0100`);
                setEndDate(formattedDate)
            }
            setAgreementStatus(props.agreements?.clientAgreement?.agreementStatus)
            setComment(props.agreements?.clientAgreement?.comment)
            setOfferId(props.agreements?.clientAgreement?.offerId)
            setAgreementTotal(props.agreements?.clientAgreement?.total)
            let tempArray = []
            let cancelationFiles = []
            props.agreements?.clientAgreement?.agreementFiles?.forEach((file)=>{
                if(file?.signatureWithUrl){
                    tempArray.push({id: file.id, fileUrl: file?.signatureWithUrl, name: file?.signature})
                }else if(file?.finalAgreementWithUrl){
                    tempArray.push({id: file.id, fileUrl: file?.finalAgreementWithUrl, name: file?.finalAgreementName})
                }else if(file?.annexesWithUrl){
                    tempArray.push({id: file.id, fileUrl: file?.annexesWithUrl, name: file?.annexes})
                }else if(file?.cancelationWithUrl){
                    cancelationFiles.push({id: file.id, fileUrl: file?.cancelationWithUrl, name: file?.cancelation})
                }
            })
            setCancellationFiles(cancelationFiles)
            setAgreementAssets(tempArray)
        }
    }, [props.agreements.clientAgreement])


    function saveAgreementData() {
        if(agreementStatus?.value === 5){
            if(!cancelAgreementFile?.file){
                toast.error('Ngarkoni file-in perkates per anulim!')
                return
            }
        }
        setLoading(true)
        let payload = new FormData();
        payload.append("agreementId", props.agreements?.clientAgreement?.id);
        payload.append("clientId", props.client?.client?.id);
        if(agreementStatus?.value === 5){
            payload.append("agreementStatusId", '5');
        }else{
            if(props.agreements?.clientAgreement?.agreementStatus?.value === 5 ){
                if(agreementStatus?.value === null){
                    payload.append("agreementStatusId", '1');
                }
            }
        }
        if(prepaid){
            payload.append("prepaid", prepaid);
        }
        if(startDate){
            payload.append("start", moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            payload.append("end", moment(endDate).format('YYYY-MM-DD'));
        }
        if (finalAgreementFile.file) {
            payload.append("finalAgreement[]", finalAgreementFile.file);
        }
        if (annexes) {
            annexes.forEach((anex, index) => {
                payload.append("annexes[]", anex.file);
            })
        }
        if (signature.file) {
            payload.append("signature[]", signature.file);
        }
        if (cancelAgreementFile.file) {
            payload.append("cancelation[]", cancelAgreementFile.file);
        }
        if(comment !== null){
            payload.append("comment", comment);
        }
        payload.append("isCanceled", '0');

        if (props.agreements?.clientAgreement?.id) {
            props.agreementsActions?.editAgreement(payload).then(() => {
                toast.info('Marrveshja u editua me sukses!')
                history?.goBack()
            })
        } else {
            props.agreementsActions?.createAgreement(payload).then(() => {
                toast.info('Marreveshja u krijua me sukses!')
                history?.goBack()
            })
        }
    }

    useEffect(() => {
        let tempArrayOfClients = props.allClients

        tempArrayOfClients = tempArrayOfClients.filter(val => val.name.includes(searchedClient?.charAt(0).toUpperCase()))

        setClients(tempArrayOfClients)

    }, [searchedClient])

    function getTransferredDataFromModules(clientId) {
        setSearchedClient('')
        setIsDropDownInfoOpen(false)
        props.history.push({pathname: `/dashboard/agreements/create/${clientId}`})
    }

    const agreementStatusDropdown = [{
        value: 5,
        label: 'E anuluar'
    }]

    function removeFile(fileId){
        setLoading(true)
        props.actions.removeFile('agreement', fileId).then(()=>{
            props.agreementsActions.fetchClientAgreement(agreementId).then(() => {
                setLoading(false)
            })
        })
    }

    function setPrepaidValue(value){
        if(value >= 0){
            setPrepaid(value)
        }
    }

    const agreementsForm = () => {
        return (
            <div className="offers-form-wrapper">
                <form className="offers-form container-fluid px-0" onSubmit={(e) => e.preventDefault()}>
                    <div className="agreement-creation-form mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                                <h1 className={`${isMobile ? 'd-none':''} main-title my-0`}>
                                    <TranslatedText id="app.agreements.appendFinalAgreement"
                                                      defaultMessage="KRIJO MARREVESHJE TE RE"/>
                                </h1>
                                <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                            defaultMessage="Pastro Formen"/></span>}>
                                    <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                         alt="create client"/>
                                </Tooltip>
                            </div>
                            <div className="d-flex print-doc align-items-center print-container">
                                <ReactToPrint
                                    trigger={() => <button className="printButton d-flex align-items-center">
                                        <img src={FileIcon} alt="" className="mx-2"/>
                                        <p className="m-0">
                                            <TranslatedText id="app.table.nameSurname"
                                                              defaultMessage="Printo dokumentin"/>
                                        </p>
                                    </button>}
                                    content={() => componentRef.current}
                                />
                                <PrintableAgreement
                                    clientName={props.client?.client?.firstnameAndLastname}
                                    agreementStatus={agreementStatus}
                                    prepaid={prepaid}
                                    leftDebt={Math.ceil(leftDebt * 100) / 100}
                                    total={agreementTotal}
                                    comment={comment}
                                    ref={componentRef}
                                />
                            </div>
                        </div>
                        <div className="col-12 position-relative  m-bottom">
                            {props.client?.client?.firstnameAndLastname &&
                            <div>
                                <a onClick={() => setIsDropDownInfoOpen(!isDropDownInfoOpen)}>
                                    <Input
                                        value={`${props.client?.client?.firstnameAndLastname} ${props.client?.client?.lastName}` || ''}
                                        type="text"
                                        inputWrapperClass="w-100 nameSearch mb-0"
                                        disabled
                                        inputTextType={EInputTextType.SimpleInput}
                                        placeholder={translatedText({
                                            id: "client.nameSurname",
                                            defaultMessage: "Emri dhe mbiemri"
                                        })}>
                                        <img className="arrowdown" src={arrowDown} alt=""
                                        />
                                    </Input>
                                </a>
                            </div>}
                            {searchedClient && <div className="suggestedClients-container-measures ">
                                {clients.length === 0 ?
                                    <p className={'m-0 w-100 text-center'}>{language.noData}</p>
                                    :
                                    clients?.map((client) => {
                                        return (
                                            <div className="suggestedClients" key={client.id}
                                                 onClick={() => getTransferredDataFromModules(client.id)}>
                                                <p className="m-0">{client.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>}
                            {isDropDownInfoOpen &&
                            <DataTransferComponent clientId={props?.client?.client?.id} measureNeeded offerNeeded/>}
                        </div>

                        <MikaSelect
                            placeholder={translatedText({
                                id: "client.selectAgreementStatus",
                                defaultMessage: "Zgjidhni statusin e marrëveshjes"
                            })}
                            value={agreementStatus}
                            setEntireOptionFunction={setAgreementStatus}
                            selectWrapperClassName={`${isMobile ? 'w-100':'w-50'} d-flex flex-column align-items-start justify-content-end`}
                            selectClassName="agreement-status-dropdown"
                            childrenContainerClass='m-0'
                            options={agreementStatusDropdown}>
                            <TranslatedText id="app.agreements.appendFinalAgreement"
                                              defaultMessage="Statusi i marrveshjes"/>
                        </MikaSelect>
                        <div className={`${isMobile? 'd-flex justify-content-start flex-column':'d-flex justify-content-between'}`}>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                min={0}
                                value={prepaid ? toFixedNumber(Number(prepaid), 2) : ''}
                                defaultValue=''
                                onChange={(name, value) => setPrepaidValue(value)}
                                type="number"
                                inputWrapperClass={`inputWrapperbackgroundImageNone ${isMobile? 'col-12': 'col-4'} p-2 mb-0 `}
                                inputTextType={EInputTextType.SimpleInput}
                            >
                                <TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Paradhenie"/>
                            </Input>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                value={toFixedNumber(Number(leftDebt), 2) || ''}
                                defaultValue=''
                                type="number"
                                inputWrapperClass={`p-2 mb-0 ${isMobile? 'col-12': 'col-4'} `}
                                disabled
                                inputTextType={EInputTextType.SimpleInput}
                            >
                                <TranslatedText id="app.agreements.appendFinalAgreement"
                                                  defaultMessage="Borgji i mbetur"/>
                            </Input>

                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                value={toFixedNumber(Number(agreementTotal), 2) || ''}
                                defaultValue=''
                                type="number"
                                inputWrapperClass={`p-2 pe-0 mb-0 ${isMobile? 'col-12': 'col-4'} `}
                                disabled
                                inputTextType={EInputTextType.SimpleInput}
                            >
                                <TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Totali"/>
                            </Input>
                            <div className={'d-flex align-items-end p-2'}>
                                <Input
                                    name="date"
                                    value={startDate ? startDate : ''}
                                    type="date"
                                    onChange={(name, value) => {
                                        setStartDate(value)
                                    }}
                                    inputWrapperClass={`simple-date-input m-0 p-0 pe-0 mb-0 heightFitContent ${startDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="completionDate" defaultMessage="Data e fillimit te finalizimit"/>
                                </Input>
                            </div>
                            <div className={'d-flex align-items-end p-2'}>
                                <Input
                                    name="date"
                                    value={endDate ? endDate : ''}
                                    type="date"
                                    onChange={(name, value) => {
                                        setEndDate(value)
                                    }}
                                    inputWrapperClass={`simple-date-input m-0 p-0 pe-0 mb-0 heightFitContent ${endDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="completionDate" defaultMessage="Data e perfundimit te finalizimit"/>
                                </Input>
                            </div>
                        </div>
                        {props?.agreements?.clientAgreement?.firstUpdate && <div>
                            <h5 className={'agreementCreationDate'}><TranslatedText id="completionDate"
                                                defaultMessage="Data e krijimit te marrveshjes"/>: {props?.agreements?.clientAgreement?.firstUpdate}
                            </h5>
                        </div>}
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"w-100 textarea-wrapper"}
                            type="textarea"
                            value={comment || ''}
                            onChange={(name, value) => setComment(value)}
                        >
                            <TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Komenti"/>
                        </Input>
                    </div>
                    <div className="w-100 row d-flex justify-content-between">
                        <div className={`${isMobile ? 'w-100' : 'w-25'} file-input-container`}>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Shto marrveshjen finale"
                            })} setFiles={setFinalAgreementFile} className={'mt-3'}/>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Shto anekset a marrveshjes"
                            })} multiple setFiles={setAnnexes} className={'mt-3'}/>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Nenshkrimi"
                            })} setFiles={setSignature} className={'mt-3'} accept={'image/*'}/>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Terheqje/Anulim i marrveshjes"
                            })} setFiles={setCancelAgreementFile} className={'mt-3'}/>
                        </div>
                        <div className={`${isMobile ? 'col-12' : 'col-5'}`}>
                            {agreementAssets?.length > 0 &&
                            <>
                                <p className="small-text product-measure-photos">
                                    <TranslatedText id="measures.productPhotos"
                                                      defaultMessage="Assetet e marrveshjes"/>
                                </p>
                                {agreementAssets?.map((item, id) => {
                                    return (
                                        <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={id}>
                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                            <a target={'_blank'} href={item.fileUrl} className="link-pics">{item.name}</a>
                                            <div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>
                                                <img src={DifferentColoredPlus} alt="close" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            }
                            {cancellation?.length > 0 &&
                            <>
                                <p className="small-text product-measure-photos">
                                    <TranslatedText id="measures.productPhotos"
                                                      defaultMessage="Assetet e anulimit te marrveshjes"/>
                                </p>
                                {cancellation?.map((item, id) => {
                                    return (
                                        <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={id}>
                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                            <a target={'_blank'} href={item.fileUrl} className="link-pics">{item.name}</a>
                                            <div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>
                                                <img src={DifferentColoredPlus} alt="close" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            }
                        </div>
                    </div>
                    <div className={`w-100 row d-flex ${isMobile ? 'justify-content-center': 'justify-content-between'}`}>
                        <div className="col-12">
                            <div className={`${isMobile ? 'w-100 hugePaddingBottom': 'row'} d-flex justify-content-end my-3`}>
                                {props.client.client?.id ? <div className={`${isMobile ? 'col-12' : 'col-3'}`}>
                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        submit={true}
                                        className="m-auto w-100"
                                        onClick={saveAgreementData}
                                        buttonType={EButtonType.GreyButton}>
                                        {props.agreements.clientAgreement?.id ?
                                            <TranslatedText id="app.navigation.next" defaultMessage="Ruaj"/> :
                                            <TranslatedText id="app.navigation.next" defaultMessage="Krijo"/>
                                        }
                                    </Button>}
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    function resetFields() {
        setPrepaid(0)
        setLeftDebt(0)
        setAgreementTotal(0)
        setAgreementStatus({
            label: '',
            value: undefined
        })
        setComment('')
        props.agreementsActions.resetAssets()
        props.clientActions.resetClient()
        setIsDropDownInfoOpen(false)
        props.history.push('/dashboard/agreements/create')
    }

    return (
        <div className="wrapper">
            {loading ? <Spinner/> :
                agreementsForm()
            }
        </div>
    )
}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        offer: state.offers,
        measures: state.measures,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Agreements as any)));