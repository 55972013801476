import React, { useState , useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as adminActions from '../../actions/admin';
import { withRouter } from 'react-router';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {ADMIN_COLUMNS, ADMIN_COLUMNS_MOBILE, OPTIONS} from '../../components/Table/TableConstants';
import TableBbros from '../../components/Table/TableBbros';
import { UserObject } from '../../types/user.type';
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    actions: any,
    letter: string,
    admin: any,
    adminActions: any
}

const ShowAllUsers = (props: Props) => {

    useEffect( ()=>{
        props.adminActions.fetchUsers()
    }, [])

    const editUserData = (user: UserObject) => {
        props.history.push({pathname: `/admin/create-user/${user?.id}`})
    }
    
    const deleteUser = (item: UserObject) => {
        props.adminActions.deleteUser(item.id).then(() => {
            props.adminActions.fetchUsers()
        })
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: UserObject) => editUserData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData: UserObject) => deleteUser(rowData)
        },
    ]

    return (
        <div>
            <div className="wrapper">
                <TableBbros
                    title={translatedText({ id:"table.admin", defaultMessage:"Admin"})}
                    data={props.admin.allUsers}
                    columns={isMobile ? ADMIN_COLUMNS_MOBILE : ADMIN_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        adminActions: bindActionCreators(adminActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ShowAllUsers as any)));