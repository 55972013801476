import * as React from 'react';
import { EInputTextType } from '../Input';
import styles from './Textarea.module.scss'; // Import css modules stylesheet as styles

interface IProps {
    isFocused?: boolean;
    isLoading?: boolean;
    name?: string;
    value?: string[] | string | number;
    placeholder?: string;
    type?: string;
    onChange?: any;
    additionalClass?: string;
    isDisabled?: boolean;
    inputTextType?: EInputTextType;
    onBlur?: any;
    autoFocus?: boolean;
    readOnly?: boolean;
    maxLength?: number;
    min?: number;
    max?: number;
    step?: string;
    id?: any;
    cols?: number;
    rows?: number;
    hasFormikValidation?:boolean;
}

interface IState {
    isFocused: boolean;
}

export class Textarea extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFocused: false
        }
    }
    private inputRef!: HTMLTextAreaElement;
    private focusTimeoutId:any;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
            if (this.props.isFocused) {
                this.inputRef.focus();
            }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    private handleChange = (e:any) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        this.props.onChange && this.props.onChange(name, value);
    };

    private handleFocus = (e:any) => {
        this.setState({isFocused: true});
    };

    private handleBlur = (e:any) => {
        this.props.onBlur && this.props.onBlur(e);
    };

    public render() {
        const {
            name,
            value,
            placeholder,
            isFocused,
            isLoading,
            isDisabled,
            readOnly,
            maxLength,
            hasFormikValidation
        } = this.props;

        return (
            <textarea name={name}
                      placeholder={placeholder}
                      maxLength={maxLength}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      readOnly={readOnly}
                      onChange={hasFormikValidation ? this.props.onChange : this.handleChange}
                      className={styles.textarea}
                      ref={(input:any) => isFocused && (this.inputRef = input)}
                      autoFocus={isFocused}
                      disabled={isDisabled || isLoading}
                      autoComplete="off"
                      cols={30}
                      rows={10}
                      value={value}>
            </textarea>
        );
    }
}
