export const exists = (x:any) => Boolean(x);

export const defined = (x:any) => typeof x !== 'undefined' && x !== null;

export const isArray = (x:any) => x.constructor === Array;

export const isString = (x:any) => typeof x === 'string';

export const isNumber = (x:any) => typeof x === 'number';

export const formatDate = (date:any) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}
