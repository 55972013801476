import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './warehouse.scss'
import * as warehouseActions from '../../actions/warehouse'
import TableBbros from '../../components/Table/TableBbros';
import {
    OPTIONS,
    WAREHOUSE_PRODUCTS,
    WAREHOUSE_PRODUCTS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import { ClientObject } from '../../types/client.type';
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";
import moment from "moment";


interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    warehouse: any,
    actions:any;
    letter: string;
    warehouseActions: any
    clientsByModuleData: any;
}

const InnerStock = (props: Props) => {

    const [warehouseProducts, setWarehouseProducts] = useState([])

    useEffect(() => {
        props.warehouseActions.getInnerStockProducts()
    }, [])

    useEffect(() => {
        let tempWarehouseArray = [...props.warehouse.warehouseInnerStock]
        tempWarehouseArray.sort(function compare(a, b) {
            let firstMilliseconds = new Date(b['finishedProduct']).getTime();
            let secondMilliseconds = new Date(a['finishedProduct']).getTime();
            return firstMilliseconds - secondMilliseconds;
        });
        tempWarehouseArray.forEach((warehouse, index)=>{
            tempWarehouseArray[index] = {
                ...tempWarehouseArray[index],
                finishedProduct: warehouse?.finishedProduct ? moment(warehouse?.finishedProduct).format("DD/MM/YYYY hh:mm:ss") : ''
            }
        })
        setWarehouseProducts(tempWarehouseArray)
    }, [props.warehouse.warehouseInnerStock])

    const editMeasureData = (client: ClientObject) => {
        props.history.push({pathname: `/dashboard/warehouse/create/${client?.clientID}`})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editMeasureData(rowData)
        }
    ]

    return(
        <div className="wrapper warehouseContainer">
            <TableBbros
                className={'firstColumnThinner'}
                title={translatedText({id: "table.innerStock", defaultMessage: "Stoku i brendshem"})}
                data={warehouseProducts}
                columns={isMobile ? WAREHOUSE_PRODUCTS_MOBILE : WAREHOUSE_PRODUCTS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        warehouse: state.warehouse,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(InnerStock as any)));