import { injectIntl } from "react-intl";

export function renderIf(
    condition: boolean,
    content: {
        ifTrue: () => JSX.Element | JSX.Element[];
        ifFalse: () => JSX.Element | JSX.Element[];
    }
): JSX.Element | JSX.Element[] {
    if (condition) {
        return content.ifTrue();
    } else {
        return content.ifFalse();
    }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
    condition: boolean,
    content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
    if (condition) {
        return content();
    }
    return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
    condition: boolean,
    content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
    if (!condition) {
        return content();
    }
    return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
    data: T,
    content: (data: T) => JSX.Element
): JSX.Element | null {
    if (typeof data !== 'undefined' && data !== null) {
        return content(data);
    } else {
        return null;
    }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
    data: T,
    content: {
        isDefined: (data: T) => JSX.Element | JSX.Element[];
        isNotDefined: () => JSX.Element | JSX.Element[];
    }
): JSX.Element | JSX.Element[] {
    if (typeof data !== 'undefined' && data !== null) {
        return content.isDefined && content.isDefined(data);
    } else {
        return content.isNotDefined && content.isNotDefined();
    }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
    key: string | number,
    callbacks: IDictionary<() => JSX.Element>,
    defaultCallback?: () => JSX.Element
): JSX.Element | null {
    if (callbacks[key]) {
        return callbacks[key]();
    } else {
        if (defaultCallback) {
            return defaultCallback();
        } else {
            return null;
        }
    }
}

export const parseDate = (str:any, type?:any) => {
    if (/\d{4}\-\d{2}\-\d{2}/gm.test(str)) {
        if (str != null && str != '') {

            var d = new Date(str),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hours = '' + d.getHours(),
                minutes = '' + d.getMinutes();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (hours.length < 2)
                hours = '0' + hours;
            if (minutes.length < 2)
                minutes = '0' + minutes;

            if (type && type == 'hr-min') {
                return [day, month, year].join('.') + ' - ' + [hours, minutes].join(':');
            }
            const date = [day, month, year].join('.');
            if(date.includes('NaN')) {
                return;
            }
            return date;
        } else {
            return '';
        }
    } else {
        return str;
    }
};

export const parseToApiDate = (str:any, type?:any) => {
    if (/\d{2}\.\d{2}\.\d{4}/gm.test(str)) {
        const parts = str.split(".");
        return parts[2] + "-" + parts[1] + "-" + parts[0] + "T00:00:00";
    }
    return str;
}


export function removeEmptyObjects(o:any) {
    for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
            continue // If null or not an object, skip to the next iteration
        }
        // The property is an object
        removeEmptyObjects(o[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(o[k]).length === 0) {
            delete o[k]; // The object had no properties, so delete that property
        }
    }
    return o;
}

export function emptyStringsToNull(o:any) {
    for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
            if (o[k] === "") {
                o[k] = null;
            }
            if (typeof o[k] === 'undefined') {
                delete o[k];
            }
        } else {
            emptyStringsToNull(o[k]);
        }
    }
    return o;
}

export function formatNumber(num:any) {
    if (!num) {
        return num;
    }
    if (typeof num == 'string' && /[a-zA-Z]/.test(num)) {
        return num;
    }
    if (typeof num == 'string') {
        num = num.replace(/'/g, '').replace(/,/g, '.');
    }
    return parseFloat(num).toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "\'" + c : c;
    });
}

export function arrayBufferToBase64(buffer:any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function compareObjects(obj1:any, obj2:any) {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function percentage(max:any, value:any) {
    return max && value ? (value * 100) / max : 0;
}

export function getLatestCsvImport(csv_imports:any) {
    let sorted = csv_imports.sort((a:any, b:any) => {
        return new Date(b["attributes"]["imported-at"]).getTime() - new Date(a["attributes"]['imported-at']).getTime()
    })
    return sorted[0];
}



export function formatCsvImportDate(string:any) {
    // "2020-08-06T11:06:29.2206086"
    const date = string.split("T")[0];
    const time = string.split("T")[1];
    const splitDate = date.split("-");
    const splitTime = time.split(":");
    const formattedDate = [splitDate[2], splitDate[1], splitDate[0]].join(".");
    const formattedTime = [splitTime[0], splitTime[1]].join(":");
    return formattedDate + ", " + formattedTime;
}

export const hasParentWithMatchingSelector = (target:any, el:any) => {
    return el.contains(target);
}

export function dateDiffInDays(a:any, b:any) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const formatMessage = (translateProp:any, id:string, defaultMessage:string) => translateProp.intl.formatMessage({ id, defaultMessage });

export const toFixedNumber = (number: number, numberAfterComma: number) => number.toFixed(numberAfterComma);

export const formatNumberPrice = (str:any,val: number) => {
    str = str.toString();
    if(str.indexOf(".") !== -1){
        str = str.slice(0, (str.indexOf(".")) + val + 1); 
    } else  {
        str = str.slice(0, (str.indexOf(".")) + 9 + 1); 
    }
    return str;   
}