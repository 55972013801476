import { SelectOption } from './../types/selectOption.type';
import { ArticleInfomation } from './../types/ArticleInformation.type';
import { User } from './../types/user.type';
import { Units } from './../types/units.type';
import * as constants from '../constants/constants';
import { OfferField } from '../types/offerField.type';
import moment from "moment/moment";
import {convertNumberToHMS} from "../containers/Admin/globalFunctions";
import {FETCH_PROJECT_INFORMATION} from "../constants/constants";

export interface AppState {
    loading: boolean,
    buttonLoader: boolean,
    partialLoading: boolean,
    showValidations: boolean;
    step: string;
    isLetterActive: boolean;
    letter: string;
    clientsByModuleData: any;
    measureUsers: User[];
    articles: ArticleInfomation[];
    calendarData: any[];
    allClients: SelectOption[];
    units: Units[],
    unitsArticle: SelectOption[]
    allOfferFields: OfferField[]
    countriesFields:any[]
    citiesFields:any[],
    isNavOpen: boolean;
    isAdminPanelOpen: boolean;
    permissions: any,
    userResponsibilities: number[];
    articlesForProduction: ArticleInfomation[],
    articlesForWarehouse: ArticleInfomation[],
    allProjects: any,
    projectName: string,
    projectDetails: any,
    archiveRecords: any,
    archivedProducts: any,
    project: any,
    archivedResponsibilities: any,
    archivedMaterials: any,
    departments: any,
    language: SelectOption,
    projectInformation: any,
    statusId1Content: any,
    statusId2Content: any,
    statusId3Content: any,
    statusId4Content: any,
    statusId5Content: any,
    adminDepartments: any,
    archivedMaterialsAndResponsibilitiesPaginated: any,
    productionUsers: any
}

const initialState = () => {
    return {
        loading: false,
        buttonLoader: false,
        partialLoading: false,
        showValidations: true,
        step: 'contact',
        isLetterActive: false,
        letter: "all",
        clientsByModuleData: [],
        selectedClientIndex: null,
        measureUsers: [],
        articles: [],
        calendarData: [],
        allClients: [],
        units: [],
        unitsArticle: [],
        allOfferFields: [],
        countriesFields: [],
        citiesFields:[],
        isNavOpen: false,
        isAdminPanelOpen: false,
        permissions: {},
        userResponsibilities: [],
        articlesForProduction: [],
        articlesForWarehouse: [],
        allProjects: null,
        projectName: '',
        projectDetails: null,
        archiveRecords: [],
        archivedProducts: [],
        project: {},
        archivedResponsibilities: [],
        archivedMaterials: [],
        departments: [],
        returnedMaterials: [],
        returnedResponsibilities: [],
        language: {} as SelectOption,
        projectInformation: {},
        statusId1Content: [],
        statusId2Content: [],
        statusId3Content: [],
        statusId4Content: [],
        statusId5Content: [],
        adminDepartments: [],
        archivedMaterialsAndResponsibilitiesPaginated: [],
        productionUsers: []
    }
}
const app = (state: AppState = initialState(), action: any) => {
    switch(action.type) {
        case(constants.LOADING): {
            return {
                ...state,
                loading: action.payload
            }
        }
        case(constants.BUTTON_LOADING): {
            return {
                ...state,
                loading: action.payload
            }
        }
        case(constants.PARTIAL_LOADING): {
            return {
                ...state,
                partialLoading: action.payload
            }
        }
        case(constants.PERMISSIONS): {
            return {
                ...state,
                permissions: action.payload
            }
        }
        case(constants.ADMIN_DEPARTMENTS): {
            return {
                ...state,
                adminDepartments: action.payload
            }
        }
        case(constants.USER_RESPONSIBILITIES): {
            return {
                ...state,
                userResponsibilities: action.payload
            }
        }
        case(constants.SHOW_VALIDATIONS): {
            return {
                ...state,
                showValidations: action.payload
            }
        }
        case(constants.SET_STEP): {
            return {
                ...state,
                step: action.payload
            }
        }
        case constants.SET_LETTER: {
            return {
                ...state,
                letter: action.payload
            }
        }
        case(constants.IS_LETTER_ACTIVE): {
            return {
                ...state,
                isLetterActive: action.payload
            }
        }
        case(constants.FETCH_CLIENTS_BY_MODULE_DATA): {
            return {
                ...state,
                clientsByModuleData: action.payload.data
            }
        }
        case(constants.SET_SELECTED_CLIENT_INDEX): {
            return {
                ...state,
                selectedClientIndex: action.payload.data
            }
        }
        case (constants.GET_ALL_MEASURE_USERS): {
            return {
                ...state,
                measureUsers: action.payload.data
            }
        }
        case(constants.FETCH_UNITS): {
            return {
                ...state,
                units: action.payload
            }
        }
        case(constants.GET_DEPARTMENTS): {
            return {
                ...state,
                departments: action.payload
            }
        }
        case(constants.FETCH_UNITS_FOR_ARTICLE): {
            return {
                ...state,
                unitsArticle: action.payload
            }
        }
        case(constants.FETCH_ARTICLES): {
            return {
                ...state,
                articles: action.payload.data
            }
        }
        case(constants.FETCH_ARTICLE_TYPES): {
            return {
                ...state,
                articleTypes: action.payload
            }
        }
        case(constants.FETCH_CALENDAR_DATA): {
            return {
                ...state,
                calendarData: action.payload.data.data
            }
        }
        case(constants.FETCH_CALENDAR_DATA_PER_CLIENT): {
            return {
                ...state,
                calendarDataPerClient: action.payload.data
            }
        }
        case(constants.GET_ALL_CLIENTS): {
            return {
                ...state,
                allClients: action.payload
            }
        }
        case(constants.FETCH_ALL_OFFER_FIELDS): {
            return {
                ...state,
                allOfferFields: action.payload
            }
        }

        case(constants.FETCH_COUNTRIES): {
            return {
                ...state,
                countriesFields: action.payload
            }
        }

        case(constants.FETCH_CITIES): {
            return {
                ...state,
                citiesFields: action.payload
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS_FILTER: {
            return {
                ...state,
                clientsByModuleData: action.payload.data.data
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS1_FILTER: {
            console.log('GET_CLIENTS_WITH_STATUS1_FILTER', action.payload)
            return {
                ...state,
                statusId1Content: action.payload.data.data
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS2_FILTER: {
            console.log('GET_CLIENTS_WITH_STATUS2_FILTER', action.payload)
            return {
                ...state,
                statusId2Content: action.payload.data.data
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS3_FILTER: {
            console.log('GET_CLIENTS_WITH_STATUS3_FILTER', action.payload)
            return {
                ...state,
                statusId3Content: action.payload.data.data
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS4_FILTER: {
            console.log('GET_CLIENTS_WITH_STATUS4_FILTER', action.payload)
            return {
                ...state,
                statusId4Content: action.payload.data.data
            }
        }
        case constants.GET_CLIENTS_WITH_STATUS5_FILTER: {
            console.log('GET_CLIENTS_WITH_STATUS5_FILTER', action.payload)
            return {
                ...state,
                statusId5Content: action.payload.data.data
            }
        }
        case constants.IS_NAV_OPEN: {
            return {
                ...state,
                isNavOpen: action.payload
            }
        }
        case constants.IS_ADMIN_PANEL_OPEN: {
            return {
                ...state,
                isAdminPanelOpen: action.payload
            }
        }
        case constants.FETCH_ARTICLES_FOR_WAREHOUSE: {
            return {
                ...state,
                articlesForWarehouse: action.payload
            }
        }
        case constants.FETCH_ARTICLES_FOR_PRODUCTION: {
            return {
                ...state,
                articlesForProduction: action.payload
            }
        }
        case constants.GET_ALL_PROJECTS: {
            return {
                ...state,
                allProjects: action.payload?.data?.data
            }
        }
        case constants.SET_PROJECT_NAME: {
            return {
                ...state,
                projectName: action.payload
            }
        }
        case constants.GET_ALL_PROJECT_INFO: {
            return {
                ...state,
                projectDetails: action.payload.data.data
            }
        }
        case constants.GET_ARCHIVE_RECORDS: {
            return {
                ...state,
                archiveRecords: action.payload.data
            }
        }
        case constants.GET_PROJECT: {
            return {
                ...state,
                project: action.payload
            }
        }
        case constants.GET_ARCHIVED_PRODUCTS: {
            return {
                ...state,
                archivedProducts: action?.payload
            }
        }
        case constants.GET_ARCHIVED_MATERIALS_AND_RESPONSIBILITIES: {
            console.log(action, "actions.payload")
            return {
                ...state,
                archivedMaterialsAndResponsibilitiesPaginated: action?.payload
            }
        }
        case constants.GET_PRODUCTION_USERS: {
            return {
                ...state,
                productionUsers: action?.payload
            }
        }
        case constants.GET_ARCHIVED_MATERIALS: {
            let tempArray = []

            action?.payload?.forEach((materialStatus)=>{

                let tempObj = {
                    client: materialStatus?.client,
                    product: materialStatus?.product,
                    project: materialStatus?.project,
                    responsibility: {
                        responsibilityId: materialStatus?.responsibilityId,
                        responsibilityName: materialStatus?.responsibilityName
                    },
                    adminWarning: materialStatus?.adminWarning,
                    updatedAt: materialStatus?.updatedAt ? materialStatus?.updatedAt : '',
                    material: materialStatus?.material,
                    user: materialStatus?.user,
                    departmentName: materialStatus?.departmentName,
                    trackersStart: materialStatus?.trackersStart,
                    trackersEnd: materialStatus?.trackersEnd,
                    totalDuration: materialStatus?.totalDuration ?  convertNumberToHMS(materialStatus?.totalDuration) : '00h:00m:00s' ,
                    completionDate: materialStatus?.updatedAt
                }
                console.log(tempObj, materialStatus,'leka')

                tempArray.push(tempObj)
            })

            return {
                ...state,
                archivedMaterials: tempArray
            }
        }
        case constants.GET_ARCHIVED_RESPONSIBILITIES: {
            let tempArray = []

            action?.payload?.forEach((responsibilityStatus)=>{
                let tempObj = {
                    client: {
                        clientId: responsibilityStatus?.clientId,
                        clientName: responsibilityStatus?.clientName
                    },
                    product: {
                        productId: responsibilityStatus?.productId,
                        articleName: responsibilityStatus?.productName
                    },
                    project: {
                        projectId: responsibilityStatus?.projectId,
                        projectName: responsibilityStatus?.projectName
                    },
                    responsibility: {
                        responsibilityId: responsibilityStatus?.responsibilityId,
                        responsibilityName: responsibilityStatus?.responsibilityName
                    },
                    adminWarning: responsibilityStatus?.adminWarning,
                    updatedAt: responsibilityStatus?.updatedAt ? responsibilityStatus?.updatedAt : '',
                    material: {},
                    user: {
                        userId: responsibilityStatus?.userId,
                        userName: responsibilityStatus?.userName
                    },
                    trackersStart: responsibilityStatus?.trackersStart,
                    trackersEnd: responsibilityStatus?.trackersEnd,
                    totalDuration: responsibilityStatus?.totalDuration ?  convertNumberToHMS(responsibilityStatus?.totalDuration) : '00h:00m:00s' ,
                    departmentName: responsibilityStatus?.departmentName,
                    completionDate: responsibilityStatus?.updatedAt
                }
                tempArray.push(tempObj)
            })

            return {
                ...state,
                archivedResponsibilities: tempArray
            }
        }
        case constants.GET_RETURNED_RESPONSIBILITIES: {
            let tempArray = []
            action?.payload?.forEach((responsibilityStatus)=>{

                let tempObj = {
                    client: {
                        clientId: responsibilityStatus?.clientId,
                        clientName: responsibilityStatus?.clientName
                    },
                    product: {
                        productId: responsibilityStatus?.productId,
                        articleName: responsibilityStatus?.productName
                    },
                    project: {
                        projectId: responsibilityStatus?.projectId,
                        projectName: responsibilityStatus?.projectName
                    },
                    responsibility: {
                        responsibilityId: responsibilityStatus?.responsibilityId,
                        responsibilityName: responsibilityStatus?.responsibilityName
                    },
                    adminWarning: responsibilityStatus?.adminWarning,
                    updatedAt: responsibilityStatus?.updatedAt ? moment(responsibilityStatus?.updatedAt).format('DD/MM/YYYY HH:MM') : '',
                    material: {},
                    user: {
                        userId: responsibilityStatus?.userId,
                        userName: responsibilityStatus?.userName
                    },
                    statusReturnDate: responsibilityStatus?.statusReturnDate,

                    // timeSpent: convertNumberToHMS(materialStatus?.trackers?.length !== 0 ? materialStatus?.trackers[0]?.duration : 0)
                }
                tempArray.push(tempObj)
            })

            return {
                ...state,
                returnedResponsibilities: tempArray
            }
        }
        case constants.GET_RETURNED_MATERIALS: {
            let tempArray = []
            console.log(action?.payload,'action?.payload')
            action?.payload?.forEach((materialStatus)=>{
                let tempObj = {
                    client: materialStatus?.client,
                    product: materialStatus?.product,
                    project: materialStatus?.project,
                    responsibility: {
                        responsibilityId: materialStatus?.responsibilityId,
                        responsibilityName: materialStatus?.responsibilityName
                    },
                    adminWarning: materialStatus?.adminWarning,
                    updatedAt: materialStatus?.updatedAt ? moment(materialStatus?.updatedAt).format('DD/MM/YYYY HH:MM') : '',
                    material: materialStatus?.material,
                    user: materialStatus?.user,
                    statusReturnDate: materialStatus?.statusReturnDate,
                    // timeSpent: convertNumberToHMS(materialStatus?.trackers?.length !== 0 ? materialStatus?.trackers[0]?.duration : 0)
                }
                tempArray.push(tempObj)
            })

            console.log(tempArray, 'tempArray')
            return {
                ...state,
                returnedMaterials: tempArray
            }
        }
        case constants.SET_LANGUAGE: {
            return {
                ...state,
                language: action?.payload
            }
        }
        case constants.FETCH_PROJECT_INFORMATION: {
            return {
                ...state,
                projectInformation: action?.payload
            }
        }
    }

    return state;
}

export default app