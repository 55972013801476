import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators} from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import { Location } from "history";
import './Measures.scss'
import { useLocation, withRouter } from 'react-router';
import { Client } from '../../types/client.type';
import {Switch, Route, useParams} from 'react-router-dom';
import ShowAllMeasures from './ShowAllMeasures';
import CreateMeasureForm from './CreateMeasureForm';
import AllMeasures from './AllMeasures'
import {ParameterTypes} from "../../types/parameter.type";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history:any;
    location:any;
    client:any;
    clientActions:any;
    measuresActions:any;
    actions:any;
    measures:any;
    letter: string,
    clientsByModuleData: Array<Client>
}

const Measures = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    }, [])
    
    return (
        <div className="measures inside-container">
            <Switch>
                <Route exact path="/dashboard/measures/:statusId?" component={ShowAllMeasures} />
                <Route exact path="/dashboard/measures/create/:measureId?/:clientId?" component={CreateMeasureForm}/>
                <Route exact path="/dashboard/measures/all-measures/:clientId?" component={AllMeasures}/>
                <Route path="/dashboard/measures/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Measures)))