import * as constants from '../constants/constants';
import { apiCaller, axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading, setButtonLoading }  from './generalActions'
import { toast } from 'react-toastify';


export const setNameSurnameOffer = (payload: string): ACTION => ({
    type: constants.SET_NAME_SURNAME_OFFER,
    payload
});

export const setDateOffer = (payload: any): ACTION => ({
    type: constants.SET_DATE_OFFER,
    payload
});

export const setFiscalNumberOffer = (payload: number): ACTION => ({
    type: constants.SET_FISCAL_NUMBER,
    payload
});

export const setAddress = (payload: string): ACTION => ({
    type: constants.SET_ADDRESS,
    payload
});

export const setOfferId = (payload: number): ACTION => ({
    type: constants.SET_OFFER_ID,
    payload
});

export const setAccountNumber = (payload: number): ACTION => ({
    type: constants.SET_ACCOUNT_NUMBER,
    payload
});

export const setDiscountValue = (payload: any): ACTION => ({
    type: constants.SET_DISCOUNT_VALUE,
    payload
});

export const setDiscountTotal = (payload: any): ACTION => ({
    type: constants.SET_DISCOUNT_TOTAL,
    payload
});

export const setDiscountType = (payload: any): ACTION => ({
    type: constants.SET_DISCOUNT_TYPE,
    payload
});


export const setOfferBasedOnWorkerFirst = (payload: string): ACTION => ({
    type: constants.SET_OFFER_BASED_ON_WORKER_FIRST,
    payload
});

export const setOfferBasedOnWorkerSecond = (payload: string): ACTION => ({
    type: constants.SET_OFFER_BASED_ON_WORKER_SECOND,
    payload
});

export const setOfferBasedOnWorkerThird = (payload: string): ACTION => ({
    type: constants.SET_OFFER_BASED_ON_WORKER_THIRD,
    payload
});

export const setTypes = (payload: string): ACTION => ({
    type: constants.SET_TYPES,
    payload
});

export const setTax = (payload: number): ACTION => ({
    type: constants.SET_TAX,
    payload
});

export const setTaxPrice = (payload: number): ACTION => ({
    type: constants.SET_TAX_PRICE,
    payload
});

export const setDesignation = (payload: string): ACTION => ({
    type: constants.SET_DESIGNATION,
    payload
});

export const setPrice = (payload: number): ACTION => ({
    type: constants.SET_PRICE,
    payload
});

export const setProductUnit = (payload: number): ACTION => ({
    type: constants.SET_PRODUCT_UNIT,
    payload
});

export const setStartDate = (payload: number): ACTION => ({
    type: constants.SET_START_DATE,
    payload
});

export const setDiscount = (payload: number): ACTION => ({
    type: constants.SET_DISCOUNT,
    payload
});

export const setProducts = (payload: any): ACTION => ({
    type: constants.SET_PRODUCTS,
    payload
});

export const setComment = (payload: string): ACTION => ({
    type: constants.SET_COMMENT,
    payload
});

export const setOfferProduct = (payload: any): ACTION => ({
    type: constants.SET_OFFER_PRODUCT,
    payload
});

export const setAllOffers = (payload: any): ACTION => ({
    type: constants.SET_ALL_OFFERS,
    payload
});

export const resetOfferClient = (): ACTION => ({
    type: constants.RESET_OFFER_CLIENT
});

export const fetchClientOffer = (e:number) => wrap(async(dispatch:any) => {
    try {
        // dispatch(setLoading(true)); Dont setLoader to true for this request
        return await apiCaller('GET', `offers/${e}`).then(res => {
            dispatch({ "type": constants.FETCH_CLIENT_OFFERS, "payload": res });
            // dispatch(setLoading(false))
            // dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_CLIENT_OFFER')

export const clearAssets = (payload: []): ACTION => ({
    type: constants.CLEAR_ASSETS,
    payload
});


export const createOffer = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('POST', 'offers', payload).then((response:any) => {
            dispatch({ "type": constants.CREATE_OFFERS, payload: response })
            toast.info('Oferta eshte krijuar')
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');


export const editOffer = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        return axiosCaller('POST', `offers`, payload).then((response:any) => {
            toast.info('Oferta eshte ruajtur me sukses!')
            dispatch({ "type": constants.EDIT_OFFER, payload: response })
            return response
        });
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'EDIT_OFFER_ERROR');

export const getAssets = (offerId: number) => wrap((dispatch:any) => {
    try {
        return apiCaller('GET', `offers/files/${offerId}`).then(res => {
            dispatch({ "type": constants.GET_ASSETS, "payload": res })
        })
    }
    catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')

export const getCanceledProducts = (offerId: number) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return apiCaller('GET', `canceled/products`).then(res => {
            dispatch({ "type": constants.GET_CANCELED_PRODUCTS, "payload": res })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    }
    catch(err) {
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')