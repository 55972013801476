import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading, setButtonLoading }  from './generalActions'
import { toast } from 'react-toastify';
import { ArticleInfomation ,  } from '../types/ArticleInformation.type';
import { OfferField } from '../types/offerField.type';

export const resetArticle = (): ACTION => ({
    type: constants.RESET_ARTICLE
});

const adminPath = "admin/"


export const fetchUsers = () => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('GET', `${adminPath}users`).then(res => {
            dispatch({ "type": constants.FETCH_USERS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_USERS')

export const fetchUsersDashboardInformation = () => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('GET', `users/dashboard`).then(res => {
            dispatch({ "type": constants.FETCH_USERS_DASHBOARD_INFORMATION, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_USERS')

export const fetchPermissions = () => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('GET', `${adminPath}permissions`).then(res => {
            dispatch({ "type": constants.FETCH_PERMISSIONS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_USERS')

export const fetchUser = (userId: number) => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('GET', `${adminPath}users/${userId}`).then(res => {
            dispatch({ "type": constants.FETCH_USER, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            return res.data.data
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'FETCH_USERS')

export const createUser = (payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}users`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            toast.info("Perdoruesi eshte krijuar!")
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_USER_ERROR');

export const editUser = (userID: number, payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}users/${userID}?_method=PATCH`, payload).then((response: any) => {
            dispatch({ "type": constants.FETCH_USER, "payload": response.data.data})
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            toast.info("Perdoruesi eshte ndryshuar!")
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchResponsibility = (responsibilityId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}responsibilities/${responsibilityId}`).then((res:any) => {
            dispatch({ "type": constants.FETCH_RESPONSIBILITY, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const createResponsibility = (payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}responsibilities`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(response.data.unique.name){
                toast.info('Ky proces eksiston!');
            }else{
                toast.success('Procesi u krijua me sukses!')
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editResponsibility = (responsibilityId: number, payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}responsibilities/${responsibilityId}?_method=PATCH`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(response.data.unique.name){
                toast.info('Ky proces eksiston!');
            }else{
                toast.success('Procesi u ndryshua me sukses!')
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteResponsibility = (responsibilityId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}responsibilities/${responsibilityId}`).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(response.data.data === false){
                toast.info('Ky proces eshte i lidhur me module tjera, nuk mund te fshihet!')
            }else{
                toast.success('Procesi u fshi me sukses!')
            }

        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchMaterials = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}material`).then((res:any) => {
            dispatch({ "type": constants.FETCH_MATERIALS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const getMaterial = (materialId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}material/${materialId}`).then((res:any) => {
            dispatch({ "type": constants.GET_MATERIAL, "payload": res.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const createMaterial = (payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}material`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            toast.info('Materiali u krijua me sukses')
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editMaterial = (materialId: number, payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}material/${materialId}`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if (response?.data?.data){
                toast.info('Materiali u ndryshua me sukses')
            }else{
                toast.error('Ky material ka artikuj ne prodhim.')
            }
            return response
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteMaterial = (materialId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}material/${materialId}`).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(!response?.data?.data){
                toast.error('Materiali eshte ne faza te prodhimit, nuk mund te fshihet!')
            }else{
                toast.info('Materiali u fshi me sukses!')
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const createEquipment = (payload: any) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}mounting-equipments`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editEquipment = (payload: any, equipmentId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}mounting-equipments/${equipmentId}?_method=PATCH`, payload).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteEquipment = (equipmentId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}mounting-equipments/${equipmentId}`).then((response: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(response.data.data === false){
                return false
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const getUsersForResponsibilities = () => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}responsibilities/users`).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_USERS_FOR_RESPONSIBILITIES, "payload": res });
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteUser = (userId: number) => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}users/${userId}`).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.DELETE_USER, "payload": res });
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const createUnit = (payload: any) => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    return await axiosCaller('POST', `${adminPath}units`, payload).then(res => {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        dispatch({ "type": constants.CREATE_UNIT, "payload": res.data.data });
        if(res.data.unique.name){
            toast.info('Kjo njesi eksiston!');
            return
        }
        toast.success('Njesia u krijua me sukses!');
    })
    // try {
    //
    // } catch(err) {
    //     dispatch(setLoading(false));
    dispatch(setButtonLoading(false));
    //     toast.error('Diqka nuk shkoi mire');
    // }
}, 'CREATE_UNIT')

export const createDepartment = (payload: any) => wrap(async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    return await axiosCaller('POST', `${adminPath}departments`, payload).then(res => {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        dispatch({ "type": constants.CREATE_UNIT, "payload": res.data.data });
        if(res.data.unique.name){
            toast.info('Ky repart eksiston!');
            return
        }
        toast.success('Reparti u krijua me sukses!');
    })
    // try {
    //
    // } catch(err) {
    dispatch(setLoading(false));
    dispatch(setButtonLoading(false));
    //     toast.error('Diqka nuk shkoi mire');
    // }
}, 'CREATE_DEPARTMENT')

export const editUnit = (unitId: number, payload: any) => wrap(async(dispatch:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('POST', `${adminPath}units/${unitId}?_method=PATCH`, payload).then(res => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(res.data.unique.name){
                toast.info('Kjo njesi eksiston!');
            }else{
                toast.success('Njesia u ndryshua me sukses!');
            }
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'EDIT_UNIT')

export const editDepartment = (departmentId: number, payload: any) => wrap(async(dispatch:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('POST', `${adminPath}departments/${departmentId}`, payload).then(res => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(res.data.unique.name){
                toast.info('Ky repart eksiston!');
            }else{
                toast.success('Reparti u ndryshua me sukses!');
            }
        })
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'EDIT_UNIT')

export const deleteUnit = (unitId: number) => wrap(async(dispatch:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('DELETE', `${adminPath}units/${unitId}`).then(res => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.DELETE_UNIT, "payload": res.data.data });
            toast.info('Njesia u fshi me sukses');
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'DELETE_UNIT')

export const deleteDepartment = (departmentId: number) => wrap(async(dispatch:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return await axiosCaller('DELETE', `${adminPath}departments/${departmentId}`).then(res => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            toast.info('Reparti u fshi me sukses');
        })
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
    }
}, 'DELETE_UNIT')

export const createArticle = (articleInformation: ArticleInfomation) => wrap((dispatch:any, state:any) => {
    // dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}articles` , articleInformation).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.CREATE_ARTICLE, "payload": res.data.data });
            if(res.data.unique.code){
                toast.info('Kodi i produktit eshte perdorur me pare!');
            }else{
                toast.success('Artikulli u krijua me sukses!');
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_ARTICLE');

export const fetchArticleTypes = () => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}types`).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_ARTICLE_TYPES, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'FETCH_ARTICLE_TYPE');

export const fetchArticleType = (articleTypeId:number) => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}types/${articleTypeId}/responsibilities`).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_ARTICLE_TYPE, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteArticleType = (articleTypeId:number) => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}types/${articleTypeId}`).then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const fetchArticle = (articleTypeId:number) => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `articles/${articleTypeId}/responsibilities`).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.FETCH_ARTICLE, "payload": res.data.data });
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editArticle = (articleId:number, articleInformation: any) => wrap((dispatch:any, state:any) => {
    // dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}articles/${articleId}?_method=PATCH`, articleInformation).then((res:any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
            if(res.data.data === false){
                toast.error('Artikulli eshte ne faza te prodhimit, nuk mund te editohet!');
                return false
            }else{
                dispatch({ "type": constants.EDIT_ARTICLE, "payload": res.data.data });
                toast.success('Artikulli u editua me sukses!');
                return true
            }
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const deleteArticle = (articleId:number) => wrap((dispatch:any, state:any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}articles/${articleId}`).then((res:any) => {
            if(res?.data?.data === false){
                toast.error('Artikulli eshte ne faza te prodhimit, nuk mund te fshihet!')
            }else{
                toast.info('Artikulli u fshi me sukses!')
            }
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const createOfferField = (payload: OfferField) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}dynamic-offers`, payload).then((res: any) => {
            toast.success('Fusha u krijua me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const getOfferField = (offerFieldId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}dynamic-offers/${offerFieldId}`).then((res: any) => {
            dispatch({ "type": constants.GET_OFFER_FIELD, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editOfferField = (offerFieldId: number, payload: OfferField) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}dynamic-offers/${offerFieldId}?_method=PATCH`, payload).then((res: any) => {
            toast.success('Fusha u editua me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const getImpressionQuestions = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}impression-questions`).then((res: any) => {
            dispatch({ "type": constants.GET_IMPRESSION_QUESTIONS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const clearImpressionQuestion = (): ACTION => ({
    type: constants.SET_NAME_SURNAME_OFFER,
    payload: null
});

export const createImpressionQuestion = (payload) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}impression-questions`, payload).then((res: any) => {
            toast.success('Pyetja u editua me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const editImpressionQuestion = (impressionQuestionId: number, payload) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}impression-questions/${impressionQuestionId}`, payload).then((res: any) => {
            toast.success('Pyetja u editua me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_OFFER_ERROR');

export const clearOfferField = (): ACTION => ({
    type: constants.CLEAR_OFFER_FIELD
})

export const saveConditionsForOffer = (payload: {html: string}, offerConditionId?: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}offer-conditions${offerConditionId ? `/${offerConditionId}?_method=PATCH` : '' }`, payload).then((res: any) => {
            toast.success('Kushtet u ruajten me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'SAVE_OFFER_CONDITIONS_ERROR');

export const getOfferConditions = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `offer/conditions`).then((res: any) => {
            dispatch({ "type": constants.GET_OFFER_CONDITIONS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const getAuditLogs = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}audits/show`).then((res: any) => {
            dispatch({ "type": constants.GET_AUDIT_LOGS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const getColorPalettes = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}color-palette`).then((res: any) => {
            dispatch({ "type": constants.GET_COLOR_PALETTES, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const createColorPalette = (payload: {colorPalette: string}) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}color-palette`,payload ).then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const editColorPalette = (payload: {colorPalette: string}, colorPaletteId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('PATCH', `${adminPath}color-palette/${colorPaletteId}`,payload ).then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const deleteColorPalette = (colorPaletteId: number) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}color-palette/${colorPaletteId}` ).then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');


export const getSuppliers = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}suppliers` ).then((res: any) => {
            dispatch({ "type": constants.GET_SUPPLIERS, "payload": res.data.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const saveSupplier = (payload) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}suppliers`, payload).then((res: any) => {
            toast.success('Furnitori u krijua me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const editSupplier = (supplierId ,payload) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}suppliers/${supplierId}`, payload).then(() => {
            toast.success('Furnitori u ndyshua me sukses!')
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');


export const deleteSupplier = (supplierId) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('DELETE', `${adminPath}suppliers/${supplierId}`).then(() => {
            toast.info('Furnitori u fshi me sukses!')
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');

export const getProductionSettings = () => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('GET', `${adminPath}production-settings`).then((res) => {
            dispatch({ "type": constants.GET_PRODUCTION_SETTINGS, "payload": res?.data });
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');


export const saveProductionSettings = (payload) => wrap((dispatch: any, state: any) => {
    dispatch(setLoading(true));
    dispatch(setButtonLoading(true));
    try {
        return axiosCaller('POST', `${adminPath}production-settings`, payload).then((res) => {
            toast.success('Te dhenat u ruajten me sukses!');
            dispatch(setLoading(false));
            dispatch(setButtonLoading(false));
        });
    } catch(err) {
        dispatch(setLoading(false));
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_OFFER_CONDITIONS_ERROR');
