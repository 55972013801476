// import { Link } from 'react-router-dom';
import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './Button.scss';
import Spinner from '../Spinner';
import { defined } from '../../utils/variableEvaluation';
import { renderSwitch } from '../../utils/react';
import SimpleLoader from "../Spinner/SimpleLoader";

export enum EButtonType {
    BasicLink = 'BasicLink',
    GreyButton =  'GreyButton',
    PurpleButton = 'PurpleButton',
    Danger = 'Danger',
    Available = 'Available',
    Unavailable = 'Unavailable'
}

enum EButtonActionType {
    InternalLink = 'Internal Link',
    ExternalLink = 'External Link',
    NormalButton = 'Normal Button'
}

interface IProps {
    buttonType?: EButtonType;
    to?: string;
    href?: string;
    isLoading?: boolean;
    notBlank?: boolean;
    id?: string;
    isDisabled?: boolean;
    submit?: boolean;
    className?: string;
    title?: string;
    onClick?: any;
    onMouseHover?: void;
}

export class Button extends React.Component<IProps> {
    private action(): string {
        if (defined(this.props.to)) {
            return EButtonActionType.InternalLink;
        } else if (defined(this.props.href)) {
            return EButtonActionType.ExternalLink;
        } else {
            return EButtonActionType.NormalButton;
        }
    }

    private renderButton() {
        const {
            isDisabled,
            isLoading,
            buttonType,
            to,
            href,
            id,
            children,
            className,
            onClick,
            submit,
            notBlank,
            ...remainingProps
        } = this.props;

        const buttonClass = classNames('button', className, {
            "grey-button": buttonType === EButtonType.GreyButton,
            "purple-button": buttonType === EButtonType.PurpleButton,
            "basic-link": buttonType === EButtonType.BasicLink,
            "danger": buttonType === EButtonType.Danger,
            "available": buttonType === EButtonType.Available,
            "unavailable": buttonType === EButtonType.Unavailable
        });

        return renderSwitch(this.action(), {
            [EButtonActionType.InternalLink]: () => (
                <Link
                    id={id}
                    key={`Link-${to}`}
                    to={to}
                    className={buttonClass}
                    role="button"
                    {...remainingProps}
                >
                    {children}
                </Link>
            ),
            [EButtonActionType.ExternalLink]: () => (
                <a
                    id={id}
                    key={`a-${href}`}
                    href={href}
                    className={buttonClass}
                    role="button"
                    target={notBlank ? "" : "_blank"}
                    rel="noopener noreferrer"
                    {...remainingProps}
                >
                    {children}
                    {/*<span className={styles.mask} />*/}
                </a>
            ),
            [EButtonActionType.NormalButton]: () => (
                <button
                    id={id}
                    key="button"
                    className={classNames(buttonClass, { "button-link": buttonType === EButtonType.BasicLink })}
                    disabled={isDisabled}
                    onClick={onClick}
                    type={submit ? "submit" : "button"}
                >
                    {this.props.isLoading ? <><SimpleLoader /></> : children}
                    {/*<span className={styles.mask} />*/}
                </button>
            )
        });
    }

    public render() {
        return this.renderButton();
    }
}
