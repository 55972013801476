import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as actions from '../../actions/generalActions';
import * as adminActions from '../../actions/admin';
import { Button, EButtonType } from '../../components/Button';
import 'react-quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';
import { useEffect, useState } from "react";
import {isMobile} from "../../constants/constants";
import TranslatedText from "../../components/TranslatedText";

interface Props {
    history: any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    buttonLoader: boolean
}

const OfferConditions = (props: Props) => {
    const [offerConditions, setOfferConditions] = useState<string>()
    const { quill, quillRef, Quill } = useQuill({ modules: { counter: true } });

    if (Quill && !quill) {
        Quill.register('modules/counter', function (quill, options) { });
    }

    useEffect(() => {
        props.adminActions.getOfferConditions()
    }, [])

    useEffect(() => {
        setOfferConditions(props.admin?.offerConditions?.conditions)
    }, [props.admin.offerConditions])

    function saveOfferConditions() {
        setOfferConditions(quill.root.innerHTML)
        if (props.admin?.offerConditions?.id) {
            props.adminActions.saveConditionsForOffer({ conditions: quill.root.innerHTML }, props.admin?.offerConditions?.id)
        } else {
            props.adminActions.saveConditionsForOffer({
                conditions: quill.root.innerHTML
            })
        }
    }

    return (
        <div className="admin-page offerConditions">
            <div className={`create-admin-page-form tabletForm ${isMobile ? 'w-100': 'w-50'}`}>
                <h3>
                    <TranslatedText id="offer.offerConditions" defaultMessage="Kushtet e ofertes" />
                </h3>
                <div className="d-flex flex-column quillContainer">
                    <div ref={quillRef} />
                </div>
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={saveOfferConditions}>
                    <TranslatedText id="offer.saveOfferConditions" defaultMessage="Ruaj kushtet e ofertes" />
                </Button>
            </div>
            {offerConditions && <div
                className={"w-50 p-3"}
                dangerouslySetInnerHTML={{
                    __html: offerConditions
                }}>

            </div>}
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(OfferConditions as any)));