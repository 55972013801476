import React from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementsActions from '../../actions/agreements';
import * as offerActions from '../../actions/offers';
import * as measuresActions from '../../actions/measures'
import {withRouter} from 'react-router';
import {useEffect, useState} from 'react';
import {Client} from '../../types/client.type';
import {SelectOption} from '../../types/selectOption.type';
import ResponsibilitySlider from "../Production/ResponsibilitySlider";
import {EInputTextType, Input} from "../../components/Input/Input";
import arrowDown1 from "../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg";
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import {useParams} from "react-router-dom";
import { ParameterTypes } from '../../types/parameter.type';
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    offer: any;
    client: any;
    clientActions: any,
    actions: any,
    agreements: any
    agreementsActions: any,
    letter: string,
    clientsByModuleData: Array<Client>,
    allClients: SelectOption[],
    measuresActions: any,
    offerActions: any,
    measures: any
}

const CreateBlueprintForm = (props: Props) => {

    const {clientId} = useParams<ParameterTypes>();

    const moduleId = 5
    const [searchedClient, setSearchedClient] = useState<string>()
    const [clients, setClients] = useState<SelectOption[]>([])
    const [isDropDownInfoOpen, setIsDropDownInfoOpen] = useState(false);
    const [firstnameAndLastname, setFirstnameAndLastname] = useState<string>('')

    useEffect(() => {
        props.actions.getAllClients(5) //the static 5 is for moduleId
    }, [])

    useEffect(() => {
        setFirstnameAndLastname(`${props.client?.client?.firstnameAndLastname} ${props.client?.client?.lastName}`)
    }, [props.client.client])

    useEffect(() => {
        props.agreementsActions.fetchClientAgreement(clientId)
        props.clientActions.fetchClient(clientId, moduleId)
        props.offerActions.fetchClientOffer(clientId)
        props.measuresActions.fetchClientMeasure(clientId)
    }, [clientId])

    useEffect(() => {
        let tempArrayOfClients = props.allClients

        tempArrayOfClients = tempArrayOfClients.filter(val => val.name.includes(searchedClient?.charAt(0).toUpperCase()))

        setClients(tempArrayOfClients)

    }, [searchedClient])

    function saveBlueprintForm() {

    }

    function resetFields() {
        props.agreementsActions.resetAssets()
        props.clientActions.resetClient()
    }

    function getTransferredDataFromModules(clientId) {
        setSearchedClient('')
        setIsDropDownInfoOpen(false)
        props.history.push({pathname: `/dashboard/blueprints/create/${clientId}`})
    }

    return (
        <div className="wrapper">
            <div className="blueprint-form-wrapper">
                <div className="col-12 position-relative">
                    {clientId ?
                        <div>
                            <a onClick={() => setIsDropDownInfoOpen(!isDropDownInfoOpen)}>
                                <Input
                                    value={firstnameAndLastname || ''}
                                    type="text"
                                    inputWrapperClass="w-100 nameSearch mb-0"
                                    disabled
                                    inputTextType={EInputTextType.SimpleInput}
                                    placeholder={translatedText({
                                        id: "client.nameSurname",
                                        defaultMessage: "Emri dhe mbiemri"
                                    })}>
                                    <img className="arrowdown" src={arrowDown1} alt=""/>
                                </Input>
                            </a>
                        </div>
                        :
                        <Input
                            name="nameSearch"
                            value={searchedClient || ''}
                            type="text"
                            inputWrapperClass="w-100 nameSearch"
                            onChange={(name, value) => setSearchedClient(value)}
                            inputTextType={EInputTextType.SimpleInput}
                            placeholder={translatedText({
                                id: "client.nameSurname",
                                defaultMessage: "Emri dhe mbiemri"
                            })}/>
                    }
                    {searchedClient && <div className="suggestedClients-container-measures ">
                        {clients.length === 0 ?
                            <p className={'m-0 w-100 text-center'}>Nuk ka te dhena</p>
                            :
                            clients?.map((client) => {
                                return (
                                    <div className="suggestedClients" key={client.id}
                                         onClick={() => getTransferredDataFromModules(client.id)}>
                                        <p className="m-0">{client.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>}
                    {isDropDownInfoOpen && <DataTransferComponent
                        measureNeeded
                        offerNeeded
                        agreementNeeded
                        clientId={clientId}/>}
                </div>
                <ResponsibilitySlider/>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        offer: state.offers,
        measures: state.measures,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateBlueprintForm as any)));