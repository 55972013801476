import React, {ChangeEvent, CSSProperties, useEffect, useState} from 'react';
import './MikaFileInput.scss'
import PlusIconLink from '../../../assets/icons/plus.svg'
import {FileUpload} from "../../../types/fileUpload.type";
import {injectIntl} from "react-intl";
import {RootState} from "../../../reducers";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {values} from "lodash";
import {translatedText} from "../../TranslatedText";

interface Props{
    style?: CSSProperties;
    labelKey?: string;
    name?: string;
    flat?: boolean;
    value?: any;  //FileUpload | FileUpload[]
    bordered?: boolean;
    className?: string;
    multiple?: boolean
    setFiles: (FileUpload) => void;
    onChange?: () => void;
    intl: any,
    accept?: string,
}

const MikaFileInput: React.FC<Props> = (props: Props) => {

    const [fileLabel, setFileLabel] = useState<string>( props.labelKey ? props.labelKey :  translatedText({id:"app.noChosenFile", defaultMessage:"Asnje file i selektuar"}))
    const [showPics, setShowPics] = useState<boolean>(false)
    const [previewPics, setPreviewPics] = useState<any>()

    useEffect(() => {
        if (props.value?.fileName) {
            setFileLabel(props.value?.fileName)
        }else if(props?.multiple && props?.value && props?.value?.length !== 0){
            setFileLabel(`${props?.value && props?.value?.map((file)=>`${file?.fileName}`)}`)
            let tempArray = []
            for (const file of props?.value) {
                if(file?.file){
                    const objectUrl = URL?.createObjectURL(file?.file)
                    tempArray.push(objectUrl)
                }
            }
            setPreviewPics(tempArray)
        }else{
            setFileLabel(props.labelKey ? props.labelKey :  translatedText({id:"app.noChosenFile", defaultMessage:"Asnje dokument i selektuar"}))
        }
    }, [props.value])

    async function compressImage(image){
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const compressedDataUrl = await compressDataUrl(e.target.result);
                const compressedBlob = dataURLtoBlob(compressedDataUrl);
                resolve(new File([compressedBlob], image.name, { type: 'image/jpeg' }));
            };

            reader.readAsDataURL(image);
        });
    };

    const compressDataUrl = (dataUrl) => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0);

                const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.2); // Adjust the quality (0.0 - 1.0)
                resolve(compressedDataUrl);
            };

            img.src = dataUrl;
        });
    };

    const isImageFile = (file) => {
        return file && file.type.startsWith('image/');
    };


    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    async function setFileOnChange(files, e){

        if(files?.length !== 0){
            if(props.multiple){
                let allFiles = []
                for (let i = 0; i < files?.length; i++) {
                    let tempFile = null

                    if(isImageFile(files[i])){
                       tempFile = await compressImage(files[i])
                    }

                    allFiles.push({
                        file: isImageFile(files[i]) ? tempFile : files[i],
                        fileName: files[i].name
                    })
                }
                setFileLabel(`${allFiles?.length} ${translatedText({id:"app.filesSelected", defaultMessage:"file te selektuara"})}`)
                props.setFiles(allFiles)
            }else{
                setFileLabel(files[0].name)
                let temp = null

                if(isImageFile(files[0])){
                    temp = await compressImage(files[0])
                }

                props.setFiles({
                    fileName: files[0].name,
                    file: isImageFile(files[0]) ? temp : files[0]
                })
            }
        }else{
            setFileLabel(props.labelKey ? props.labelKey : translatedText({id:"app.noChosenFile", defaultMessage:"Asnje file i selektuar"}))
        }
    }

    return <div className={`mikaInputFileWrapper ${props.className}`} onMouseEnter={()=>setShowPics(true)} onMouseLeave={()=>setShowPics(false)}>
        {showPics && previewPics?.length && <div className={'imgHoverContainer'}>
            {previewPics?.map((picBase64)=>{
                return <img src={picBase64} alt=""/>
            })}
        </div>}
        <input type="file" id={'fileInput'} name={props?.name} accept={props?.accept} onChange={(fileInput:ChangeEvent<HTMLInputElement>)=>{setFileOnChange(fileInput.target.files, fileInput)}} multiple={props.multiple} />
        <p className={'m-0'}>{ fileLabel }</p>
        <img src={PlusIconLink} alt="plus-icon" />
    </div>
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(MikaFileInput)))