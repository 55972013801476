import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Impression.scss'
import * as productionActions from '../../actions/production'
import * as installationActions from '../../actions/installation'
import TableBbros from '../../components/Table/TableBbros';
import {
    IMPRESSION_RECORDS, IMPRESSION_RECORDS_MOBILE,
    OPTIONS,
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import * as transportActions from "../../actions/transport";
import * as serviceActions from "../../actions/service";
import * as impressionActions from "../../actions/impression";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";


interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    transportActions: any
    transport: any
    archiveRecords: any
    installationActions: any
    installation: any
    serviceActions: any
    service: any
    impressionActions: any
    impression: any
}

const AllImpressionRecords = (props: Props) => {

    const {statusId} = useParams<ParameterTypes>()
    const [serviceRecordId, setServiceRecordId] = useState<number>(null)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        props.impressionActions.getImpressionRecords(statusId)
    }, [statusId])

    const editImpressionData = (project) => {
        console.log(project,'project')

        props.history.push({pathname: `/dashboard/impression/create/${project?.client?.id}/${project?.id}`})
    }

    const handleRemove = (impression) => {
        setDeleteModal(true)
        setServiceRecordId(impression?.id)
    }

    const deleteService = () => {
        props.serviceActions.deleteImpressionRecord(serviceRecordId).then(()=>{
            props.serviceActions.getImpressionRecords()
        })
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event, rowData) => editImpressionData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => handleRemove(rowData)
        }
    ]

    return(
        <div className="wrapper warehouseContainer">
            <TableBbros
                title={translatedText({id: "table.warehouse", defaultMessage: "Pershtypja"})}
                data={props?.impression?.impressionRecords}
                columns={isMobile ? IMPRESSION_RECORDS_MOBILE : IMPRESSION_RECORDS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteService()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        transport: state.transport,
        installation: state.installation,
        service: state.service,
        impression: state.impression
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
        impressionActions: bindActionCreators(impressionActions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllImpressionRecords as any)));