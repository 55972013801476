import React, {useEffect, useRef} from 'react';
import MaterialTable from "material-table";
import { ActionTypes } from '../../types/actionTable.type';
import { ColumnTypes } from '../../types/columnTable.type';
import { TABLEICONS } from './TableIcons'
import Spinner from '../Spinner';
import { PatchedPagination } from './TablePagination';
import { Paper } from '@material-ui/core';
import './Table.scss'

interface Props {
    title?: string;
    columns: ColumnTypes[],
    actions?: ActionTypes[],
    data: Array<object>,
    options?: object,
    components?: object,
    isLoading?: boolean;
    className?: string;
    customPagination?: boolean
    paginationDetails?: any
    callBack?: any
}

const TableBbros: React.FC<Props> = (props: Props) => {
    
    const {
        title,
        columns,
        actions,
        data,
        options,
        components,
        isLoading
    } = props;
    
    return (
        <div className={`${props.className} materialTableContainer`}>
            <MaterialTable
                icons={TABLEICONS}
                title={title}
                columns={columns}
                actions={actions}
                data={data || []}
                options={options}
                isLoading={isLoading}
                components={{
                    Container: (props) => <Paper className="full-height-container" {...props}/>,
                    OverlayLoading: () => <Spinner />,
                    Pagination: PatchedPagination,
                }}
            />
        </div>
    // <div className={`${props.className} materialTableContainer`}>
    //     <MaterialTable
    //         icons={TABLEICONS}
    //         title={title}
    //         columns={columns}
    //         actions={actions}
    //         data={data || []}
    //         options={options}
    //         isLoading={isLoading}
    //         components={props?.customPagination ? {
    //             Container: (props) => <Paper className="full-height-container" {...props}/>,
    //             OverlayLoading: () => <Spinner/>,
    //             // Pagination: PatchedPagination,
    //             Pagination: (propsForPagination) => <PatchedPagination paginationDetails={props?.paginationDetails}
    //                                                                    callBack={props?.callBack}
    //                                                                    customPagination={props?.customPagination} {...propsForPagination}/>,
    //
    //         } : {
    //             Container: (props) => <Paper className="full-height-container" {...props}/>,
    //             OverlayLoading: () => <Spinner/>,
    //             Pagination: PatchedPagination,
    //             // Pagination: (propsForPagination)=> <PatchedPagination paginationDetails={props?.paginationDetails} callBack={props?.callBack} customPagination={props?.customPagination} {...propsForPagination}/>,
    //
    //         }}
    //     />
    // </div>
)
}

export default TableBbros;