import React, { useEffect,useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { Switch,Route } from 'react-router-dom';
import "./Transport.scss";
import { withRouter } from 'react-router';
import { User } from '../../types/user.type';
import ShowAllTransports from './ShowAllTransports';
import AllTransportRecords from "./AllTransportRecords";
import AllProjectLocationMap from "./AllProjectLocationMap";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
import ShowAllMeasures from "../Measures/ShowAllMeasures";
import ShowAllTransport from "./ShowAllTransport";
interface Props {
    intl: any;
    isLoading: boolean;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    validationMessageConfig: any;
    letter:string;
    app:any;
    history:any;
    measureUsers: User[];
}

const Transports = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    },[])

    return (
        <div className="transport inside-container">
            <Switch>
                {/*<Route exact path="/dashboard/transport" component={AllTransportRecords} />*/}
                <Route exact path="/dashboard/transport/:query?" component={ShowAllTransport} />
                <Route exact path="/dashboard/transport/create/record/:transportCode?" component={ShowAllTransports} />
                <Route exact path="/dashboard/transport/records/map" component={AllProjectLocationMap} />
                <Route path="/dashboard/transport/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )

}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Transports as any)))