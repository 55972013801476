import {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';

import '../Login/Login.scss'
import {Button, EButtonType} from '../../components/Button';

import LoginBg from "../../assets/img/login-bg.png";
import LoginLogo from "../../assets/icons/MIKA_logo-01.svg";
import { withRouter} from 'react-router';
import { Link, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner';
import * as Yup from 'yup';
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import MikaPasswordInput from "../../components/Input/MikaPasswordInput/MikaPasswordInput";
import {USER_PASSWORD_VALIDATION, USER_PASSWORDCONFIRM_VALIDATION} from "../../utils/validationConfigs";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    actions: any;
    setLoading: boolean;
    user: any;
    history: any;
    loading: boolean;
    buttonLoader: boolean
}

interface State {
    isLoginForm: boolean;
    rememberMe: boolean;
    email: string;
    username: string;
    password: string;
}

export interface ResetPasswordCredentials {
    token: string;
    email: string;
    password: string;
    confirmationPassword: string;
}

type ResetParams = {
    token: string,
    email: string
}

const ResetPassword = (props: Props) => {
    
    const params = useParams<ResetParams>();
    const [password, setPassword] = useState<string>('');
    const [confirmationPassword, setConfirmationPassword] = useState<string>('');

    function validationScheme() {
        return Yup.object().shape({
            password: USER_PASSWORD_VALIDATION,
            confirmationPassword: USER_PASSWORDCONFIRM_VALIDATION,
        });
    }

    function resetPw(values: ResetPasswordCredentials) {
        const resetPwPayload = {
            token: params.token,
            email: params.email,
            password: values?.password,
            passwordConfirmation: values?.confirmationPassword
        }
        props.actions.resetPw(resetPwPayload)
    }

    const initialValues = {
        password: '',
        confirmationPassword: ''
    };

    function resetPasswordForm() {
        return (
            <>
                {props.loading ? <Spinner isTransparent /> :
                    <div>
                        <p className="login-text medium-text">
                            <TranslatedText id="login.login_text"
                                              defaultMessage="Miresevini! Ju lutem hyni ne llogarin tuaj."/>
                        </p>
                        <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()}
                                           onSubmit={resetPw}>
                            <MikaPasswordInput name="password" flat placeholder={translatedText({
                                id: "login.password",
                                defaultMessage: "Fjalekalimi"
                            })}/>
                            <MikaPasswordInput name="confirmationPassword" flat placeholder={translatedText({
                                id: "login.confirmationPassword",
                                defaultMessage: "Konfirmo fjalekalimin"
                            })}/>
                            <div className="row">
                                <p className="login-text medium-text go-to-login">
                                    <Link to="/login"><TranslatedText id="login.login" defaultMessage="Kyquni" /></Link>
                                </p>
                            </div>
                            <div className="col text-center">
                                <Button
                                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                                    submit
                                    className="m-auto mt-5"
                                    buttonType={EButtonType.PurpleButton}>
                                    <TranslatedText id="login.login" defaultMessage="Kyquni"/>
                                </Button>
                            </div>
                        </FormikFormWrapper>
                    </div>}
            </>
        )
    }

    return (
        <div className="container-fluid height111vh">
            <div className="row height111vh">
                <div className="col-6 p-0 left-side">
                    <img src={LoginBg} className="login-bg" alt="login bg"/>
                </div>
                <div className="col-6 h-100 p-0 right-side d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column">
                        <img className="login-logo" src={LoginLogo} alt="login logo"/>
                        {resetPasswordForm()}
                    </div>
                    <p className="mini-text terms-policy">
                        <TranslatedText id="login.terms_and_privacy" defaultMessage="Termet e perdorimit. Politikat e privatesise"/>
                    </p>
                </div>
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ResetPassword as any)));
