import * as React from "react"
import { injectIntl } from "react-intl";
import Logo from "../../assets/icons/MIKA_logo-01.svg";
import UserAvatar from "../UserAvatar";
import './Header.scss'
import ProfileIcon from '../../assets/icons/profile-icon.svg'
import { connect } from "react-redux";
import { Dispatch, bindActionCreators} from 'redux'
import * as actions from '../../actions/generalActions';
import { RootState } from "../../reducers";
import { withRouter } from "react-router";
import FontAwesome from 'react-fontawesome'
import {useEffect} from "react";

interface Props {
    intl: any;
    actions: any;
    history: any;
    user:any;
    isNavOpen: boolean;
}

const Header = (props: Props) => {

    useEffect(()=>{
        if(window.innerWidth < 500){
            props.actions.setNavBarOpen(true)
        }
    },[window.innerWidth])
    
    const username = localStorage.getItem('username');

    function logOut () {
        localStorage.removeItem("mikaBearerToken");
        localStorage.removeItem('username');
        localStorage.removeItem('mikaUserId');
        localStorage.removeItem('userPermissions');
        localStorage.removeItem("mikaNavbarToggled");
        props.actions.isLogin(false);
        props.actions.isAuthenticated(false)
        props.history.push({ pathname: '/login' });
    }

    function toggleNavBar () {
        localStorage.setItem("mikaNavbarToggled", JSON.stringify(props.isNavOpen));
        props.actions.setNavBarOpen(!props.isNavOpen)
    }


    return (
        <div className="header">
            <div className="d-flex justify-content-between flex-nowrap">
                <div className="box-left-shadow d-flex justify-content-between">
                    <UserAvatar
                        name={props.user.username || username}
                        avatar={ProfileIcon}
                        logOut={() => logOut()}
                        isNavOpen={props.isNavOpen}
                    />
                    <div className="collapse-bar justify-content-center aligns-items-center" onClick={toggleNavBar}>
                        <div
                            className={`collapse-button ${props.isNavOpen? 'closed': 'opened'}`}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                {/*<div className="logo d-flex align-items-center">*/}
                {/*    <img src={Logo} alt="logo"/>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Header as any)));