import * as constants from "../constants/constants";
import { Responsibility } from '../types/responsibility.type';

export interface ProductionState {
    responsibilities: Responsibility[],
    userResponsibilities: Responsibility[],
    productionTableData: any//make type
    productStatuses: any//make type
    productPrices: any//make type
    warehouseProducts: any//make type
    blueprintFiles: any//make type
    selectedTrack: any
    materialsOfProduct: any
    passiveProducts: any
}

const initialState = () => {
    return {
        responsibilities: [],
        userResponsibilities: [],
        productionTableData: [],
        productStatuses: [],
        productPrices: [],
        warehouseProducts: [],
        blueprintFiles: [],
        selectedTrack: null,
        materialsOfProduct: [],
        passiveProducts: []
    }
}

const production = (state: ProductionState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_RESPONSIBILITIES: {
            return { 
                ...state, 
                responsibilities: action.payload
            };
        }
        case constants.FETCH_USER_RESPONSIBILITIES: {
            return { 
                ...state, 
                userResponsibilities: action.payload
            };
        }
        case constants.FETCH_PRODUCTION_TABLE_DATA: {
            return { 
                ...state, 
                productionTableData: action.payload.data
            };
        }
        case constants.FETCH_PRODUCT_STATUSES: {
            return { 
                ...state, 
                productStatuses: action.payload.data
            };
        }
        case constants.FETCH_PRODUCT_PRICES: {
            return {
                ...state,
                productPrices: action.payload.data
            };
        }
        case constants.FETCH_PASSIVE_PRODUCTS: {
            return {
                ...state,
                passiveProducts: action.payload.data
            };
        }
        case constants.FETCH_WAREHOUSE: {
            return { 
                ...state, 
                warehouseProducts: action.payload.data
            };
        }
        case constants.GET_MATERIALS_FOR_PRODUCTS_AND_RESPONSIBILITY: {
            return {
                ...state,
                productMaterials: action.payload.data
            }
        }
        case constants.GET_BLUEPRINT_FILES: {
            return {
                ...state,
                blueprintFiles: action.payload?.data
            }
        }
        case constants.GET_CURRENT_RESPONSIBILITY_TIME: {
            return {
                ...state,
                selectedTrack: action.payload
            }
        }
        case constants.GET_MATERIALS_OF_PRODUCT: {
            return {
                ...state,
                materialsOfProduct: action.payload
            }
        }
    }
    return state;
}

export default production;