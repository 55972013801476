import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as measuresActions from '../../actions/measures';
import * as clientActions from '../../actions/client';
import {RootState} from '../../reducers';
import {injectIntl} from 'react-intl';
import Spinner from '../../components/Spinner';
import * as warehouseActions from "../../actions/warehouse";
import './warehouse.scss'
import FontAwesome from 'react-fontawesome'
import {setWarehouseRecord} from "../../actions/warehouse";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {ClientObject} from "../../types/client.type";

interface Props {
    history: any;
    intl: any;
    measuresActions: any;
    warehouse: any;
    warehouseActions: any;
    clientActions: any;
    loading: boolean;
    actions: any;
    archivedProducts: any;
    projectId: number;
    projectIndex: number;
    warehouseRecords?: any
    clientId?: any
    resetFields?: any
}

const WarehouseExtraInfo = (props: Props) => {

    const [loading, setLoading] = useState(false)
    const [warehouseRecords, setWarehouseRecords] = useState([])
    const [warehouseId, setWarehouseId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);


    useEffect(() => {
        let tempArray = []
        props?.warehouseRecords?.forEach((record) => {
            tempArray.push({
                ...record,
                id: record?.id,
                assignees: record?.user,
                products: record?.productWarehouseRecord,
                filePath: record?.invoiceWithUrl
            })
        })
        setWarehouseRecords(tempArray)
    }, [props.warehouseRecords])

    function selectWarehouseRecord(warehouseRecord, isDelete?) {
        if(isDelete){
            props.warehouseActions.setWarehouseRecord({})
            props?.resetFields()
        }else{
            props.warehouseActions.setWarehouseRecord({
                ...warehouseRecord,
                projectIndex: props.projectIndex
            })
        }
    }

    function selectWarehouseForDelete(warehouseRecordId) {
        setDeleteModal(true)
        setWarehouseId(warehouseRecordId)
    }

    const deleteWarehouseRecord = () => {
        setLoading(true)
        props.warehouseActions.deleteWarehouseRecord(warehouseId).then(() => {
            props.warehouseActions.getWarehouseRecordExtraInformation(props?.projectId)
            props.warehouseActions.fetchWarehouseClientInformation(props?.clientId)
            setLoading(false)
        })
        setDeleteModal(false)
    }

    return (<div className="warehouseExtraInformationContainer">
        {loading ? <Spinner isTransparent/> : <div className={'warehouseRecordContainer'}>
            {warehouseRecords && warehouseRecords.map((warehouseRecord, index) => {
                return (<div
                    className={`recordCard ${props.warehouse.warehouseRecord?.id === warehouseRecord?.id ? 'selected' : ''}`}
                    key={index}>
                    <div className={'actions d-flex justify-content-between'}>
                        {props.warehouse.warehouseRecord?.id !== warehouseRecord?.id ? <FontAwesome
                                name="edit"
                                style={{color: 'blue', cursor: 'pointer'}}
                                onClick={() => selectWarehouseRecord(warehouseRecord)}
                            /> :
                            <FontAwesome
                                name="close"
                                style={{color: 'red', cursor: 'pointer'}}
                                onClick={() => selectWarehouseRecord({}, true)}
                            />}
                        <p>{warehouseRecord?.label}</p>
                        <FontAwesome
                            name="trash"
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => selectWarehouseForDelete(warehouseRecord?.id)}
                        />
                    </div>
                    {warehouseRecord?.products?.map((product, idx) => {
                        return (<div className={'productRow'} key={idx}>
                            <hr/>
                            <p>{`${product?.productName}`}</p>
                            <p>{`: ${product?.quantityForTransport}`}</p>
                        </div>)
                    })}
                </div>)
            })}
        </div>}
        <ModalComponent
            isModalHeader
            isOpen={isDeleteModalOpen}
            close={setDeleteModal}
        >
            <DeleteModalComponent
                history={props.history}
                close={() => setDeleteModal(false)}
                deleteFunction={() => deleteWarehouseRecord()}/>
        </ModalComponent>
    </div>)
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarehouseExtraInfo)));