import * as constants from "../constants/constants";

export interface ImpressionState {
    impressionRecords: any
    projectsReadyForImpression: any
    fetchedImpression: any,
    impressionNeededInfo: any
}

const initialState = () => {
    return {
        impressionRecords: [],
        projectsReadyForImpression: [],
        fetchedImpression: {},
        impressionNeededInfo: {}
    }
}

const admin = (state: ImpressionState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_IMPRESSION_RECORDS: {
            return {
                ...state,
                impressionRecords: action.payload
            };
        }
        case constants.FETCH_PROJECTS_FOR_IMPRESSION: {
            return {
                ...state,
                projectsReadyForImpression: action.payload
            };
        }
        case constants.FETCH_IMPRESSION: {
            return {
                ...state,
                fetchedImpression: action.payload
            };
        }
        case constants.FETCH_IMPRESSION_NEEDED_INFO: {
            return {
                ...state,
                impressionNeededInfo: action.payload
            };
        }
    }
    return state;
}

export default admin;