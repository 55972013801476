import React, { useEffect } from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import './App.scss';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import { RootState } from './reducers';
import * as actions from './actions/generalActions';
import {logOutLocally} from './actions/generalActions';

import Login from './containers/Login/Login';
import Dashboard from './containers/Dashboard/Dashboard';
import { connect } from 'react-redux';
import { Protected } from './auth/ProtectedRoute';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import LandingPage from './containers/LandingPage/LandingPage'
import {toast} from "react-toastify";

interface Props {
	user: any;
	app: any;
	history:any;
	actions:any;
	authenticate:any;
	loading: boolean;
	intl:any;
}

const App: React.FC<Props> = (props) => {

	const token = localStorage.getItem('mikaBearerToken')
	useEffect(() => {
		if(token) {
			props.actions.authenticate(token).then(res => {
				if(!res) {
					props.actions.isAuthenticated(false)
					props.history.push('/login')
				} else {
					props.actions.isAuthenticated(true)
				}
			})
		}
	}, []);

	return (
		<BrowserRouter>
			<Switch>
				<Route
					exact
					path="/"
					component={LandingPage}
				/>
				
				<Route
					exact
					path="/reset-password/:token/:email"
					component={ResetPassword}
				/>

				<Route
					exact
					path="/login"
					component={Login}
				/>

				<Protected path="/" component={Dashboard}/>

				<Route
					exact
					path="/impression/client-creation"
					component={Login}
				/>
			</Switch>
		</BrowserRouter>
	);
}

function mapStateToProps(state: RootState, ownProps: any) {
	return {
		...ownProps,
		...state.app,
        user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
	return {
        actions: bindActionCreators(actions as any, dispatch),
		logOutLocally: bindActionCreators(logOutLocally, dispatch),
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(App)
