import {EInputTextType, Input} from '../../../components/Input/Input';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {RootState} from '../../../reducers';
import {connect} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../../actions/generalActions';
import * as adminActions from '../../../actions/admin';
import * as productActions from '../../../actions/production';
import '../admin.scss';
import PlusIconLink from '../../../assets/icons/plus.svg'
import {Button, EButtonType} from '../../../components/Button';
import MikaSelect from '../../../components/BbrosSelect/mikaSelect.component';
import {SelectOption} from '../../../types/selectOption.type';
import {axiosCaller} from '../../../utils/apiCaller';
import {ArticleInfomation} from '../../../types/ArticleInformation.type';
import {toast} from 'react-toastify';
import {
    checkCorrespondingInput,
    returnArrayWithValueLabelForSelect,
    setOnlyNewCorrespondingItems
} from "../globalFunctions";
import TableBbros from '../../../components/Table/TableBbros';
import {ARTICLE_COLUMNS, OPTIONS} from '../../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {UnitsObj} from '../../../types/units.type';
import Tooltip from 'rc-tooltip';
import clearFormSvg from '../../../assets/icons/archeology.svg'
import ModalComponent from "../../../components/Modal/ModalComponent";
import Select from "react-select";
import * as transportActions from '../../../actions/transport'
import {setLoading} from "../../../actions/generalActions";
import {isMobile} from "../../../constants/constants";
import DeleteModalComponent from "../../../components/Modal/DeleteModal";
import TranslatedText, {translatedText} from "../../../components/TranslatedText";

interface Props {
    history: any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    productActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    articleTypes: SelectOption[],
    articles: ArticleInfomation[],
    unitsArticle: SelectOption[],
    units: SelectOption[],
    articlesForWarehouse: ArticleInfomation[]
    showSupply: any,
    buttonLoader: boolean,
    departments: SelectOption[]
}

const ArticleListAndCreateForm = (props: Props) => {
    const [articleId, setArticleId] = useState<number>(null)
    const [articleName, setArticleName] = useState<string>('');
    const [productCode, setProductCode] = useState<string>('');
    const [dailyCapacity, setDailyCapacity] = useState<number>();
    const [extraTime, setExtraTime] = useState<number>();
    const [units, setUnits] = useState<SelectOption>({} as SelectOption);
    const [department, setDepartment] = useState<SelectOption>({} as SelectOption);
    const [supplier, setSupplier] = useState<SelectOption>({} as SelectOption);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [selectedArticleId, setSelectedArticleId] = useState();
    const [allUnits, setAllUnits] = useState([]);
    const [allSuppliers, setAllSuppliers] = useState([])
    const [forProduction, setForProduction] = useState<number>(1)
    const [responsibilityIds, setResponsibilityIds] = useState<number[]>([]);
    const [isWarehouseModalOpen, setIsWarehouseModalOpen] = useState<boolean>(false)
    const [selectedArticleForWarehouse, setSelectedArticleForWarehouse] = useState<SelectOption>()
    const [selectedAmountOfWarehouseProducts, setSelectedAmountOfWarehouseProducts] = useState<number>()
    const [order, setOrder] = useState<any[]>([])
    const [equipments, setEquipments] = useState<any[]>([])
    const [deliveryDays, setDeliveryDays] = useState<string>()
    const [equipmentOptions, setEquipmentOptions] = useState([])
    const [supplierCode, setSupplierCode] = useState('')
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);


    useEffect(() => {
        props.actions.fetchArticles();
        props.actions.fetchArticlesForWarehouse();
        props.actions.fetchUnits()
        props.actions.getAllDepartments()
        props.productActions.fetchResponsibilities();
        props.actions.fetchUnitsForArticle();
        props.adminActions.fetchMaterials();
        props.transportActions.fetchEquipments();
        props.adminActions.getSuppliers()
        axiosCaller('GET', `admin/articles/code`).then((res) => {
            setProductCode(res?.data)
        });
    }, []);

    useEffect(() => {
        setEquipmentOptions(props?.transport?.equipments)
    }, [props?.transport?.equipments])

    useEffect(() => {
        if (articleId) {
            try {
                props.adminActions.fetchArticle(articleId);
            } catch (err) {
                toast.info(err);
            }
        } else {
            resetFields()
        }
    }, [articleId])

    useEffect(() => {
        setAllUnits(props.units)
    }, [props.units]);

    useEffect(() => {
        let tempArray = []

        props.admin?.suppliers?.forEach((supplier)=>{
            tempArray.push({label: supplier?.supplier, value: supplier?.id})
        })
        setAllSuppliers(tempArray)

    }, [props.admin.suppliers]);

    function saveArticle() {
        let materialsArray = []
        order.forEach((material, index) => {
            materialsArray.push(material.value)
        })
        let equipmentsArray = []
        equipments.forEach((equipment, index) => {
            equipmentsArray.push(equipment.value)
        })
        let payload = {
            name: articleName,
            productCode: productCode,
            dailyCapacity: dailyCapacity ? dailyCapacity : null,
            extraTime: extraTime,
            unitId: units?.value !== undefined ? units?.value : units?.id,
            responsibilities: responsibilityIds,
            forProduction: forProduction,
            materials: materialsArray,
            equipments: equipmentsArray,
            deliveryDays: deliveryDays,
            suppliersCode: null,
            supplier: null,
            departmentId: department?.value
        };

        if(forProduction === 0){
            payload.supplier = [supplier?.value]
            payload.suppliersCode = [{code: supplierCode}]
        }else{
            delete payload.supplier
            delete payload.suppliersCode
        }

        if (articleName !== "" && productCode !== "" && (forProduction === 1 ? (dailyCapacity !== null && dailyCapacity !== 0) : true) && units?.value !== "" && (forProduction === 1 ? responsibilityIds.length !== 0 : true) && (forProduction === 1 ? order.length !== 0 : true) && (forProduction === 0 ? deliveryDays?.length > 0 : true)) {
            isInEditMode ?
                props.adminActions.editArticle(selectedArticleId, payload).then((res) => {
                    if(res !== false){
                        props.actions.fetchArticles();
                        props.actions.fetchArticlesForWarehouse();
                        resetFields()
                    }
                })
                :
                props.adminActions.createArticle(payload).then(() => {
                    props.actions.fetchArticles();
                    props.actions.fetchArticlesForWarehouse();
                    axiosCaller('GET', `admin/articles/code`).then((res) => {
                        setProductCode(res?.data)
                    });
                    resetFields()
                })
        } else {
            toast.error(translatedText({
                id: "app.pleaseFillForms",
                defaultMessage: "Ju lutem mbushini format"
            }))
        }
    }

    useEffect(() => {
        if (props.admin.articleInformation && articleId) {
            setArticleName(props.admin.articleInformation?.name);
            setUnits(props.admin.articleInformation.unit);
            setProductCode(props.admin.articleInformation?.productCode);
            setDailyCapacity(props.admin.articleInformation?.dailyCapacity);
            setDeliveryDays(props.admin.articleInformation?.deliveryDays)
            setExtraTime(props.admin.articleInformation?.extraTime)
            setForProduction(props.admin.articleInformation?.forProduction ? 1 : 0)
            setDepartment(props.admin.articleInformation?.department || {} as SelectOption)
            let tempArrayOfResponsibilityIds = []
            props.admin?.articleInformation?.responsibilities?.forEach((responsibility) => {
                tempArrayOfResponsibilityIds.push(JSON.stringify(responsibility.id))
            })
            setResponsibilityIds(tempArrayOfResponsibilityIds)
            setOrder(props.admin.articleInformation?.material)
            setEquipments(props.admin.articleInformation?.equipments)
            setSupplierCode(props.admin.articleInformation?.code[0]?.supplierCode)
            setSupplier({value:props.admin.articleInformation?.suppliers[0]?.id, label: props.admin.articleInformation?.suppliers[0]?.supplier})
        } else {
            resetFields()
        }

    }, [props.admin.articleInformation]);

    function editArticleData(item: UnitsObj, method: string) {
        setArticleId(item?.value)
        setSelectedArticleId(item?.value);
        setIsInEditMode(method === 'edit');
    }

    const deleteArticle = () => {
        props.adminActions.deleteArticle(articleId).then(() => {
            props.actions.fetchArticles();
            setDeleteModal(false)
            props.actions.fetchArticlesForWarehouse();
        });
    };

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: UnitsObj) => editArticleData(rowData, 'edit')
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData: UnitsObj) => deleteButtonFunction(rowData)
        },
    ]

    function deleteButtonFunction (article) {
        setArticleId(article?.value)
        setDeleteModal(true)
    }

    function resetFields() {
        setArticleId(null)
        setDepartment({} as SelectOption)
        setArticleName('');
        setProductCode('');
        setDailyCapacity(null);
        setExtraTime(0)
        setUnits({
            label: '',
            value: undefined
        });
        setResponsibilityIds([])
        setIsInEditMode(false)
        setOrder([])
        setEquipments([])
    }

    function addProductToWarehouse() {
        let payload = {
            articleId: selectedArticleForWarehouse?.value,
            quantity: selectedAmountOfWarehouseProducts
        }
        props.productActions.addArticlesToWarehouse(payload).then(() => {
            toast.success('Artikujt u shtuan ne depo!')
        })
    }

    function returnIndexOfThatResponsibility(responsibilityIds, responsibility) {
        let tempIndex = ''
        responsibilityIds.forEach((responsibilityId, index) => {
            if (responsibilityId == responsibility.id) {
                tempIndex = `${index + 1}`
            }
        })
        return tempIndex
    }
    function setExtraTimeValue(value){
        if(value >= 0){
            setExtraTime(value)
        }
    }

    function setDailyCapacityValues(value){
        // if(value > 0){
        setDailyCapacity(value)
        // }
    }

    return (
        <div className="admin-page article-page">
            <div className="create-admin-page-form">
                <div className="input-container">
                    <Input
                        name="articleName"
                        value={articleName || ''}
                        type="text"
                        inputWrapperClass="col-12"
                        onChange={(name, value) => setArticleName(value)}
                        inputTextType={EInputTextType.SimpleInput}
                    >
                        <TranslatedText id="articleName" defaultMessage="Emri i artikullit *"/>
                    </Input>
                    <div className={'d-flex justify-content-between align-items-center w-100 my-3'}>
                        <div className={'w-50 d-flex justify-content-start align-items-center'}>
                            <input type="radio" value={1} checked={forProduction === 1}
                                   onChange={(radioInput) => setForProduction(Number(radioInput.target.value))}
                                   name={'forProduction'} id={'forProduction'} className={'m-3'}/>
                            <label htmlFor="forProduction"><TranslatedText id="forProduction"
                                                                             defaultMessage="Per prodhim"/></label>
                        </div>
                        <div className={'w-50 d-flex justify-content-start align-items-center'}>
                            <input type="radio" value={0} checked={forProduction === 0}
                                   onChange={(radioInput) => setForProduction(Number(radioInput.target.value))}
                                   name={'forProduction'} id={'forWarehouse'} className={'m-3'}/>
                            <label htmlFor="forWarehouse"><TranslatedText id="admin.forWarehouse"
                                                                            defaultMessage="I blere"/></label>
                        </div>
                    </div>
                    <Input
                        name="productCode"
                        value={productCode || ''}
                        type="text"
                        inputWrapperClass="col-12"
                        onChange={(name, value) => setProductCode(value)}
                        inputTextType={EInputTextType.SimpleInput}
                    >
                        <TranslatedText id="productCode" defaultMessage="Kodi i produktit *"/>
                    </Input>

                    {forProduction === 0 && <Input
                        name="deliveryDays"
                        value={deliveryDays || ''}
                        type="text"
                        inputWrapperClass="col-12"
                        onChange={(name, value) => setDeliveryDays(value)}
                        inputTextType={EInputTextType.SimpleInput}
                    >
                        <TranslatedText id="deliveryDays" defaultMessage="Ditet e liferimit"/>
                    </Input>}
                    {forProduction === 0 && <div className='position-relative'>
                        <MikaSelect
                            options={allSuppliers}
                            value={supplier || {} as SelectOption}
                            idRequired
                            setEntireOptionFunction={setSupplier}
                            placeholder={translatedText({id: 'client.units', defaultMessage: 'Furnitori'})}
                            selectWrapperClassName="my-3">
                            <TranslatedText id="productCode" defaultMessage="Furnitori *"/>
                        </MikaSelect>
                        <Input
                            name="deliveryDays"
                            value={supplierCode || ''}
                            type="text"
                            inputWrapperClass="col-12"
                            onChange={(name, value) => setSupplierCode(value)}
                            inputTextType={EInputTextType.SimpleInput}
                        >
                            <TranslatedText id="supplierCode" defaultMessage="Kodi i furnitorit"/>
                        </Input>
                    </div>}

                    <MikaSelect
                        options={allUnits}
                        value={units || {} as SelectOption}
                        idRequired
                        setEntireOptionFunction={setUnits}
                        placeholder={translatedText({id: 'client.units', defaultMessage: 'Njesia'})}
                        selectWrapperClassName="my-3">
                        <TranslatedText id="productCode" defaultMessage="Njesia *"/>
                    </MikaSelect>

                    {forProduction === 1 && <MikaSelect
                        options={props?.departments}
                        value={department || {} as SelectOption}
                        idRequired
                        setEntireOptionFunction={setDepartment}
                        placeholder={translatedText({id: 'client.units', defaultMessage: 'Reparti'})}
                        selectWrapperClassName="my-3">
                        <TranslatedText id="productCode" defaultMessage="Reparti"/>
                    </MikaSelect>}

                    {forProduction === 1 && <Input
                        name="dailyCapacity"
                        value={dailyCapacity || ''}
                        type="number"
                        inputWrapperClass="col-12"
                        min={1}
                        onChange={(name, value) => setDailyCapacityValues(value)}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="dailyCapacity" defaultMessage="Kapaciteti Ditor *"/>
                    </Input>}

                    {forProduction === 1 && <Input
                        name="extraTimeInPercentage"
                        value={extraTime || ''}
                        type="number"
                        inputWrapperClass="col-12"
                        onChange={(name, value) => setExtraTimeValue(value)}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="productCode" defaultMessage="Hapsire kohore ekstra (%)"/>
                    </Input>}
                    {forProduction === 1 && <Select
                        id="order"
                        name="order"
                        options={props.admin.materials}
                        closeMenuOnSelect={false}
                        className={order && order ? "select-input material-select my-3 w-100 input-error" : "select-input material-select my-3 w-100"}
                        classNamePrefix="react-select"
                        isMulti
                        value={order || ''}
                        onChange={(value: any) => setOrder(value)}
                        placeholder={translatedText({
                            id: "client.order",
                            defaultMessage: "Materialet *"
                        })}/>}
                    {forProduction === 1 && <Select
                        id="equipments"
                        name="quipments"
                        options={equipmentOptions}
                        closeMenuOnSelect={false}
                        className={equipments && equipments ? "select-input material-select my-3 w-100 input-error" : "select-input material-select my-3 w-100"}
                        classNamePrefix="react-select"
                        isMulti
                        value={equipments || ''}
                        onChange={(value: any) => setEquipments(value)}
                        placeholder={translatedText({
                            id: "client.order",
                            defaultMessage: "Pajisjet"
                        })}/>}
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        submit={true}
                        className="w-100 mt-5"
                        buttonType={EButtonType.GreyButton}
                        onClick={saveArticle}>
                        {articleId ?
                            <TranslatedText id="createArticle" defaultMessage="Edito kete artikull"/>
                            :
                            <TranslatedText id="createArticle" defaultMessage="Krijo artikullin"/>
                        }
                    </Button>
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        className="w-100 mt-5"
                        buttonType={EButtonType.GreyButton}
                        onClick={() => setIsWarehouseModalOpen(true)}>
                        <TranslatedText id="addWarehouseProducts" defaultMessage="Shto produkt ne depo"/>
                    </Button>
                </div>
            </div>

            {forProduction === 1 && <div className={`responsibilityListContainer ${isMobile ? 'w-100' : ''}`}>
                <p><TranslatedText id="createArticle" defaultMessage="Lista e proceseve *"/></p>
                <div className="responsibilities-container">
                    {props.production.responsibilities && props.production.responsibilities.map((responsibility, index) => {
                        return (
                            <div className="responsibility" key={index}>
                                <Input
                                    id={`input` + responsibility?.label}
                                    name={responsibility?.label}
                                    value={responsibility?.id}
                                    type="checkbox"
                                    inputWrapperClass="checkboxWrapper"
                                    valueNeedeed
                                    checked={checkCorrespondingInput(responsibilityIds, responsibility)}
                                    onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setResponsibilityIds, responsibilityIds)}
                                >
                                    {returnIndexOfThatResponsibility(responsibilityIds, responsibility) &&
                                    <p className={'index'}>{returnIndexOfThatResponsibility(responsibilityIds, responsibility)}</p>}
                                    <p className="m-0">{responsibility?.label}</p>
                                </Input>
                            </div>
                        )
                    })}
                </div>
            </div>}

            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.articlesList" defaultMessage="Lista e artikujve"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({id: "table.artikulli", defaultMessage: "Artikulli"})}
                    data={props.articles}
                    columns={ARTICLE_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isWarehouseModalOpen}
                close={() => setIsWarehouseModalOpen(false)}
            >
                <div className={'w-100 px-3'}>
                    <div className={'warehouseModal-header'}>
                        <p><TranslatedText id="admin.addWarehouseProducts" defaultMessage="Shto produkt ne depo"/></p>
                    </div>
                    <div className={'warehouseModal-content d-flex align-items-center justify-content-between'}>
                        <MikaSelect selectWrapperClassName={'w-50 p-1'}
                                    options={props.articlesForWarehouse}
                                    value={selectedArticleForWarehouse}
                                    setEntireOptionFunction={setSelectedArticleForWarehouse}
                        >
                            <TranslatedText id="admin.selectWarehouseProduct"
                                              defaultMessage="Zgjidh produkin per depo"/>
                        </MikaSelect>
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            value={selectedAmountOfWarehouseProducts || ''}
                            defaultValue=''
                            onChange={(name, value) => setSelectedAmountOfWarehouseProducts(value)}
                            type="number"
                            inputWrapperClass="w-50 p-1"
                            inputTextType={EInputTextType.SimpleInput}
                        >
                            <p className={'mb-1'}><TranslatedText id="admin.amountOfWarehouseProducts"
                                                                    defaultMessage="Sasia e produkteve per depo"/></p>
                        </Input>
                    </div>
                    <div className={'d-flex justify-content-end align-items-center'}>
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            className="w-100 mt-5"
                            buttonType={EButtonType.GreyButton}
                            onClick={addProductToWarehouse}>
                            <TranslatedText id="admin.addWarehouseProducts" defaultMessage="Shto produkt ne depo"/>
                        </Button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteArticle()} />
            </ModalComponent>
        </div>
    );
};

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        transport: state.transport,
        admin: state.admin,
        production: state.production,
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
        productActions: bindActionCreators(productActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch)
    };
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ArticleListAndCreateForm as any)));
