import * as React from 'react';
import classNames from 'classnames';
import {exists} from '../../../utils/variableEvaluation';
import {formatNumber} from '../../../utils/react';
import {EInputTextType} from '../Input';
import {ReactComponent as IconCalendar} from "../../../assets/icons/calendar.svg";
import {ReactComponent as IconSearch} from "../../../assets/icons/search.svg";
import styles from './InputText.module.scss'; // Import css modules stylesheet as styles

interface IProps {
    isFocused?: boolean;
    isLoading?: boolean;
    name?: string;
    value?: string[] | string | number;
    placeholder?: string;
    type?: string;
    onChange?: any;
    // additionalClass?: string;
    isDisabled?: boolean;
    inputTextType?: EInputTextType;
    onBlur?: any;
    autoFocus?: boolean;
    readOnly?: boolean;
    maxLength?: number;
    min?: number;
    max?: number;
    step?: string;
    filtering?: boolean;
    isDropdown?: boolean;
    hasFormikValidation?: boolean;
}

interface IState {
    isFocused: boolean;
}

export class InputText extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFocused: false
        }
    }

    private inputRef!: HTMLInputElement;
    private focusTimeoutId: any;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
            if (this.props.isFocused) {
                this.inputRef.focus();
            }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const determinedValue = this.determineValue(value);
        const finalValue = typeof determinedValue == "undefined" ? "" : determinedValue;
        this.props.onChange && this.props.onChange(name, finalValue);
    };

    private handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isFocused: true});
    };

    private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const determinedValue = this.determineValue(value);
        if (this.props.type === "date") {
            this.setState({isFocused: false}, () => {
                const determinedValue = this.determineValue(value);
                const finalValue = typeof determinedValue == "undefined" ? "" : determinedValue;
                this.props.onChange && this.props.onChange(name, finalValue);
            });
        } else {
            this.setState({isFocused: false});
        }
        this.props.onBlur && this.props.onBlur(e);
    };

    determineValue = (val:any) => {
        if (this.props.type === "date") {
            return this.formatDate(val)
        } else if (this.props.type === "year") {
            return this.formatYear(val)
        } else if (this.props.type === "month") {
            return this.formatMonth(val)
        } else {
            if (this.state.isFocused) {
                return val;
            } else {
                if (this.props.type === "money") {
                    return formatNumber(val);
                    // } else if (this.props.type === "percentage") {
                    //     return val ? (val + "%") : "";
                } else {
                    return val;
                }
            }
        }
    }

    formatYear = (val:any) => {
        if (val === "" || !val) return val
        val = val.replace(/[^\d]/g, '');
        if(val.length > 4) {
            val = val.slice(0, 4);
        }
        if(val.length === 4) {
            if(parseInt(val) < 1970) {
                return "1970";
            }
            if(parseInt(val) > 2100) {
                return "2100";
            }
        }

        return val;
    }

    formatMonth = (val:any) => {
        if (val === "" || !val) return val
        val = val.replace(/[^\d]/g, '');
        if(val.length > 2) {
            val = val.slice(0, 2);
        }
        if(parseInt(val) > 12) {
            val = "12";
        }
        if(val.length > 1 && parseInt(val) < 1) {
            val = "01";
        }
        return val;
    }

    formatDate = (val:any) => {
        if (val && Array.from(val).length > 0) {
            val = val.replace(/[^\d.-]/g, '');

            if (val === "") return val;
            var value = val;
            if (!value) return val;
            if (this.state.isFocused) {
                var parts = val.split(".");
                if (parts.length === 1) {
                    if (val.length > 2) {
                        value = val[0] + val[1] + "." + val[2];
                    }
                }
                if (parts.length === 2) {
                    if (parts[0].length === 1 && parts[1].length === 0) {
                        value = "0" + parts[0] + ".";
                    }
                    if (parts[1].length === 3) {
                        value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
                    }
                }
                if (parts.length === 3) {
                    if (parts[1].length === 1 && parts[2].length === 0) {
                        value = parts[0] + ".0" + parts[1] + "." + parts[2];
                    }
                    if (parts[1].length === 2) {
                        value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
                    }
                }
            } else {
                var parts = val.split(".");
                if (parts.length === 1) {
                    if (val.length > 2) {
                        return val[0] + val[1] + "." + val[2];
                    }
                }
                if (parts.length === 2) {
                    if (parts[0].length === 1) {
                        return "0" + parts[0] + ".";
                    }
                    if (parts[1].length > 2) {
                        return parts[0] + "." + parts[1][0] + parts[1][1] + ".";
                    }
                }
                if (parts.length === 3) {
                    if (parts[2].length === 2) {
                        if (parseInt(parts[2]) < 45) {
                            value = parts[0] + "." + parts[1] + "." + "20" + parts[2];
                        } else {
                            value = parts[0] + "." + parts[1] + "." + "19" + parts[2];
                        }
                    } else if (parts[2].length === 4) {
                        value = parts[0] + "." + parts[1] + "." + parts[2];
                    }
                    if (parts[0].length === 1) {
                        value = "0" + parts[0] + "." + parts[1] + "." + parts[2];
                    }
                    if (parts[1].length === 1) {
                        value = parts[0] + ".0" + parts[1] + "." + parts[2];
                    }
                }
            }
            return value;
        }
    }

    public render() {
        const {
            name,
            value,
            placeholder,
            type,
            isFocused,
            isLoading,
            // additionalClass,
            isDisabled,
            readOnly,
            min,
            max,
            step,
            maxLength,
            inputTextType,
            filtering,
            isDropdown,
            hasFormikValidation
        } = this.props;

        const inputClass = classNames(styles.inputText, {
            [styles.hasValue]: exists(value),
            [styles.textStandard]: inputTextType === EInputTextType.Standard,
            [styles.textMaterial]: inputTextType === EInputTextType.Material,
            [styles.simpleInput]: inputTextType === EInputTextType.SimpleInput,
        });

        return (
            <div className={styles.inputContainer}>

                <input
                    name={name}
                    type={type === "money" || type === "date" || type === "month" || type === "year" ? "text" : type}
                    min={min}
                    max={max}
                    step={step}
                    // placeholder={placeholder}
                    value={this.determineValue(value)}
                    maxLength={maxLength || 255}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    readOnly={readOnly}
                    onChange={hasFormikValidation ? this.props.onChange : this.handleChange}
                    className={inputClass}
                    ref={(input:any) => isFocused && (this.inputRef = input)}
                    autoFocus={isFocused}
                    disabled={isDisabled || isLoading}
                    autoComplete="off"
                />
                <span className={styles.label}>{placeholder}</span>

                {!isDropdown ? type === "date" || type === "year" || type === "month" ? <IconCalendar/> : filtering ?
                    <IconSearch className="search-icon"/> : null : null}
            </div>
        );
    }
}
