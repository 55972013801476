import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import {setButtonLoading, wrap} from './generalActions'
import {toast} from "react-toastify";

export const getServiceRecords = (statusId?) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing/existing/servicing${statusId ? `?status=${statusId}` : ''}`).then(res => {
            dispatch({ "type": constants.FETCH_SERVICE_RECORDS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getNotRegisteredServiceRecords = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing/non/client`).then(res => {
            dispatch({ "type": constants.FETCH_SERVICE_RECORDS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getProjectReadyForService = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing?status=1`).then(res => {
            dispatch({ "type": constants.FETCH_PROJECTS_FOR_SERVICE, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const createService = (payload) => wrap(async(dispatch:any) => {
    try {
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `servicing`, payload).then(res => {
            toast.success('Servisimi u krijua me sukses');
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const editService = (payload, serviceId) => wrap(async(dispatch:any) => {
    try {
        dispatch(setButtonLoading(true));
        return await axiosCaller('POST', `servicing/${serviceId}`, payload).then(res => {
            toast.success('Servisimi u editua me sukses');
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getService = (serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing/${serviceId}`).then(res => {
            dispatch({ "type": constants.FETCH_SERVICE, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const deleteServiceRecord = (serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('DELETE', `servicing/${serviceId}`).then(res => {
            toast.info('Servisimi u fshi me sukses!')
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const setSelectedProjectForService = (payload:boolean) => wrap(async(dispatch:any, state:any) => {
    dispatch({ "type": constants.SET_SELECTED_PROJECT_FOR_SERVICE, payload: payload });
}, 'LOGIN_ERROR');

export const getServiceMapData = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing/map`).then(res => {
            console.log(res)
            dispatch({ "type": constants.GET_SERVICE_MAP_DATA, payload: res?.data?.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')