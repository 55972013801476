import * as React from 'react';
import DatePicker from 'react-date-picker';
import { EInputTextType } from '../Input';
import { ReactComponent as IconCalendar } from "../../../assets/icons/calendar.svg";
import styles from './InputDate.module.scss'; // Import css modules stylesheet as styles


interface IProps {
    name?: string;
    minDate?: Date;
    maxDate?: Date;
    value?: Date | Date[];
    onChange?: (name?: string, value?: Date | Date[]) => void;
    additionalClass?: string;
    isDisabled?: boolean;
    inputTextType?: EInputTextType;
    autoFocus?: boolean;
    readOnly?: boolean;
    maxLength?: number;
}

interface IState {
}

export class InputDate extends React.Component<IProps, IState> {
    public render() {
        const {
            name,
            value,
            onChange,
            minDate,
            maxDate
        } = this.props;

        return (
            <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                name={name}
                className={styles.inputDate}
                calendarIcon={<IconCalendar />}
                clearIcon={null}
                value={value as any}
                onChange={(date:any) => onChange && onChange(name, date)}
                format="dd/MM/yyyy"
            />
        );
    }
}
