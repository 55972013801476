import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Service.scss'
import * as productionActions from '../../actions/production'
import * as installationActions from '../../actions/installation'
import TableBbros from '../../components/Table/TableBbros';
import {
    NOT_REGISTERED_CLIENTS_SERVICE_RECORDS_COLUMNS_MOBILE,
    OPTIONS,
    SERVICE_RECORDS_COLUMNS_ARCHIVE, SERVICE_RECORDS_COLUMNS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import * as transportActions from "../../actions/transport";
import * as serviceActions from "../../actions/service";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {isMobile} from '../../constants/constants'
import {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {getNotRegisteredServiceRecords, setSelectedProjectForService} from "../../actions/service";
import {toast} from "react-toastify";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    transportActions: any
    transport: any
    archiveRecords: any
    installationActions: any
    installation: any
    serviceActions: any
    service: any
    notRegisteredClients: boolean
}

const AllServiceRecords = (props: Props) => {

    const {statusId} = useParams<ParameterTypes>();
    const [serviceRecordId, setServiceRecordId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        if(props?.notRegisteredClients){
            props.serviceActions.getNotRegisteredServiceRecords()
        }else{
            props.serviceActions.getServiceRecords(statusId)
        }
    }, [statusId])

    useEffect(()=>{
        if(props?.notRegisteredClients){
            props.serviceActions.getNotRegisteredServiceRecords()
        }else{
            props.serviceActions.getServiceRecords(statusId)
        }
    },[props?.notRegisteredClients])


    const editServiceData = (serviceRecord) => {
        if(serviceRecord?.project?.id === null){
            props.history.push({pathname: `/dashboard/service/not-registered-clients/${serviceRecord?.id}`})
        }else{
            if(serviceRecord?.id){
                props.history.push({pathname: `/dashboard/service/create/${serviceRecord?.id}`})
            }else{
                let tempObj = {
                    value: serviceRecord?.id,
                    firstName: serviceRecord?.client?.firstnameAndLastname,
                    lastName: serviceRecord?.client?.lastName,
                    address: serviceRecord?.client?.address,
                    city: serviceRecord?.client?.city,
                    email: serviceRecord?.client?.email,
                    phoneNumber: serviceRecord?.client?.phoneNumber
                }

                props?.serviceActions?.setSelectedProjectForService(tempObj)

                props.history.push({pathname: `/dashboard/service/create`})
            }
        }
    }

    const handleRemove = (serviceRecord) => {
        if(serviceRecord?.servicing?.id){
            setDeleteModal(true)
            setServiceRecordId(serviceRecord?.servicing?.id)
        }else{
            toast?.info('Ky projekt nuk ka tiket servisimi!')
        }
    }

    const deleteService = () => {
        props.serviceActions.deleteServiceRecord(serviceRecordId).then(()=>{
            props.serviceActions.getServiceRecords(statusId)
        })
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event, rowData) => editServiceData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => handleRemove(rowData)
        }
    ]

    const tableNames = {
        1: "Serviset ne pritje",
        2: "Serviset ne proces",
        3: "Serviset e perfunduara",
    }

    return(
        <div className="wrapper serviceContainer">
            {props?.notRegisteredClients && <div className={'createNotRegisteredClient'}
                  onClick={() => props?.history?.push('/dashboard/service/not-registered-clients')}>
                <img src={DifferentColoredPlus}/>
            </div>}
            <TableBbros
                title={props?.notRegisteredClients ? translatedText({id: "table.warehouse", defaultMessage: "Klientat e meparshem"}) : statusId ? tableNames[statusId] : translatedText({id: "table.warehouse", defaultMessage: "Servisimi"})}
                data={props?.service?.serviceRecords}
                columns={props?.notRegisteredClients ? NOT_REGISTERED_CLIENTS_SERVICE_RECORDS_COLUMNS_MOBILE : isMobile ? NOT_REGISTERED_CLIENTS_SERVICE_RECORDS_COLUMNS_MOBILE: NOT_REGISTERED_CLIENTS_SERVICE_RECORDS_COLUMNS_MOBILE}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteService()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        transport: state.transport,
        installation: state.installation,
        service: state.service
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllServiceRecords as any)));