import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as measuresActions from '../../actions/measures';
import * as clientActions from '../../actions/client';
import {ParameterTypes} from '../../types/parameter.type';
import {SelectOption} from '../../types/selectOption.type';
import {RootState} from '../../reducers';
import {FormattedMessage, injectIntl} from 'react-intl';
import Spinner from '../../components/Spinner';
import * as warehouseActions from "../../actions/warehouse";
import './warehouse.scss'
import Select from "react-select";
import FontAwesome from 'react-fontawesome';
import Tooltip from "rc-tooltip";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import {editWarehouseRecords, getWarehouseLeftOvers} from "../../actions/warehouse";
import {isMobile} from "../../constants/constants";
import {EInputTextType, Input} from "../../components/Input/Input";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import clearFormSvg from "../../assets/icons/archeology.svg";
import WarehouseExtraInfo from "./WarehouseExtraInfo";
import {FileUpload} from "../../types/fileUpload.type";
import ReactToPrint from "react-to-print";
import FileIcon from "../../assets/icons/file-text.svg";
import {WarehouseRecordPrint} from "./WarehouseRecordPrint";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import WarehouseProjectCard from "./WarehouseProjectCard";

interface Props {
    history: any;
    intl: any;
    measuresActions: any;
    warehouse: any;
    warehouseActions: any;
    clientActions: any;
    loading: boolean;
    actions: any;
    buttonLoader: boolean
    permissions: any
}

const CreateWarehouseForm = (props: Props) => {

    const {clientId, projectId} = useParams<ParameterTypes>();
    const [firstAndLastName, setFirstAndLastName] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>(' ');
    const [projects, setProjects] = useState([])
    const [selectedProjectId, setSelectedProjectId] = useState<any>()

    useEffect(() => {
        props.warehouseActions.fetchTransportAssigneesOptions()
        props.warehouseActions.fetchInstallationAssigneesOptions()
    }, [])

    useEffect(() => {
        if (clientId) {
            props.warehouseActions.fetchWarehouseClientInformation(clientId)
        }
    }, [clientId])

    useEffect(() => {
        if(projectId){
            setSelectedProjectId(projectId)
        }
    }, [projectId]);

    useEffect(() => {
        let tempArray = []
        props?.warehouse?.warehouseClientInformation?.forEach((project) => {// let wantedProject = props?.warehouse?.warehouseClientInformation?.warehouseRecords?.find(record => record?.project[0]?.id === offer?.projectId)
            setFirstAndLastName(`${project?.client?.name}`)
            console.log(project, 'project')
            tempArray.push({
                id: project?.projectId,
                edit: true
            })
        })
        if (tempArray.length !== 0) {
            setProjects(props?.warehouse?.warehouseClientInformation)
        }
    }, [props?.warehouse?.warehouseClientInformation])

    useEffect(() => {
        console.log(searchValue,'asd')
        if(searchValue){
            let tempArray = filterProjectsByName(projects, searchValue)
            setProjects(tempArray)
        }else{
            console.log(props?.warehouse?.warehouseClientInformation,'props?.warehouse?.warehouseClientInformation?.offer')
            setProjects(props?.warehouse?.warehouseClientInformation)
        }
    }, [searchValue]);

    function filterProjectsByName(projects, projectName) {
        return projects?.filter(project => project.projectName.toLowerCase().includes(projectName.toLowerCase()));
    }

    if (props.loading) return (<Spinner/>)
    return (
        <div className="wrapper warehouse-form-wrapper">
            {props?.warehouse?.warehouseClientInformation && <div className={'warehouseClientInformationContainer'}>
                <div className={'projectInformation '}>
                    <div className={'projectName d-flex w-100'}>
                        <div className={'w-50'}>
                            <p className={'titleField'}>Emri dhe mbiemri i klientit</p>
                            <p className={'m-0'}>{firstAndLastName}</p>
                        </div>
                        <div className={'w-50 searchBarContainer'}>
                            <Input
                                name="search"
                                label="search"
                                id="search"
                                defaultValue=''
                                inputWrapperClass={"w-100 m-0"}
                                type="text"
                                value={searchValue}
                                inputTextType={EInputTextType.SimpleInput}
                                placeholder={translatedText({id: 'searchBasedOnProjects', defaultMessage:'Kerko ne baze te projektit'})}
                                onChange={(name, value) => setSearchValue(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={'projectContainer'}>
                    {projects?.map((project, index) => {
                        return (
                            <WarehouseProjectCard key={index} history={props?.history} collapsed={project?.projectId != selectedProjectId} firstAndLastName={firstAndLastName} warehouseProject={project} projectId={project?.projectId} projectIndex={index} clientId={clientId}/>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateWarehouseForm)));