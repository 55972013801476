import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './signatureCanvas.scss'


interface Props {
    setSignatureStateFile: any
    signatureTitle: string
}
function SignaturePad(props: Props) {
    const [signatureImage, setSignatureImage] = useState(null);
    const signatureRef = useRef<any>();

    const clearSignature = () => {
        signatureRef.current.clear();
        setSignatureImage(null)
    };

    const saveSignature = () => {
        const signatureData = signatureRef.current.toDataURL();
        setSignatureImage(signatureData);
        convertToBlob(signatureData);
        props?.setSignatureStateFile(signatureData)
    };

    const convertToBlob = (dataUrl) => {
        fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], `${props?.signatureTitle}.png`, { type: 'image/png' });
                props?.setSignatureStateFile({fileName: `${props?.signatureTitle}.png`, file})
            });
    };

    return (
        <div className={'signatureWrapper'}>
            <p>{props?.signatureTitle}</p>
            <SignatureCanvas
                ref={signatureRef}
                canvasProps={{ width: 400, height: 200, className: 'signature-canvas' }}
            />
            <div>
                <button onClick={clearSignature}>Fshije</button>
                {!signatureImage && <button onClick={saveSignature}>Ruaj</button>}
            </div>
            {/*{signatureImage && (*/}
            {/*    <div>*/}
            {/*        <p>Saved Signature:</p>*/}
            {/*        <img src={signatureImage} alt="Saved Signature" />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
}

export default SignaturePad;
