import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as transportActions from '../../actions/transport';
import * as installationActions from '../../actions/installation';
import "./Installation.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import Select from "react-select";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import Tooltip from "rc-tooltip";
import calendarIcon from "../../assets/icons/blackCalendar.svg";
import moment from "moment";
import FontAwesome from 'react-fontawesome';
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {
    createInstallationRecord,
    editInstallationRecord,
    getInstallationRecordByProjectId, getProjectsReadyForInstallation
} from "../../actions/installation";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {FileUpload} from "../../types/fileUpload.type";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import PICFile from "../../assets/icons/pic.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {isMobile, leafletMapIconBase64} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import ReactToPrint from "react-to-print";
import FileIcon from "../../assets/icons/file-text.svg";
import {MeasureImages} from "../Measures/MeasureImages";
import SignaturePad from "../../components/SignatureCanvas/SignatureCanvas";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    installation: any
    installationActions: any
    history: any
    buttonLoader: boolean
    permissions: any
}

const CreateInstallationForm = (props: Props) => {

    const { transportId } = useParams<ParameterTypes>();
    const [comment, setComment] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [allInstallationFiles, setAllInstallationFiles] = useState<any>()
    const [selectedProject, setSelectedProject] = useState<any>([])
    const [installerSignature, setInstallerSignature] = useState<FileUpload>({} as FileUpload);
    const [clientSignature, setClientSignature] = useState<FileUpload>({} as FileUpload);
    const [installerPictures, setInstallerPictures] = useState<FileUpload[]>([] as FileUpload[]);
    const [conditionalInstallation, setConditionalInstallation] = useState<boolean>(false)
    const [isMapOpened, setIsMapOpened] = useState<boolean>(false)
    const [measureFiles, setMeasureFiles] = useState([])
    const [agreementFiles, setAgreementFiles] = useState([])



    useEffect(()=>{
        if(transportId){
            props?.installationActions.getInstallationRecordByTransportId(transportId)
        }else{
            resetFields()
        }
    },[transportId])

    useEffect(()=>{
        if(props?.installation?.installationRecordByTransportId){
            console.log(props?.installation?.installationRecordByTransportId,'props?.installation?.installationRecordByTransportId')
            setSelectedProject({
                value: props?.installation?.installationRecordByTransportId?.project?.id,
                label: props?.installation?.installationRecordByTransportId?.project?.name,
                coordinates: props?.installation?.installationRecordByTransportId.measure?.location ?  props?.installation?.installationRecordByTransportId.measure?.location?.split(',') : ''
            })
            if(props?.installation?.installationRecordByTransportId?.installation){
                setStartDate(props?.installation?.installationRecordByTransportId?.installation?.installationStart)
                setEndDate(props?.installation?.installationRecordByTransportId?.installation?.installationEnd)
                setComment(props?.installation?.installationRecordByTransportId?.installation?.comment)
            }
            let tempFileArray = []
            props?.installation?.installationRecordByTransportId?.installation?.installationFiles?.forEach((file)=>{
                if(file?.installationFileWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.installationFileWithUrl, name: file?.installationFileName})
                }else if(file?.clientSignatureWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.clientSignatureWithUrl, name: file?.clientSignatureFileName})
                }else if(file?.installerSignatureWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.installerSignatureWithUrl, name: file?.installerSignatureFileName})
                }
            })
            setAllInstallationFiles(tempFileArray)
            let tempMeasureFilesArray = []
            props?.installation?.installationRecordByTransportId?.measure?.files?.forEach((file) => {
                if (file?.measureWithUrl) {
                    tempMeasureFilesArray.push({ id: file.id, fileUrl: file?.measureWithUrl, name: file?.measureName })
                } else if (file?.pictureWithUrl) {
                    tempMeasureFilesArray.push({ id: file.id, fileUrl: file?.pictureWithUrl, name: file?.pictureName })
                } else if (file?.sketchWithUrl) {
                    tempMeasureFilesArray.push({ id: file.id, fileUrl: file?.sketchWithUrl, name: file?.sketchName })
                }
            })
            setMeasureFiles(tempMeasureFilesArray)
            let tempAgreementFilesArray = []
            props?.installation?.installationRecordByTransportId?.agreement?.files?.forEach((file) => {
                if (file?.annexesWithUrl) {
                    tempAgreementFilesArray.push({ id: file.id, fileUrl: file?.annexesWithUrl, name: file?.annexes })
                } else if (file?.finalAgreementWithUrl) {
                    tempAgreementFilesArray.push({ id: file.id, fileUrl: file?.finalAgreementWithUrl, name: file?.finalAgreementName })
                } else if (file?.renderWithUrl) {
                    tempAgreementFilesArray.push({ id: file.id, fileUrl: file?.renderWithUrl, name: file?.renderName })
                }
            })
            setAgreementFiles(tempAgreementFilesArray)
        }
    },[props?.installation?.installationRecordByTransportId])

    function resetFields() {
        setComment('')
        setInstallerPictures([])
        setInstallerSignature({} as FileUpload)
        setClientSignature({} as FileUpload)
        setStartDate('')
        setEndDate('')
        props.history.push('/dashboard/installation/create')
    }

    function saveInstallation(){
            let formData = new FormData()

            formData.append('projectId', selectedProject.value)
            formData.append('transportId', transportId)

            if(startDate){
                formData.append('installationStart', startDate)
            }else{
                toast.error('Data e nisjes eshte obligative!')
                return
            }
            if(endDate){
                if(!(installerSignature.file && clientSignature.file)){
                    toast.error('Ju lutem jepni nenshkrimet e kerkuara!')
                    return;
                }
                formData.append('installationEnd', endDate)
            }
            if(installerSignature?.file){
                formData.append('installerSignature[]', installerSignature.file)
            }
            if(clientSignature?.file){
                formData.append('clientSignature[]', clientSignature.file)
            }
            if(installerPictures){
                installerPictures?.forEach((pic)=>{
                    formData.append('installationFiles[]', pic.file)
                })
            }
            formData.append('comment', comment || '')
            if(props?.installation?.installationRecordByTransportId?.installation?.id){
                props.installationActions?.editInstallationRecord(formData, props?.installation?.installationRecordByTransportId?.installation?.id)
                props?.installationActions.getInstallationRecordByTransportId(transportId)
            }else{
                props.installationActions?.createInstallationRecord(formData).then(()=>{
                    // props.history.push('/dashboard/installation')
                })
            }
    }

    function clearDate(which) {
        if(which === 'start'){
            setStartDate('')
        }else{
            setEndDate('')
        }
    }

    function getCurrentDate(which) {
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss")
        if(which === 'start'){
            setStartDate(currentDate)
        }else{
            setEndDate(currentDate)
        }
    }

    function createInstallationForm() {
        return (
            <div className={'w-100 mb-1 installationContainer'}>
                {selectedProject?.value && selectedProject?.coordinates && isMapOpened && <div className="mapContainer">
                    <MapContainer
                        center={selectedProject?.coordinates}
                        zoom={10}
                        scrollWheelZoom={false}
                        className={'map'}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {selectedProject?.coordinates?.length === 2 && <Marker
                            position={selectedProject?.coordinates}
                            icon={icon}
                        >
                            <Popup>
                                <p className={'mb-0'}>{selectedProject?.label}</p>
                                {selectedProject?.coordinates[0] && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/?q=${selectedProject?.coordinates[0]},${selectedProject?.coordinates[1]}`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google
                                    maps</a>}
                            </Popup>
                        </Marker>}
                    </MapContainer>
                </div>}
                <div className={`projectInfoContainer ${isMapOpened ? 'mapOpened' : ''}`}>
                    <div className={'installationMapIcon'} onClick={()=>setIsMapOpened(!isMapOpened)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path fill={'#34366D'} d="M384 476.1L192 421.2V35.9L384 90.8V476.1zm32-1.2V88.4L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3V394.6c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2V423.6L32.9 474.5C17.1 480.8 0 469.2 0 452.2V117.4c0-9.8 6-18.6 15.1-22.3z"/></svg>
                    </div>
                    <h1>{selectedProject?.label} {props?.installation?.installationRecordByTransportId?.warehouseRecord?.label ? `(${props?.installation?.installationRecordByTransportId?.warehouseRecord?.label})` : ''}</h1>
                    <div className={'d-flex w-100'}>
                        <div className={'w-50'}>
                            <p>Produktet: </p>
                            {props?.installation?.installationRecordByTransportId?.warehouseRecord?.products?.map((product)=>{
                                return <p>{product?.productName}: {product?.quantityForTransport}</p>
                            })}
                        </div>
                        {props?.installation?.installationRecordByTransportId?.equipments?.length !== 0 && <div className={'w-50'}>
                            <p>Pajisjet: </p>
                            {props?.installation?.installationRecordByTransportId?.equipments?.map((product) => {
                                return <p>{product?.label}</p>
                            })}
                        </div>}
                    </div>
                </div>
                <div className={`d-flex justify-content-between ${isMobile? 'flex-column':'mb-1'}`}>
                    <div className={`${isMobile? 'w-100': 'w-50'} pr-15`}>
                        <div className={'allInstallationFiles'}>
                            {allInstallationFiles && allInstallationFiles.map((item, index)=>{
                                return (
                                    <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={index}>
                                        <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                        <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                    </div>
                                )
                            })}
                        </div>
                        <MikaFileInput labelKey={translatedText({
                            id: "offer.addFinalOffer",
                            defaultMessage: "Shto fotot e instalimit"
                        })} accept="image/*" multiple setFiles={setInstallerPictures} value={installerPictures} className={'mt-3 w-100 p-3'}/>
                        <div className={'d-flex w-100'}>
                            {measureFiles?.length > 0 && <div className="measureFiles w-50">
                                <p className="small-text product-measure-photos">Dokumentat e matjes</p>
                                {measureFiles?.map((item, id) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center mb-2 cursor-pointer position-relative"
                                            key={id}>
                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                            <a target={'_blank'} href={item.fileUrl}
                                               className="link-pics">{item.name}</a>
                                        </div>
                                    )
                                })}
                            </div>}
                            {agreementFiles?.length > 0 && <div className="measureFiles w-50">
                                <p className="small-text product-measure-photos">Dokumentat e marrveshjes</p>
                                {agreementFiles?.map((item, id) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center mb-2 cursor-pointer position-relative"
                                            key={id}>
                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                            <a target={'_blank'} href={item.fileUrl}
                                               className="link-pics">{item.name}</a>
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>
                    </div>
                    <div className={`${isMobile ? 'w-100':'w-50'}`}>
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"w-100 textarea-wrapper"}
                            type="textarea"
                            value={comment || ''}
                            onChange={(name, value) => setComment(value)}
                        >
                            <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Komenti"/></p>
                        </Input>
                        <div className={'d-flex pt-2 justify-content-end'}>
                            <div className={'conditionalInstallation'}>
                                <label htmlFor={`conditionalInstallation`}><TranslatedText id="conditionalInstallation"
                                                                                           defaultMessage="Instalim me kusht"/></label>
                                <input type="checkbox" checked={conditionalInstallation} onChange={()=>setConditionalInstallation(!conditionalInstallation)} id={`conditionalInstallation`} />
                            </div>
                        </div>
                        <div className={`d-flex flex-wrap mt-5 ${isMobile ? 'flex-column': ''}`}>
                            <div className={`${isMobile? 'w-100':'w-50 pr-12'} d-flex align-items-end`}>
                                <Input
                                    name="orderAcceptanceDate"
                                    label="label"
                                    id="orderAcceptanceDate"
                                    inputWrapperClass={"m-0 w-100 startEndInstallationInputs"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={startDate || ''}
                                    disabled
                                >
                                    <p className={'m-0'}>Data e fillimit te instalimit</p>
                                    <div className={'clearDate'}>
                                        <p className={'x-sign'} onClick={()=>clearDate('start')}>+</p>
                                    </div>
                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                                                                                                     overlay={<span>Ruaj daten</span>}>
                                        <img src={calendarIcon} alt=""
                                             className="pinLogoForMeasure"
                                             onClick={() => getCurrentDate('start')}/>
                                    </Tooltip>}
                                </Input>
                            </div>
                            <div className={`${isMobile? 'w-100':'w-50 pl-12'} d-flex align-items-end`}>
                                <Input
                                    name="orderAcceptationDate"
                                    label="label"
                                    id="orderAcceptationDate"
                                    inputWrapperClass={"m-0 w-100 startEndInstallationInputs"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={endDate || ''}
                                    disabled
                                >
                                    <p className={'m-0'}>Data e perfundimit te instalimit</p>
                                    <div className={'clearDate'}>
                                        <p className={'x-sign'} onClick={()=>clearDate('end')}>+</p>
                                    </div>
                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                                                                                                     overlay={<span>Ruaj daten</span>}>
                                        <img src={calendarIcon} alt=""
                                             className="pinLogoForMeasure"
                                             onClick={() => getCurrentDate('end')}/>
                                    </Tooltip>}
                                </Input>
                            </div>
                            <div className={`${isMobile ? 'w-100':'w-50'} d-flex align-items-end file-input-wrapper`}>
                                {/*<MikaFileInput labelKey={translatedText({*/}
                                {/*    id: "offer.addFinalOffer",*/}
                                {/*    defaultMessage: "Nenshkrimi i instaluesit"*/}
                                {/*})} setFiles={setInstallerSignature} value={installerSignature} className={'mt-3 w-100 p-3'}/>*/}
                                <SignaturePad signatureTitle={translatedText({
                                    id: "offer.addFinalOffer",
                                    defaultMessage: "Nenshkrimi i instaluesit"
                                })} setSignatureStateFile={setInstallerSignature} />
                            </div>
                            <div className={`${isMobile ? 'w-100':'w-50'} d-flex align-items-end file-input-wrapper`}>
                                {/*<MikaFileInput labelKey={translatedText({*/}
                                {/*    id: "offer.addFinalOffer",*/}
                                {/*    defaultMessage: "Nenshkrimi i klientit"*/}
                                {/*})} setFiles={setClientSignature} value={clientSignature} className={'mt-3 w-100 p-3'}/>*/}
                                <SignaturePad signatureTitle={translatedText({
                                    id: "offer.addFinalOffer",
                                    defaultMessage: "Nenshkrimi i klientit"
                                })} setSignatureStateFile={setClientSignature} />

                            </div>

                        </div>
                    </div>
                </div>
                <div className={'w-100 d-flex justify-content-end'}>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveInstallation}
                        className="w-50"
                        buttonType={EButtonType.PurpleButton}>
                        <TranslatedText id="client.editContact"
                                        defaultMessage="Ruaj"/>
                    </Button>}
                </div>
            </div>
        )
    }

    let icon = L.icon({
        iconUrl: leafletMapIconBase64,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })


    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> : createInstallationForm()}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        installation: state.installation,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateInstallationForm as any)))