import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as measuresActions from '../../actions/measures';
import * as clientActions from '../../actions/client';
import {RootState} from '../../reducers';
import {injectIntl} from 'react-intl';
import * as warehouseActions from "../../actions/warehouse";
import './warehouse.scss'
import FontAwesome from 'react-fontawesome'
import {isMobile} from "../../constants/constants";
import ReactToPrint from "react-to-print";
import Tooltip from "rc-tooltip";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import FileIcon from "../../assets/icons/file-text.svg";
import {WarehouseRecordPrint} from "./WarehouseRecordPrint";
import {EInputTextType, Input} from "../../components/Input/Input";
import clearFormSvg from "../../assets/icons/archeology.svg";
import Select from "react-select";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import {FileUpload} from "../../types/fileUpload.type";
import WarehouseExtraInfo from "./WarehouseExtraInfo";

interface Props {
    history: any;
    intl: any;
    measuresActions: any;
    warehouse: any;
    warehouseActions: any;
    clientActions: any;
    loading: boolean;
    actions: any;
    archivedProducts: any;
    projectId: number;
    clientId?: any
    warehouseProject: any
    firstAndLastName?: any
    projectIndex?: any
    permissions?: any
    buttonLoader: boolean
    collapsed?: boolean
}

const WarehouseProjectCard = (props: Props) => {

    const componentRef = useRef()
    const [quantities, setQuantities] = useState([])
    const [transportAssignees, setTransportAssignees] = useState<any>([])
    const [installationAssignees, setInstallationAssignees] = useState<any>([])
    const [warehouseRecordFile, setWarehouseRecordFile] = useState<FileUpload[]>([] as FileUpload[])
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)
    const [projectData, setProjectData] = useState([])
    const [transportUsers, setTransportUsers] = useState([])
    const [installationUsers, setInstallationUsers] = useState([])
    const [comment, setComment] = useState('')
    const [withoutTransport, setWithoutTransport] = useState<boolean>(false)
    const [withoutInstallation, setWithoutInstallation] = useState<boolean>(false)
    const [label, setLabel] = useState(`${props?.warehouseProject?.projectName} #${props?.warehouseProject?.warehouseRecords?.length + 1}`)

    useEffect(() => {
        if(props.warehouse.warehouseRecord?.id){
            let tempValuesForMultiSelectTransport = []
            props.warehouse.warehouseRecord?.transportUser?.forEach((user) => {
                tempValuesForMultiSelectTransport.push({
                    value: user?.id,
                    label: user?.username
                })
            })
            setLabel(props.warehouse.warehouseRecord?.label)
            setComment(props.warehouse.warehouseRecord?.comment)
            setTransportAssignees(tempValuesForMultiSelectTransport)
            setWithoutTransport(props.warehouse.warehouseRecord?.withoutTransport === 1)
            let tempValuesForMultiSelectInstallation = []
            props.warehouse.warehouseRecord?.installationUsers?.forEach((user) => {
                tempValuesForMultiSelectInstallation.push({
                    value: user?.id,
                    label: user?.username
                })
            })
            setInstallationAssignees(tempValuesForMultiSelectInstallation)
            setWithoutInstallation(props.warehouse.warehouseRecord?.withoutInstallation === 1)
            let quantitiesTempArray = []
            props.warehouse.warehouseRecord?.products?.forEach((product, index) => {
                quantitiesTempArray && quantitiesTempArray.push({
                    quantity: product?.quantityForTransport,
                    quantityAddedForEdit: product?.quantityForTransport,
                    productId: product?.productId,
                })
            })
            setQuantities(quantitiesTempArray)
        }
    }, [props.warehouse.warehouseRecord])

    useEffect(() => {
        setTransportUsers(props.warehouse?.transportAssigneesOptions)
    }, [props.warehouse?.transportAssigneesOptions])

    useEffect(() => {
        setInstallationUsers(props.warehouse?.installationAssigneesOptions)
    }, [props.warehouse?.installationAssigneesOptions])

    function resetFields() {
        setTransportAssignees([])
        setInstallationAssignees([])
        setQuantities([])
        setComment('')
        setWithoutInstallation(false)
        setWithoutTransport(false)
        setLabel(`${props?.warehouseProject?.projectName} #${props?.warehouseProject?.warehouseRecords?.length + 1}`)
        setWarehouseRecordFile([] as FileUpload[])
        props.warehouseActions.setWarehouseRecord({})
    }

    function editWarehouseRecord(currentProjectData, projectIndex, projectId) {
        if (!buttonLoader) {
            setButtonLoader(true)
            if (props.warehouse?.warehouseRecord?.id) {
                let formData = new FormData()
                formData.append('clientId', props?.clientId);
                formData.append('comment', comment);
                formData.append('projectId', projectId);
                formData.append('label', label);
                formData.append('withoutInstallation', withoutInstallation ? '1': '0');
                formData.append('withoutTransport', withoutTransport ? '1': '0');
                transportAssignees?.forEach((assignee) => {
                    formData.append(`transportUsers[]`, assignee?.value);
                })
                installationAssignees?.forEach((assignee) => {
                    formData.append(`installationUsers[]`, assignee?.value);
                })
                quantities.forEach((product) => {
                    if (product?.productId && product?.quantity) {
                        formData.append('productIds[]', product?.productId);
                        formData.append('quantity[]', product?.quantity || '0');
                    }
                })

                if (warehouseRecordFile?.length !== 0) {
                    warehouseRecordFile?.forEach((file) => {
                        formData.append(`invoice[]`, file?.file);
                    })
                }
                props.warehouseActions.editWarehouseRecords(formData, props.warehouse?.warehouseRecord?.id).then(() => {
                    props.warehouseActions.fetchWarehouseClientInformation(props?.clientId).then(()=>{
                        resetFields()
                        setButtonLoader(false)
                    })
                })
            } else {
                createOneWarehouseRecord(currentProjectData, projectId)
            }
        }
    }

    function createOneWarehouseRecord(currentProjectData, projectId) {
        if (!buttonLoader) {
            if (quantities.length === 0) {
                toast.error('Plotesoni sasine e produkteve te deponuara!')
                setButtonLoader(false)
                return
            }
            setButtonLoader(true)
            let formData = new FormData()
            formData.append('clientId', props?.clientId);
            formData.append('projectId', projectId);
            if(comment){
                formData.append('comment', comment);
            }
            formData.append('label', label);
            formData.append('withoutInstallation', withoutInstallation ? '1': '0');
            formData.append('withoutTransport', withoutTransport ? '1': '0');
            if(withoutTransport){
                transportAssignees?.forEach((assignee) => {
                    formData.append(`transportUsers[]`, assignee?.value);
                })
            }
            if(withoutInstallation){
                installationAssignees?.forEach((assignee) => {
                    formData.append(`installationUsers[]`, assignee?.value);
                })
            }
            if (warehouseRecordFile?.length !== 0) {
                warehouseRecordFile?.forEach((file) => {
                    formData.append(`invoice[]`, file?.file);
                })
            }
            quantities.forEach((product) => {
                if (product?.productId && product?.quantity) {
                    formData.append('productIds[]', product?.productId);
                    formData.append('quantity[]', product?.quantity || '0');
                }
            })
            props.warehouseActions.createWarehouseRecords(formData).then(() => {
                props.warehouseActions.fetchWarehouseClientInformation(props?.clientId).then((res)=>{
                    resetFields()
                    setButtonLoader(false)
                })
            })
        }
    }

    function changeProjectWarehouseInformation(name, value, index){
        let tempArray = [
            ...projectData
        ]

        tempArray[index] = {
            ...tempArray[index],
            [name]: value
        }

        setProjectData(tempArray)
    }


    function setProductQuantities(productIndex, productId, quantity, quantityLimit, valid, projectIndex) {
        if (!valid) {
            toast.error('Ky produkt nuk eshte perfunduar!')
            return
        }

        let quantitiesTempArray = [
            ...quantities
        ]
        let tempObject = {
            quantity: quantity,
            productId: productId
        }
        quantitiesTempArray[productIndex] = tempObject
        setQuantities(quantitiesTempArray)
    }

    function changeSelectedProject(){
        props?.history?.push(`/dashboard/warehouse/create/${props?.clientId}/${props?.warehouseProject?.projectId}`)
    }

    return (<div className={`projectCard ${isMobile ? 'w-100' : ''}`}>
        <div className={`projectInformation ${props?.collapsed ? 'noMinHeight' : ''}`}>
            <div className={`projectName d-flex w-100`} onClick={changeSelectedProject}>
                <div className={'w-50 position-relative'}>
                    <p className={'titleField'}>Emri i projektit</p>

                    <p className={'m-0'}>{props?.warehouseProject?.projectName}</p>
                </div>
                <div className={'w-50 position-relative'}>
                    <div
                        className="d-flex print-doc align-items-center print-container warehouse">
                        <ReactToPrint
                            trigger={() => <Tooltip placement="left"
                                                    overlay={<span><TranslatedText
                                                        id="app.clearForm"
                                                        defaultMessage="Printo dokumentin"/></span>}>
                                <button
                                    className="printButton d-flex align-items-center">
                                    <img src={FileIcon} alt=""/>
                                </button>
                            </Tooltip>}
                            content={() => componentRef.current}
                        />
                        <WarehouseRecordPrint
                            products={props?.warehouseProject?.products}
                            quantities={quantities}
                            clientName={props?.firstAndLastName}
                            projectName={props?.warehouseProject?.projectName}
                            ref={componentRef}
                        />
                    </div>
                    <div className={'labelContainer'}>
                        {!props?.collapsed && <Input
                            name="label"
                            label="label"
                            id="label"
                            defaultValue=''
                            inputWrapperClass={"w-100 m-0"}
                            type="text"
                            value={label}
                            inputTextType={EInputTextType.SimpleInput}
                            onChange={(name, value) => setLabel(value)}
                        >
                            <TranslatedText id="app.agreements.appendFinalAgreement"
                                            defaultMessage="Emertimi"/>
                        </Input>}
                    </div>
                </div>
            </div>
            {!props?.collapsed && <>
                <div
                    className={`projectInformationContainer`}>
                    <WarehouseExtraInfo key={props?.projectIndex} resetFields={resetFields}
                                        warehouseRecords={props?.warehouseProject?.warehouseRecords}
                                        projectId={props?.warehouseProject?.projectId} clientId={props?.clientId}
                                        projectIndex={props?.projectIndex}/>
                    <div className={`d-flex productContainer w-100`}>
                        <div className={'w-33'}>
                            <p className={'titleField m-0'}>Emri i produktit</p>
                        </div>
                        <div className={'w-33'}>
                            <p className={'titleField m-0'}>Sasia e mbetur</p>
                        </div>
                        <div className={'w-33'}>
                            <p className={'titleField m-0'}>Njesia e produktit</p>
                        </div>
                        <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                  defaultMessage="Pastro Formen"/></span>}>
                            <img className="create-client-icon" onClick={resetFields}
                                 src={clearFormSvg}
                                 alt="create client"/>
                        </Tooltip>
                    </div>
                    <div
                        className={`productsContainer w-100`}>
                        {props?.warehouseProject?.products && props?.warehouseProject?.products?.map((product, idx) => {
                            return (
                                <div className={'productContainer products d-flex w-100'} key={idx}>
                                    <p className={'w-33'}>{product?.designation?.name}</p>
                                    <div
                                        className={'w-33 d-flex align-items-center justify-content-start'}>
                                        <div className={'quantityInputContainer'}>
                                            <input type="number"
                                                   min={1}
                                                   value={quantities[idx]?.quantity || ''}
                                                   onChange={(e) => setProductQuantities(idx, product?.id, e.target.value, product?.quantityLeft, product?.valid, props?.projectIndex)}/>
                                        </div>
                                        {props?.warehouse?.warehouseRecord?.id ? <p className={'m-0'}>
                                            <span>/</span>{product?.quantityLeft ? ((+product?.quantityLeft) + (quantities[idx]?.quantity ? +quantities[idx]?.quantity : 0))?.toFixed(2) : 0}
                                        </p> : <p className={'m-0'}>
                                            <span>/</span>{product?.quantityLeft ? product?.quantityLeft?.toFixed(2) : 0}
                                        </p>}
                                    </div>
                                    <p className={'w-33'}>{product?.designation?.unit?.name}</p>
                                    {product?.valid ? <div className={'validCheckContainer valid'}>
                                        <Tooltip placement="right"
                                                 overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Prodhimi i perfunduar"/></span>}>
                                            <FontAwesome
                                                name="check"
                                            />
                                        </Tooltip>
                                    </div> : <div className={'validCheckContainer invalid'}>
                                        <Tooltip placement="right"
                                                 overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Prodhimi i perfunduar"/></span>}>
                                            <FontAwesome
                                                name="close"
                                            />
                                        </Tooltip>
                                    </div>}
                                </div>)
                        })}
                    </div>
                </div>
                <div className={'w-100'}>
                    <div
                        className={`editWarehouseRecordContainer align-items-end justify-content-between`}>
                        <Input
                            name="comment"
                            label="label"
                            id="comment"
                            defaultValue=''
                            inputWrapperClass={"w-50 textarea-wrapper"}
                            type="textarea"
                            value={comment ? comment : ''}
                            onChange={(name, value) => setComment(value)}
                        >
                            <TranslatedText id="app.agreements.appendFinalAgreement"
                                            defaultMessage="Komenti"/>
                        </Input>
                        <div className={'file-and-input-container'}>
                            <div className={'w-100 d-flex'}>
                                <div className={'w-75 d-flex align-items-center'}>
                                    <Select
                                        id={`${props?.projectIndex}`}
                                        name="transportAssignees"
                                        options={transportUsers}
                                        closeMenuOnSelect={false}
                                        className={`w-100 assigneeSelect ${withoutTransport ? 'd-none' : ''}`}
                                        classNamePrefix="react-select"
                                        isMulti
                                        value={transportAssignees || ''}
                                        onChange={(value) => setTransportAssignees(value)}
                                        placeholder={translatedText({
                                            id: "client.transportUsers",
                                            defaultMessage: "Perdoruesit e transportit"
                                        })}/>
                                </div>
                                <div className={'w-25'}>
                                    <div className={'warehouseCheckboxesContainer'}>
                                        <label htmlFor={`withoutTransport${props?.projectIndex}`}><TranslatedText
                                            id="withoutTransport"
                                            defaultMessage="Pa transport"/></label>
                                        <input type="checkbox" checked={withoutTransport}
                                               onChange={() => setWithoutTransport(!withoutTransport)}
                                               id={`withoutTransport${props?.projectIndex}`}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'w-100 d-flex mb-2'}>
                                <div className={'w-75 d-flex align-items-center'}>
                                    <Select
                                        id={`${props?.projectIndex}`}
                                        name="installationAssignees"
                                        options={installationUsers}
                                        closeMenuOnSelect={false}
                                        className={`w-100 assigneeSelect ${withoutInstallation ? 'd-none' : ''}`}
                                        classNamePrefix="react-select"
                                        isMulti
                                        value={installationAssignees || ''}
                                        onChange={(value) => setInstallationAssignees(value)}
                                        placeholder={translatedText({
                                            id: "client.installationUsers",
                                            defaultMessage: "Perdoruesit e instalimit"
                                        })}/>
                                </div>
                                <div className={'w-25'}>
                                    <div className={'warehouseCheckboxesContainer'}>
                                        <label htmlFor={`withoutInstallation${props?.projectIndex}`}><TranslatedText
                                            id="withoutInstallation"
                                            defaultMessage="Pa instalim"/></label>
                                        <input type="checkbox" checked={withoutInstallation}
                                               onChange={() => setWithoutInstallation(!withoutInstallation)}
                                               id={`withoutInstallation${props?.projectIndex}`}/>
                                    </div>
                                </div>
                            </div>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Fletdergesa"
                            })} multiple
                                           setFiles={setWarehouseRecordFile}
                                           className={'w-50'}/>
                            {props?.warehouse?.warehouseRecord?.filePath &&
                                <a target={'_blank'} href={props?.warehouse?.warehouseRecord?.filePath}>
                                    <div className={'fileIcon'}>
                                        <Tooltip placement="right"
                                                 overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Fletdergesa"/></span>}>
                                            <FontAwesome name="file"/>
                                        </Tooltip>
                                    </div>
                                </a>}
                            {((!props.permissions.observer) || props.permissions.fullPrivileges) &&
                                <Button
                                    isLoading={buttonLoader}
                                    isDisabled={buttonLoader}
                                    onClick={() => editWarehouseRecord(projectData[props?.projectIndex], props?.projectIndex, props?.projectId)}
                                    className="editButton"
                                    buttonType={EButtonType.PurpleButton}>
                                    {props.warehouse.warehouseClientInformation &&
                                        <TranslatedText id="client.editContact"
                                                        defaultMessage="Ruaj"/>
                                    }
                                </Button>}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    </div>)
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarehouseProjectCard)));