import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import {useHistory, withRouter} from 'react-router';
import {Client, ClientObject} from '../../types/client.type';

import TableBbros from '../../components/Table/TableBbros';
import {
    AGREEMENT_ARCHIVE_RECORD_COLUMNS,
    AGREEMENT_ARCHIVE_RECORD_COLUMNS_MOBILE,
    COLUMNS, COLUMNS_MOBILE,
    INSTALLATION_RECORDS_COLUMNS_ARCHIVE, INSTALLATION_RECORDS_COLUMNS_ARCHIVE_MOBILE,
    MEASURE_ARCHIVE_RECORD_COLUMNS, MEASURE_ARCHIVE_RECORD_COLUMNS_MOBILE,
    OFFER_ARCHIVE_RECORD_COLUMNS,
    OFFER_ARCHIVE_RECORD_COLUMNS_MOBILE,
    OPTIONS,
    TRANSPORT_RECORDS_COLUMNS, TRANSPORT_RECORDS_COLUMNS_MOBILE,
    WAREHOUSE_ARCHIVE_RECORD_COLUMNS, WAREHOUSE_ARCHIVE_RECORD_COLUMNS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import {MEASURE_STATUSES} from "../../constants/constants";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ModalComponent from "../../components/Modal/ModalComponent";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import {toast} from "react-toastify";
import {deleteArchiveData} from "../../actions/generalActions";
import {translatedText} from "../../components/TranslatedText";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';


interface Props {
    intl: any
    history: any
    clientActions: any
    measuresActions: any
    measures: any
    actions: any
    letter: string
    clientsByModuleData: Array<Client>
    loading: boolean
    archiveRecords: any
    permissions: any
    buttonLoader: any
}

const Archive = (props: Props) => {

    const history = useHistory();

    const {moduleName} = useParams<ParameterTypes>();
    const [clientId, setClientId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [tableColumns, setTableColumns] = useState([])
    const [filterStatus, setFilterStatus] = useState<SelectOption>({
        label: 'Te gjitha',
        value: null
    })
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    const filterStatuses = [
        {
            label: 'Te gjitha',
            value: undefined
        },
        ...MEASURE_STATUSES
    ]

    useEffect(()=>{
        let isDesktop = window.innerWidth > 500
        if(moduleName === 'contact'){
            setTableColumns(isDesktop ?  COLUMNS : COLUMNS_MOBILE)
        } else if(moduleName === 'measures'){
           setTableColumns(isDesktop ? MEASURE_ARCHIVE_RECORD_COLUMNS : MEASURE_ARCHIVE_RECORD_COLUMNS_MOBILE)
        }else if(moduleName === 'offers'){
            setTableColumns(isDesktop ?  OFFER_ARCHIVE_RECORD_COLUMNS : OFFER_ARCHIVE_RECORD_COLUMNS_MOBILE)
        }else if(moduleName === 'agreements'){
            setTableColumns(isDesktop ? AGREEMENT_ARCHIVE_RECORD_COLUMNS : AGREEMENT_ARCHIVE_RECORD_COLUMNS_MOBILE)
        }else if(moduleName === 'warehouse'){
            setTableColumns(isDesktop ? WAREHOUSE_ARCHIVE_RECORD_COLUMNS : WAREHOUSE_ARCHIVE_RECORD_COLUMNS_MOBILE)
        }else if(moduleName === 'transport'){
            setTableColumns(isDesktop ? TRANSPORT_RECORDS_COLUMNS_MOBILE : TRANSPORT_RECORDS_COLUMNS)
        }else if(moduleName === 'installation'){
            setTableColumns(isDesktop ? INSTALLATION_RECORDS_COLUMNS_ARCHIVE : INSTALLATION_RECORDS_COLUMNS_ARCHIVE_MOBILE)
        }
    }, [moduleName])

    useEffect(() => {
        getClients()
    }, [filterStatus])

    function getClients(){
        props.actions.getUserPermissionsAndResponsibilities()
        props.actions.getArchiveRecords(`archive/${moduleName}`)
    }

    function editArchiveData (record) {
        if(moduleName === 'contact'){
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.id}`})
        }else if(moduleName === 'warehouse'){
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.client?.id}`})
        }else if(moduleName === 'transport'){
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.code}`})
        }else if(moduleName === 'installation'){
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.project.value}`})
        }else if(moduleName === 'measures'){
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.project.value}/${record?.clientId}`})
        }else {
            props.history.push({pathname: `/dashboard/${moduleName}/create/${record?.id}/${record?.project?.value}`})
        }
    }
    function deleteButtonFunction (client: ClientObject) {
        setClientId(client?.id)
        setDeleteModal(true)
    }

    function deleteArchiveRow () {
        if(moduleName == 'installation') {
            props.actions.deleteArchiveData(`${moduleName}s`, clientId).then(() => {
                props.actions.getArchiveRecords(`archive/${moduleName}`)
            })
        }else if(moduleName == 'service') {
            props.actions.deleteArchiveData(`servicing`, clientId).then(() => {
                props.actions.getArchiveRecords(`archive/${moduleName}`)
            })
        }else if (moduleName == 'contact'){
            props.actions.deleteArchiveData(`clients`, clientId).then(() => {
                props.actions.getArchiveRecords(`archive/${moduleName}`)
            })
        }else{
            props.actions.deleteArchiveData(moduleName, clientId).then(()=>{
                props.actions.getArchiveRecords(`archive/${moduleName}`)
            })
        }
        setDeleteModal(false)
    }

    function filterData(){
        if(startDate && endDate){
            props.actions.getArchiveRecords(`archive/${moduleName}`, startDate, endDate)
            setFiltersOpened(false)
        }else{
            props.actions.getArchiveRecords(`archive/${moduleName}`)
        }
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editArchiveData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:ClientObject) => deleteButtonFunction(rowData)
        }
    ]

    return (
        <div className="wrapper">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                className={`${isDeleteModalOpen ? 'pointerEventsNone': ''} firstColumnThinner`}
                title={translatedText({id: "table.measure", defaultMessage: "Arkiva"})}
                data={props?.archiveRecords}
                columns={tableColumns}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteArchiveRow()} />
            </ModalComponent>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Archive)))
