import React, { useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { Switch,Route } from 'react-router-dom';
import "./Impression.scss";
import { withRouter } from 'react-router';
import { User } from '../../types/user.type';
import AllImpressionRecords from "./AllImpressionRecords";
import CreateImpressionForm from "./CreateImpressionForm";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";

interface Props {
    intl: any;
    isLoading: boolean;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    validationMessageConfig: any;
    letter:string;
    app:any;
    history:any;
    measureUsers: User[];
}

const Impression = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    },[])

    return (
        <div className="transport inside-container">
            <Switch>
                <Route exact path="/dashboard/impression/:statusId?" component={AllImpressionRecords} />
                <Route exact path="/dashboard/impression/create/:clientId/:projectId/:authToken?" component={CreateImpressionForm} />
                <Route exact path="/dashboard/impression/clientSide/:clientId/:projectId/:authToken" component={CreateImpressionForm} />
                <Route path="/dashboard/impression/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )

}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Impression as any)))