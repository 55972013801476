import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { withRouter } from 'react-router';
import L, {LatLngExpression} from "leaflet";
import CreateTransportForm from "./CreateTransportForm";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";


interface Props {
    intl: any;
    actions: any;
    clientActions: any;
    client: any;
    letter:string;
    history:any;
    loading: boolean;
    archiveRecords: any;
}

const ShowAllTransports = (props: Props) => {

    const {transportCode} = useParams<ParameterTypes>();

    return (
        <div className="transportContainer">
            <div className={'transportForm w-100'}>
                <h1 className={'p-3'}>Transport</h1>
                <CreateTransportForm transportCode={transportCode}/>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShowAllTransports as any)))