import {Responsibility} from "../../types/responsibility.type";
import {SelectOption} from "../../types/selectOption.type";

export const filterConditionFunction = (val: any, responsibilityId: number ) => {
    return !(val.id === responsibilityId)
};
export const returnedArrayWithoutDuplicates = (responsibilityId: number, value: any, workingDaysPerResponsibilityArray: Responsibility[] ) => {
    let tempArray = [...workingDaysPerResponsibilityArray]
    let alreadyInArrayObject = tempArray.find(item => item.id === responsibilityId)
    if(alreadyInArrayObject){
        tempArray = tempArray.filter((val) => filterConditionFunction(val , responsibilityId))
    }
    tempArray.push({ id: responsibilityId })
    return tempArray
};

export const checkCorrespondingInput = (correspondingArray, correspondingItem: SelectOption) => {
    let foundCorrespondingItemInsideArray = correspondingArray.find(item => item === JSON.stringify(correspondingItem.value ? correspondingItem.value : correspondingItem.id))
    if (foundCorrespondingItemInsideArray) {
        return true
    } else {
        return false
    }
}
export const setOnlyNewCorrespondingItems = (isChecked: boolean, value: number, correspondingFunction: (value: number[]) => void, correspondingArray: number[]) => {
    if(isChecked){
        correspondingFunction([
            ...correspondingArray,
            value
        ])
    }else{
        correspondingFunction([
            ...correspondingArray.filter((val:number)=> val !== value)
        ])
    }
}

export const checkIfUserIsAuthorized = (permissions, fullPrivileges:boolean, givenPermission:boolean) => {
    if(!(permissions && (fullPrivileges || givenPermission))){
        return false
    }else{
        return true
    }
}

export const returnArrayWithValueLabelForSelect = (selectOptions) => {
    let tempArray = []
    selectOptions.forEach((option)=>{
        tempArray.push({
            value: option?.id,
            label: option?.name
        })
    })
    return tempArray
}

export function convertNumberToHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
        hours = 0 + hours;
    }
    if (minutes < 10) {
        minutes = 0 + minutes;
    }
    if (seconds < 10) {
        seconds = 0 + seconds;
    }
    return hours + 'h:' + minutes + 'm:' + seconds + 's'; // Return is HH : MM : SS
}

export function convertHMSToNumber(value) {
    let secondsInTotal = (+value?.split(':')[0]?.split('h')[0] * 3600) + (+value?.split(':')[1]?.split('m')[0] * 60) + (+value?.split(':')[2]?.split('s')[0])
    return secondsInTotal
    // const sec = parseInt(value, 10); // convert value to number if it's string
    // let hours = Math.floor(sec / 3600); // get hours
    // let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    // let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // // add 0 if value < 10; Example: 2 => 02
    // if (hours < 10) {
    //     hours = 0 + hours;
    // }
    // if (minutes < 10) {
    //     minutes = 0 + minutes;
    // }
    // if (seconds < 10) {
    //     seconds = 0 + seconds;
    // }
    // return hours + 'h : ' + minutes + 'm : ' + seconds + 's'; // Return is HH : MM : SS
}

export function getMonthsBetween(startMonth, endMonth) {
    const startDate = new Date(startMonth);
    const endDate = new Date(endMonth);

    const months = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
        const formattedMonth = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
        months.push(formattedMonth);

        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
}