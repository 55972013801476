import {EInputTextType, Input} from '../../components/Input/Input'
import {FormattedMessage, injectIntl} from 'react-intl'
import {withRouter} from 'react-router';
import {RootState} from '../../reducers';
import {connect} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import {Button, EButtonType} from '../../components/Button';
import {Responsibility} from '../../types/responsibility.type';
import Tooltip from 'rc-tooltip';
import clearFormSvg from '../../assets/icons/archeology.svg'
import {toast} from 'react-toastify';
import * as validationConfigs from "../../utils/validationConfigs";
import TableBbros from '../../components/Table/TableBbros';
import { OPTIONS, RESPONSIBILITY_COLUMNS } from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {isMobile} from "../../constants/constants";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history: any;
    loading: boolean;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    productionActions: any;
    buttonLoader: boolean
}

const Responsibilities = (props: Props) => {

    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [responsibilityName, setResponsibilityName] = useState<string>('')
    const [responsibilityID, setResponsibilityID] = useState<number>(null)
    const [edit, isEdit] = useState<boolean>(false);

    useEffect(() => {
        props.productionActions.fetchResponsibilities()
    }, [])

    function saveResponsibility() {
        if(responsibilityName === ''){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }
        let payload = {
            name: responsibilityName
        }

        props.adminActions.createResponsibility(payload).then(() => {
            props.productionActions.fetchResponsibilities()
        })
        setResponsibilityName('')
    }

    function editResponsibility() {
        if(responsibilityName === ''){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }

        let payload = {
            name: responsibilityName
        }

        props.adminActions.editResponsibility(responsibilityID, payload).then(() => {
            props.productionActions.fetchResponsibilities()
        })
        setResponsibilityName('')
        setResponsibilityID(null);
        isEdit(false);
    }

    const editResponsibilityData = (item: Responsibility) => {
        isEdit(true);
        setResponsibilityName(item.label)
        setResponsibilityID(item.id);
        toast.info(translatedText({
            id: "app.admin.responsibilitySelected",
            defaultMessage: "Procesi u selektua"
        }))
    }

    const deleteResponsibility = () => {
        props.adminActions.deleteResponsibility(responsibilityID).then(() => {
            props.productionActions.fetchResponsibilities()
            setDeleteModal(false)
        })
    }
    const resetFields = () => {
        setResponsibilityName('');
        setResponsibilityID(null);
        isEdit(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:Responsibility) => editResponsibilityData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:Responsibility) => deleteButtonFunction(rowData)
        },
    ]

    function deleteButtonFunction (responsibility) {
        setResponsibilityID(responsibility?.id)
        setDeleteModal(true)
    }

    return (
        <div className={`admin-page ${isMobile ? 'flex-column-reverse':''}`}>
            <div className="create-admin-page-form">
                <Input
                    name="responsibilityName"
                    value={responsibilityName || ''}
                    type="text"
                    inputWrapperClass="col-12"
                    onChange={(name, value) => setResponsibilityName(value)}
                    inputTextType={EInputTextType.SimpleInput}
                >
                    <TranslatedText id="admin.responsibilityName" defaultMessage="Emri i Procesit"/>
                </Input>
                {!edit ?
                    <div>
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            submit={true}
                            className="w-100"
                            buttonType={EButtonType.GreyButton}
                            onClick={saveResponsibility}>
                            <TranslatedText id="admin.createResponsibility" defaultMessage="Krijo nje proces"/>
                        </Button>
                    </div> :
                    <div>
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            submit={true}
                            className="w-100"
                            buttonType={EButtonType.GreyButton}
                            onClick={editResponsibility}>
                            <TranslatedText id="admin.editResponsibility" defaultMessage="Ndrysho nje proces"/>
                        </Button>
                    </div>
                }
            </div>
            <div className={`admin-page-list ${isMobile? 'w-100 mb-5':''}`}>
                <h2 className="m-0 mb-4">
                    <TranslatedText id="client.responsibilityList" defaultMessage="Lista e proceseve te prodhimit"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.responsibility", defaultMessage:"Proceset e prodhimit"})}
                    data={props.production.responsibilities}
                    columns={RESPONSIBILITY_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteResponsibility()} />
            </ModalComponent>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        productionActions: bindActionCreators(productionActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Responsibilities as any)));