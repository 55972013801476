import {FormattedMessage, injectIntl} from 'react-intl'
import {withRouter} from 'react-router';
import {RootState} from '../../reducers';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../actions/generalActions';
import './admin.scss'
import React, {useEffect, useState} from "react";
import * as adminActions from '../../actions/admin'
import TableBbros from '../../components/Table/TableBbros';
import { AUDIT_LOG_COLUMNS, OPTIONS } from '../../components/Table/TableConstants';
import MoreInfo from '@material-ui/icons/More';
import {UnitsObj} from "../../types/units.type";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {translatedText} from "../../components/TranslatedText";
interface Props {
    admin: any,
    adminActions: any
    loading: boolean;
    intl: any;
}

const AuditLog = ( props: Props ) =>{

    const [selectedAudit, setSelectedAudit] = useState<any>()
    const [oldValues, setOldValues] = useState<any>([])
    const [newValues, setNewValues] = useState<any>([])

    useEffect(()=>{
        props.adminActions.getAuditLogs()
    }, [])

    useEffect(()=>{
        if(typeof selectedAudit?.audit?.newValue === "object"){
            let tempArray = []
            let newValues = selectedAudit?.audit?.newValue
            Object.keys(newValues).forEach((newValue)=>{
                tempArray.push({
                    label: newValue,
                    value: newValues[newValue]
                })
            })

            setNewValues(tempArray)
        }

        if(typeof selectedAudit?.audit?.oldValues === "object"){
            let tempArray = []
            let oldValues = selectedAudit?.audit?.oldValues
            Object.keys(oldValues).forEach((oldValue)=>{
                tempArray.push({
                    label: oldValue,
                    value: oldValues[oldValue]
                })
            })

            setOldValues(tempArray)
        }
    },[selectedAudit])

    const actions = [
        {
            icon: MoreInfo,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: UnitsObj) => setSelectedAudit(rowData)
        }
    ]

    return(
        <div className="auditLogWrapper">
            <div className="auditLogContainer allModuleContent">
                <TableBbros
                    title={translatedText({ id:"admin.auditLog", defaultMessage:"Audit Log"})}
                    data={props.admin?.auditLogs}
                    columns={AUDIT_LOG_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
                {selectedAudit && <div className={'auditLogModal-wrapper'}>
                    <div className={'auditLogModal'}>
                        <img onClick={() => setSelectedAudit(undefined)} src={DifferentColoredPlus} className="x-sign"/>
                        <p className={'modalTitle'}>{`Moduli: ${selectedAudit?.audit?.auditableType.split('\\')[2]} - ( ${selectedAudit?.audit?.createdAt} )`}</p>
                        <div className={'auditLogContent'}>
                            <div className={'old-values valueContainers'}>
                                <p className={'cardTitle'}>Vlerat e meparshme:</p>
                                <div className="valueRowContainer">
                                    {oldValues && oldValues?.map((oldValue)=>{
                                        return(
                                            <div className={'valueRow'}>
                                                <p className={'label'}>{oldValue?.label}:</p>
                                                <p className={'value'}>{oldValue?.value}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={'new-values valueContainers'}>
                                <p className={'cardTitle'}>Vlerat aktuale:</p>
                                <div className={'valueRowContainer'}>
                                    {newValues && newValues?.map((newValue)=>{
                                        return(
                                            <div className={'valueRow'}>
                                                <p className={'label'}>{newValue?.label}:</p>
                                                <p className={'value'}>{newValue?.value}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AuditLog as any)));