import { EInputTextType , Input } from '../../components/Input/Input'
import { FormattedMessage , injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as transportActions from '../../actions/transport';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import { Button, EButtonType } from '../../components/Button';
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import TableBbros from "../../components/Table/TableBbros";
import {OPTIONS, EQUIPMENT_COLUMNS} from "../../components/Table/TableConstants";
import {Responsibility} from "../../types/responsibility.type";
import {toast} from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history:any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    buttonLoader: boolean;
}

const Equipments = ( props: Props ) =>{

    const [equipmentName , setEquipmentName] = useState<string>('')
    const [equipmentId, setEquipmentId] = useState<number>(null)

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:Responsibility) => getEquipmentData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:Responsibility) => deleteResponsibility(rowData)
        },
    ]

    useEffect(()=>{
        props.transportActions.fetchEquipments()
    }, [])

    function getEquipmentData (item) {
        setEquipmentName(item?.label)
        setEquipmentId(item?.value);
        toast.info(translatedText({
            id: "equipments.equipmentSelected",
            defaultMessage: "Pajisja u selektua"
        }))
    }

    function deleteResponsibility (item) {
        props.adminActions.deleteEquipment(item?.value).then((res)=>{
            if(res !== false){
                toast.info(translatedText({
                    id: "equipments.equipmentDeleted",
                    defaultMessage: "Pajisja u fshi me sukses!"
                }))
                props.transportActions.fetchEquipments()
            }else{
                toast.error(translatedText({
                    id: "equipments.equipmentDeleted",
                    defaultMessage: "Pajisja perdoret ne modulet tjera!"
                }))
            }
        })
    }

    function saveEquipment(){
        if(equipmentName === ''){
            toast.error(translatedText({
                id: "admin.pleaseFillTheField",
                defaultMessage: "Ju lutem mbushni fushen!"
            }))
            return
        }
        let payload = {
            name: equipmentName
        }

        if(!equipmentId){
            props.adminActions.createEquipment(payload).then(()=>{
                props.transportActions.fetchEquipments()
                toast.info(translatedText({
                    id: "equipments.equipmentDeleted",
                    defaultMessage: "Pajisja u ruajt me sukses!"
                }))
                resetFields()
            })
        }else{
            props.adminActions.editEquipment(payload, equipmentId).then(()=>{
                props.transportActions.fetchEquipments()
                toast.info(translatedText({
                    id: "equipments.equipmentUpdated",
                    defaultMessage: "Pajisja u editua!"
                }))
                resetFields()
            })
        }
    }

    function resetFields(){
        setEquipmentId(null)
        setEquipmentName('')
    }

    return(
        <div className="admin-page equipments-page">
            <div className="create-admin-page-form">
                <Input
                    name="responsibilityName"
                    value={equipmentName || ''}
                    type="text"
                    inputWrapperClass="col-12"
                    onChange={(name, value) => setEquipmentName(value)}
                    inputTextType={EInputTextType.SimpleInput}
                >
                    <TranslatedText id="admin.responsibilityName" defaultMessage="Emri i pajisjes"/>
                </Input>
                <div>
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        className="w-100"
                        buttonType={EButtonType.GreyButton}
                        onClick={saveEquipment}>
                        <TranslatedText id="admin.createResponsibility" defaultMessage="Ruaj pajisjen"/>
                    </Button>
                </div>
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="equipments.equipmentsList" defaultMessage="Lista e pajisjeve"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"equipments.equipments", defaultMessage:"Pajisjet"})}
                    data={props.transport.equipments}
                    columns={EQUIPMENT_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        transport: state.transport,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Equipments as any)));