import React from 'react';
import './measureImages.scss'

interface Props {
    images: any
}

export class MeasureImages extends React.PureComponent<Props> {
    render() {
        const { images } = this.props
        return (
            <>
                <div id="measureImages">
                    {images?.length !== 0 && images?.map((image)=>{
                        return (<img src={image?.fileUrl} alt=""/>)
                    })}
                </div>
            </>
        );
    }
}