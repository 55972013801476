import { EInputTextType , Input } from '../../components/Input/Input'
import { FormattedMessage , injectIntl } from 'react-intl'
import { withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../../actions/generalActions';
import * as transportActions from '../../actions/transport';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import { Button, EButtonType } from '../../components/Button';
import { toast } from 'react-toastify';
import {DEPARTMENT_COLUMNS, OPTIONS} from '../../components/Table/TableConstants';
import TableBbros from '../../components/Table/TableBbros';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {isMobile} from '../../constants/constants'
import {SelectOption} from "../../types/selectOption.type";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    history:any;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    transport: any;
    transportActions: any;
    loading: boolean;
    departments: SelectOption[]
    buttonLoader: boolean
}

const Departments = ( props: Props ) =>{

    const [departmentId , setDepartmentId] = useState<number>(null);
    const [departmentName , setDepartmentName] = useState<string>('');
    const [edit, isEdit] = useState<boolean>(false);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(()=>{
        props.actions.getAllDepartments()
    }, [])

    function saveUnit(){
        if(departmentName){
            let payload = {
                name: departmentName
            }

            props.adminActions.createDepartment(payload).then(()=>{
                props.actions.getAllDepartments()
            })
            setDepartmentName('')
        }else{
            toast.error('Ju lutem mbushni emrin e repartit!')
        }
    }

    function editUnit(){
        if(departmentName){
            let payload = {
                name: departmentName,
            }

            props.adminActions.editDepartment(departmentId,payload).then(()=>{
                props.actions.getAllDepartments()
            })
            setDepartmentName('')
        }else{
            toast.error('Ju lutem mbushni emrin e repartit!')
        }
    }

    const getUnit = (item: SelectOption) => {
        isEdit(true);
        setDepartmentName(item.label)
        setDepartmentId(item.value)
    }

    const deleteDepartment = () => {
        props.adminActions.deleteDepartment(departmentId).then(() => {
            props.actions.getAllDepartments()
            setDeleteModal(false)
        })
    };

    function deleteButtonFunction (department) {
        setDepartmentId(department?.value)
        setDeleteModal(true)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData:SelectOption) => getUnit(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:SelectOption) => deleteButtonFunction(rowData)
        },
    ]

    function resetFields (){
        setDepartmentId(null)
        setDepartmentName('')
        isEdit(false)
    }

    return(
        <div className="admin-page">
            <div className={`create-unit-form ${isMobile? 'w-100':'w-25'}`}>
                <Input
                    name="departmentName"
                    value={departmentName || ''}
                    type="text"
                    inputWrapperClass="col-12"
                    onChange={(name, value) => setDepartmentName(value)}
                    inputTextType={EInputTextType.SimpleInput}>
                    <TranslatedText id="client.departmentName" defaultMessage="Emri i repartit" />
                </Input>
                {edit ?
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        submit={true}
                        className="w-100"
                        buttonType={EButtonType.GreyButton}
                        onClick={editUnit}>
                        <TranslatedText id="client.editUnit" defaultMessage="Ndrysho" />
                    </Button>:
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        submit={true}
                        className="w-100"
                        buttonType={EButtonType.GreyButton}
                        onClick={saveUnit}>
                        <TranslatedText id="client.createUnit" defaultMessage="Krijo" />
                    </Button>}
            </div>
            <div className="admin-page-list">
                <h2 className="m-0 mb-4">
                    <TranslatedText id="admin.listOfColors" defaultMessage="Lista e reparteve"/>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </h2>
                <TableBbros
                    title={translatedText({ id:"table.units", defaultMessage:"Repartet"})}
                    data={props.departments}
                    columns={DEPARTMENT_COLUMNS}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteDepartment()} />
            </ModalComponent>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        transport: state.transport,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Departments as any)));