import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Installation.scss'
import * as productionActions from '../../actions/production'
import * as installationActions from '../../actions/installation'
import TableBbros from '../../components/Table/TableBbros';
import {
    INSTALLATION_RECORDS_COLUMNS,
    INSTALLATION_RECORDS_COLUMNS_MOBILE,
    OPTIONS
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import * as transportActions from "../../actions/transport";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {isMobile, TRANSPORT_STATUSES} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import {SelectOption} from "../../types/selectOption.type";
import FontAwesome from 'react-fontawesome';


interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    transportActions: any
    transport: any
    archiveRecords: any
    installationActions: any
    installation: any,
    buttonLoader: any
}

const AllInstallationRecords = (props: Props) => {
    const {query} = useParams <ParameterTypes>()

    const [installationRecordId, setInstallationRecordId] = useState<number>(null)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    useEffect(() => {
        props.installationActions.getInstallationRecords(query)
    }, [query])

    function getClients(){
        props.installationActions.getInstallationRecords(selectedStatus?.value, startDate, endDate)
    }

    const editInstallationData = (transportRecord) => {
        props.history.push({pathname: `/dashboard/installation/create/${transportRecord?.id}`})
    }

    const handleRemove = (installationRecord) => {
        setDeleteModal(true)
        setInstallationRecordId(installationRecord?.id)
    }

    const deleteInstallation = () => {
        props.transportActions.deleteTransportRecord(installationRecordId).then(()=>{
            props.installationActions.getInstallationRecords()
        })
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event, rowData) => editInstallationData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => handleRemove(rowData)
        }
    ]

    function filterData(){
        if(selectedStatus?.value || (startDate && endDate)){
            getClients()
            setFiltersOpened(false)
        }else{
            if(!selectedStatus?.value){
                getClients()
                setFiltersOpened(false)
                return
            }
            getClients()

        }
    }

    return(
        <div className="wrapper warehouseContainer">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            {!query && <MikaSelect options={TRANSPORT_STATUSES} placeholder={'Statusi'} value={selectedStatus}
                                                      setEntireOptionFunction={setSelectedStatus}/>}
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                title={translatedText({id: "table.warehouse", defaultMessage: "Instalimi"})}
                data={props?.installation?.installationRecords}
                columns={isMobile ? INSTALLATION_RECORDS_COLUMNS_MOBILE : INSTALLATION_RECORDS_COLUMNS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteInstallation()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        transport: state.transport,
        installation: state.installation
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllInstallationRecords as any)));