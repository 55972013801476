import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import {Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';
import './landingPage.scss'
import LoginBg from "../../assets/img/login-bg.png";
import LoginLogo from "../../assets/icons/MIKA_logo-01.svg";
import {withRouter} from 'react-router';
import {toast} from "react-toastify";
import TranslatedText from "../../components/TranslatedText";
import {isMobile} from "../../constants/constants";

interface Props {
    intl: any;
    actions: any;
    setLoading: boolean;
    user: any;
    history: any;
    loading: boolean;
    permissions: any;
    buttonLoader: boolean
}

const LandingPage = (props: Props) => {
    const  [code, setCode] = useState<any>()

    useEffect(()=>{
        if(localStorage?.getItem('mikaBearerToken')){
            props?.history?.push('/login')
        }else{
            if(!isMobile){
                props?.history?.push('/login')
            }
        }
    },[])

    function checkWhereToRedirect(){

        if(code){
            window.location.href = `https://${code}.mikasoft.tech/dashboard`
        }else if(code.toLowerCase() === 'demo' || code.toLowerCase() === 'greenhouse' || code.toLowerCase() === 'nicaswiss'){
            window.location.href = 'https://app.mikasoft.tech/dashboard'
        }else{
            toast.error('Nuk ka kompani me ate kod!')
            return
        }
    }

    return (
        <div className="height111vh landingPage">
            {isMobile && <div className="landingPageWrapper">
                <img className="landingPageLogo" src={LoginLogo} alt="login logo"/>
                <input type="text" placeholder={'Kodi'} value={code} onChange={(e: any) => setCode(e?.target?.value)}/>
                <Button
                    isLoading={props?.buttonLoader}
                    isDisabled={props?.buttonLoader}
                    submit={true}
                    className="w-100"
                    buttonType={EButtonType.GreyButton}
                    onClick={checkWhereToRedirect}>
                    <TranslatedText id="offer.continue" defaultMessage="Vazhdo"/>
                </Button>
            </div>}
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        user: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(LandingPage as any)));
