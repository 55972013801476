// TaskForm.tsx
import React, {useEffect, useState} from 'react';
import TranslatedText, {translatedText} from "../../../components/TranslatedText";
import MikaSelect from "../../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../../types/selectOption.type";
import {toFixedNumber} from "../../../utils/react";
import {EInputTextType, Input} from "../../../components/Input/Input";
import './taskForm.scss'
import {Button, EButtonType} from "../../../components/Button";
import {axiosCaller} from "../../../utils/apiCaller";
import {setButtonLoading, setLoading} from "../../../actions/generalActions";
import {toast} from "react-toastify";
import DifferentColoredPlus from "../../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../../../components/HotkeyButton/HotkeyButton";

interface Props {
    task?: any,
    setShowTaskModal?: any
    setSelectedTask?: any
    getUpdatedData?:any
}

const TaskForm = (props: Props) => {
    const [selectedTaskStatus, setSelectedTaskStatus] = useState<SelectOption>({} as SelectOption)
    const [updatedStartDate, setUpdatedStartDate] = useState<any>()
    const [updatedEndDate, setUpdatedEndDate] = useState<any>()
    const [comment, setComment] = useState<any>()
    const [title, setTitle] = useState<any>()
    const [buttonLoader, setButtonLoader] = useState(false)

    const taskStatuses = [
        {
            label: 'Export',
            value: 1
        },
        {
            label: 'Montim',
            value: 2
        },
        {
            label: 'Furnitor',
            value: 3
        },
        {
            label: 'Pushim',
            value: 4
        }
    ]

    useEffect(()=>{
        console.log(props?.task,'props?.task')
        if(props?.task?.id){
            let newEndDate = new Date(props?.task?.end)
            console.log(newEndDate,'newEndDate')
            newEndDate.setDate(props?.task?.end.getDate() - 1);
            setTitle(props?.task?.title)
            setComment(props?.task?.extendedProps?.comment)
            setUpdatedStartDate(props?.task?.start)
            setUpdatedEndDate(newEndDate)

        }
    },[props?.task])

    function createTask(){
        setButtonLoader(true)
        let newEndDate = updatedEndDate.setDate(updatedEndDate.getDate() + 2);
        let newStartDate = updatedStartDate.setDate(updatedStartDate.getDate() + 1);


        let payload = {
            title: title,
            comment: comment,
            start: new Date(newStartDate),
            end: new Date(newEndDate),
            taskStatusId: selectedTaskStatus?.value
        }

        axiosCaller('POST', `task`, payload).then((response: any) => {
            props?.getUpdatedData()
            closeModal()
            setButtonLoader(false)
        })
    }

    function editTask(id){
        let newEndDate = updatedEndDate.setDate(updatedEndDate.getDate() + 2);
        let newStartDate = updatedStartDate.setDate(updatedStartDate.getDate() + 1);

        setButtonLoader(true)
        let payload = {
            title: title,
            comment: comment,
            start: new Date(newStartDate),
            end: new Date(newEndDate),
            taskStatusId: selectedTaskStatus?.value
        }

        axiosCaller('POST', `task/${id}`, payload).then((response: any) => {
            props?.getUpdatedData()
            closeModal()
            setButtonLoader(false)
        })
    }

    function deleteTask(id){
        axiosCaller('DELETE', `task/${id}`).then((response: any) => {
            props?.getUpdatedData()
            closeModal()
            setButtonLoader(false)
        })
    }

    function closeModal(){
        props?.setSelectedTask({})
        props?.setShowTaskModal(false)
    }

    return (
        <div className={'task-form'}>
            <HotkeyButton
                keyCode="Escape"
                modifiers={[]}
                isActive={false}
                onStateChange={(state) => {
                    if(state){
                        closeModal()
                    }
                }}
            >
                <img className={'x-sign'} src={DifferentColoredPlus} alt="" onClick={() => {
                    closeModal()
                }}/>
            </HotkeyButton>
            <Input
                name="title"
                label="label"
                id="titleInput"
                value={title || ''}
                onChange={(name, value) => setTitle(value)}
                defaultValue=''
                type="text"
                inputTextType={EInputTextType.SimpleInput}
            >
                    <span className="font-weight-bold">
                        <TranslatedText id="title" defaultMessage="Titulli"/>
                    </span>
            </Input>

            <Input
                name="comments"
                label="label"
                id="commentsInput"
                value={comment || ''}
                onChange={(name, value) => setComment(value)}
                defaultValue=''
                type="textarea"
                inputTextType={EInputTextType.SimpleInput}
            >
                    <span className="font-weight-bold">
                        <TranslatedText id="comment" defaultMessage="Komenti"/>
                    </span>
            </Input>

            <MikaSelect
                options={taskStatuses}
                value={selectedTaskStatus || ''}
                setEntireOptionFunction={setSelectedTaskStatus}
                selectWrapperClassName=" w-100 heightFitContent m-b mb-bottom"
                placeholder={'Zgjedhni statusin'}
            >
                <TranslatedText id="taskStatus"
                                defaultMessage="Statusi i taskes"/>
            </MikaSelect>

            <div className={'w-100 d-flex'}>
                <Input
                    name="date"
                    value={ updatedStartDate ? new Date(updatedStartDate) : ''}
                    type="date"
                    onChange={(name, value) => setUpdatedStartDate(value)}
                    inputWrapperClass={`w-50 simple-date-input heightFitContent ${updatedStartDate ? " " : 'does-not-have-data'}`}>
                    <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/>
                </Input>
                <Input
                    name="date"
                    value={ updatedEndDate ? new Date(updatedEndDate) : ''}
                    type="date"
                    onChange={(name, value) => setUpdatedEndDate(value)}
                    inputWrapperClass={`w-50 simple-date-input heightFitContent ${updatedEndDate ? " " : 'does-not-have-data'}`}>
                    <TranslatedText id="client.scanDocument" defaultMessage="Data e Mbarimit *"/>
                </Input>
            </div>


            {props?.task?.id ? <div className={'editViewButtons'}>

                <Button
                    isLoading={buttonLoader}
                    isDisabled={buttonLoader}
                    submit={true}
                    className="actionButton"
                    onClick={() => deleteTask(props?.task?.id)}
                    buttonType={EButtonType.GreyButton}>
                    <TranslatedText id="delete" defaultMessage="Fshij"/>:
                </Button>
                <Button
                    isLoading={buttonLoader}
                    isDisabled={buttonLoader}
                    submit={true}
                    className="actionButton"
                    onClick={() => editTask(props?.task?.id)}
                    buttonType={EButtonType.GreyButton}>
                    <TranslatedText id="edit" defaultMessage="Edito"/>:
                </Button>
            </div> : <Button
                isLoading={buttonLoader}
                isDisabled={buttonLoader}
                submit={true}
                className="actionButton"
                onClick={() => createTask()}
                buttonType={EButtonType.GreyButton}>
                <TranslatedText id="save" defaultMessage="Ruaj"/>:
            </Button>}
        </div>
    );
};

export default TaskForm;