import appReducer, { AppState } from './app'; 
import userReducer, { UserState } from './user'; 
import clientReducer, { ClientState } from './client'; 
import measuresReducer, { MeasuresState } from './measures'; 
import offersReducer, { OffersState } from './offers'; 
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { intlReducer } from 'react-intl-redux';
import localesReducers from './i18n';
import { History } from 'history'
import agreementsReducer ,{ AgreementsState } from './agreements';
import adminReducer ,{ AdminState } from './admin';
import productionReducer ,{ ProductionState } from './production';
import transportReducer ,{ TransportState } from './transport';
import warehouseReducer, {WarehouseState} from "./warehouse";
import installationReducer, {InstallationState} from './installation'
import serviceReducer, {ServiceState} from './service'
import impressionReducer, {ImpressionState} from "./impression";

export interface RootState {
    app: AppState;
    user: UserState;
    client: ClientState;
    measures: MeasuresState;
    offers: OffersState;
    agreements: AgreementsState;
    admin: AdminState;
    production: ProductionState;
    transport: TransportState;
    service: ServiceState;
    installation: InstallationState;
    impression: ImpressionState;
    warehouse: WarehouseState;
    intl: any;
    locales: any;
}

const combinedReducers = (history: History) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    client: clientReducer,
    measures: measuresReducer,
    offers: offersReducer,
    agreements: agreementsReducer,
    admin: adminReducer,
    production: productionReducer,
    transport: transportReducer,
    installation: installationReducer,
    service: serviceReducer,
    warehouse: warehouseReducer,
    intl: intlReducer,
    locales: localesReducers,
    impression: impressionReducer
})

export default combinedReducers