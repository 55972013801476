import { Redirect, Route } from 'react-router-dom';
export const Protected = ({ component: Component, ...rest }) => {  
  var session_token=localStorage.getItem('mikaBearerToken')
  return (
    <Route {...rest} render={props => (
     session_token ? (
      <Component  {...props} />
      ) : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }}
            />
          )
      )} 
    />
  )
};