import './simpleLoader.scss'

const SimpleLoader = () =>{

    return(
        <div className="simpleLoader">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.925" height="26.536" viewBox="0 0 26.925 26.536">
                <g id="Group_196" data-name="Group 196" transform="translate(3.963 4.305)">
                    <path id="Vector" d="M5.7,2.849A2.849,2.849,0,1,1,2.849,0,2.849,2.849,0,0,1,5.7,2.849Z" transform="translate(6.647 6.114)" fill="none" stroke="#252434" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1"/>
                    <path id="Vector-2" data-name="Vector" d="M0,9.8V8.127a1.809,1.809,0,0,1,1.8-1.8c1.719,0,2.421-1.215,1.557-2.706a1.8,1.8,0,0,1,.665-2.459L5.669.217a1.585,1.585,0,0,1,2.165.57l.1.18c.855,1.491,2.26,1.491,3.124,0l.1-.18a1.585,1.585,0,0,1,2.165-.57l1.643.94a1.8,1.8,0,0,1,.665,2.459c-.864,1.491-.161,2.706,1.557,2.706a1.809,1.809,0,0,1,1.8,1.8V9.8a1.809,1.809,0,0,1-1.8,1.8c-1.719,0-2.421,1.215-1.557,2.706a1.8,1.8,0,0,1-.665,2.459l-1.643.94a1.585,1.585,0,0,1-2.165-.57l-.1-.18c-.855-1.491-2.26-1.491-3.124,0l-.1.18a1.585,1.585,0,0,1-2.165.57l-1.643-.94a1.8,1.8,0,0,1-.665-2.459C4.225,12.818,3.523,11.6,1.8,11.6A1.809,1.809,0,0,1,0,9.8Z" transform="translate(5.754 -3.549) rotate(30)" fill="none" stroke="#252434" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1"/>
                </g>
            </svg>
        </div>
    )
}

export default SimpleLoader;