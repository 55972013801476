import * as React from 'react';
import { InputWrapper } from './InputWrapper';
import { InputLabel } from './Label';
import { EInputType, InputContent } from './Content';
import { renderDefinedTrue } from '../../utils/react';
import { ValidationMessage, ValidationMessageProps } from '../ValidationMessage';
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import { injectIntl } from 'react-intl';

export enum EInputTextType {
    Standard = 'Standard',
    Material = 'Material',
    SimpleInput = 'SimpleInput'
}

export interface IInputConfig {
    // @TODO fix it for radio buttons!
    checkboxLabel?: any;
    checked?: boolean;
    clearable?: boolean;
    customComponent?: JSX.Element;
    defaultChecked?: boolean;
    defaultValidationMessageConfig?: ValidationMessageProps;
    defaultValue?: any;
    disabled?: boolean;
    dropdownLabel?: string;
    forcedValue?: any;
    id?: string;
    inputTextType?: EInputTextType;
    inputType?: EInputType;
    inputWrapperClass?: string;
    inputWrapperStyle?: React.CSSProperties;
    isFocused?: boolean;
    isLoading?: boolean;
    label?: string | JSX.Element;
    max?: number;
    maxLength?: number;
    min?: number;
    name?: string;
    index?: number;
    onBlur?: any;
    onChange?: (name: any, value?: any, file?: any , index?: number) => void;
    customChangeFunction?: boolean;
    onChangeFunction?: (index?: number , idx?: number , value?: number) => void;
    onDropdownOpen?: (isOpen: boolean) => void;
    onSelected?: any;
    placeholder?: string;
    radioOptions?: IInputConfig[];
    readOnly?: boolean;
    selectOptions?: IInputConfig[];
    step?: string;
    style?: React.CSSProperties;
    type?: string;
    validationMessageConfig?: ValidationMessageProps;
    value?: any;
    filtering?: boolean;
    cols?: number;
    rows?: number;
    isOpen?: boolean;
    onFocus?: any;
    sort?: boolean;
    showValidations?: boolean;
    children?: any;
    minDate?: Date;
    maxDate?: Date;
    intl:any;
    hasFormikValidation?: boolean;
    valueNeedeed?: boolean;
    multiple?: boolean;
    errors?: any; // TODO: L Type this
    touched?: any; // TODO: L Type this
    isContact?: boolean;
    accept?: string;
}

class Input extends React.Component<IInputConfig, {}> {

    state = {
        isValidationVisible: false
    }

    onBlur = (e:any) => {
        this.setState({isValidationVisible: true});
        this.props.onBlur && this.props.onBlur(e);
    }

    render(): JSX.Element {
        const {
            validationMessageConfig,
            inputWrapperStyle,
            children,
            style,
            inputWrapperClass,
            sort,
            showValidations,
            multiple
        } = this.props;
        const { isValidationVisible } = this.state;

        return <>
            <InputWrapper inputWrapperStyle={inputWrapperStyle} inputWrapperClass={inputWrapperClass || ""}>
                {renderDefinedTrue(children, () => (
                    <InputLabel style={style || {}}>{children}</InputLabel>
                ))}
                {renderDefinedTrue(validationMessageConfig, () => (
                    !!(showValidations || isValidationVisible) &&
                        <ValidationMessage {...validationMessageConfig} />
                ))}
                <InputContent inputConfig={{...this.props, onBlur: this.onBlur}} />
            </InputWrapper>
            {/*{this.props?.errors && this.props?.errors[this.props.name] && this.props.touched && this.props?.touched[this.props.name]*/}
            {/*    && (<span>Test</span>)*/}
            {/*}*/}
            </>
            ;
    }
}


function mapStateToProps(state: RootState) {
    return {
        // showValidations: state.app.showValidations
    }
}

const component = connect(mapStateToProps)(injectIntl(Input));

export { component as Input };