import * as constants from '../constants/constants'
import en from '../constants/langs/en.json'
import de from '../constants/langs/de.json'

const initialState = () => {
    return {
        en: en,
        de: de
    }
}

const localesReducers = (state: any = initialState, action: any) => {
    switch (action.type) {
        case constants.UPDATE_LOCALES: {
            return {
                ...state,
                ...action.payload
            }
        }
    }
    return state;
}

export default localesReducers;