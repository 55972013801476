import * as constants from "../constants/constants";

export interface WarehouseState {
    transportAssigneesOptions: any[],
    warehouseClientInformation: any,
    warehouseProducts: any,
    warehouseExtraInformation: any,
    warehouseRecord: any
    warehouseLeftOvers: any
    warehouseInnerStock: any
    warehouseClients: any
}

const initialState = () => {
    return {
        transportAssigneesOptions: [],
        warehouseClientInformation: null,
        warehouseProducts: [],
        warehouseExtraInformation: [],
        warehouseRecord: {},
        warehouseLeftOvers: {},
        warehouseInnerStock: [],
        warehouseClients: []
    }
}

const warehouse = (state: WarehouseState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_TRANSPORT_ASSIGNEES_OPTIONS: {
            return {
                ...state,
                transportAssigneesOptions: action.payload.data[0].user
            };
        }
        case constants?.FETCH_INSTALLATION_ASSIGNEES_OPTIONS: {
            return {
                ...state,
                installationAssigneesOptions: action?.payload.data[0].user
            }
        }
        case constants.FETCH_WAREHOUSE_CLIENT_INFORMATION: {
            return {
                ...state,
                warehouseClientInformation: action.payload.data
            }
        }
        case constants.GET_WAREHOUSE_PRODUCTS: {
            return {
                ...state,
                warehouseProducts: action.payload
            }
        }
        case constants.GET_WAREHOUSE_INFORMATION: {
            return {
                ...state,
                warehouseExtraInformation: action.payload
            }
        }
        case constants.GET_WAREHOUSE_RECORD: {
            return {
                ...state,
                warehouseRecord: action.payload
            }
        }
        case constants.GET_WAREHOUSE_LEFT_OVERS: {
            return {
                ...state,
                warehouseLeftOvers: action.payload
            }
        }
        case constants.GET_WAREHOUSE_INNER_STOCK: {
            return {
                ...state,
                warehouseInnerStock: action?.payload
            }
        }
        case constants.GET_WAREHOUSE_CLIENTS: {
            return {
                ...state,
                warehouseClients: action.payload
            }
        }
    }
    return state;
}

export default warehouse;