import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as offerActions from '../../actions/offers';
import * as clientActions from '../../actions/client';
import { withRouter } from 'react-router';
import './Offers.scss'
import { Client } from '../../types/client.type';
import { Switch,Route } from 'react-router-dom';
import ShowAllOffers from './ShowAllOffers';
import CreateOfferForm from './CreateOfferForm';
import React, {useEffect} from "react";
import AllOffers from "../Offers/AllOffers";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
import CanceledProducts from "./CanceledProducts";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    client: any;
    clientActions: any;
    offerActions: any;
    actions: any,
    offers: any,
    measures: any,
    letter: string,
    clientsByModuleData: Array<Client>
}
    

const Offers = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    }, [])

    return (
        <div className="offers">
            <Switch>
                <Route exact path="/dashboard/offers/:statusId?" component={ShowAllOffers} />
                <Route exact path="/dashboard/offers/create/:offerId?/:projectId?" component={CreateOfferForm}/>
                <Route exact path="/dashboard/offers/all-offers/:clientId?" component={AllOffers}/>
                <Route exact path="/dashboard/offers/canceled/products" component={CanceledProducts}/>
                <Route path="/dashboard/offers/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Offers as any)));