import {EInputTextType, Input} from '../../components/Input/Input'
import {FormattedMessage, injectIntl} from 'react-intl'
import {withRouter} from 'react-router';
import {RootState} from '../../reducers';
import {connect} from 'react-redux';
import {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import * as adminActions from '../../actions/admin';
import './admin.scss'
import {Button, EButtonType} from '../../components/Button';
import {isMobile} from "../../constants/constants";
import {getProductionSettings, saveProductionSettings} from "../../actions/admin";
import TranslatedText from "../../components/TranslatedText";

interface Props {
    history: any;
    loading: boolean;
    actions: any;
    production: any;
    intl: any;
    admin: any
    adminActions: any;
    productionActions: any;
    buttonLoader: boolean
}

const ProductionSettings = (props: Props) => {

    const [showProductionDropdownDetails, setShowProductionDropdownDetails] = useState(0)
    const [ignoreMaterialPath, setIgnoreMaterialPath] = useState(0)

    useEffect(()=>{
        props?.adminActions?.getProductionSettings()
    },[])


    useEffect(()=>{
        if(props?.admin?.productionSettings){
            setShowProductionDropdownDetails(props?.admin?.productionSettings?.showProductionDropdownDetails)
        }
    },[props?.admin?.productionSettings])

    function saveProductionSettings(){
        let payload = {
            showProductionDropdownDetails: showProductionDropdownDetails
        }
        props?.adminActions?.saveProductionSettings(payload)
    }



    return (
        <div className="admin-page productionSettingsPage">
            <p className={'pageTitle'}>Preferencat e prodhimin</p>
            <div className={'productionSettingsInputContainer'}>
                <Input
                    value={showProductionDropdownDetails}
                    type="checkbox"
                    inputWrapperClass="checkboxWrapper"
                    valueNeedeed
                    checked={showProductionDropdownDetails === 1}
                    onChange={(currentTarget) => setShowProductionDropdownDetails(currentTarget.checked ? 1 : 0)}
                >
                    <p className="m-0">{'Shiko detajet e prodhimit'}</p>
                </Input>
                <Input
                    value={ignoreMaterialPath}
                    type="checkbox"
                    inputWrapperClass="checkboxWrapper"
                    valueNeedeed
                    checked={ignoreMaterialPath === 1}
                    onChange={(currentTarget) => setIgnoreMaterialPath(currentTarget.checked ? 1 : 0)}
                >
                    <p className="m-0">{'Injoro rradhen per materiale'}</p>
                </Input>
            </div>
            <div className={'w-100'}>
                <Button
                    isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                    onClick={saveProductionSettings}
                    className={isMobile ? 'w-100': "w-50"}
                    buttonType={EButtonType.GreyButton}>
                    <TranslatedText id="admin.createMaterial" defaultMessage="Ruaj"/>
                </Button>
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        productionActions: bindActionCreators(productionActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ProductionSettings as any)));