import * as constants from "../constants/constants";

export interface UserState {
    email: string,
    username: string,
    password: string,
    isLogin: boolean,
    rememberMe: boolean,
    permissions: any[]
}

const initialState = () => {
    return {
        email: "",
        username: "",
        password: "",
        isLogin: false,
        isAuthenticated: false,
        rememberMe: false,
        permissions: []
    }
}

const user = (state: UserState = initialState(), action: any) => {
    switch (action.type) {
        case constants.SET_EMAIL: {
            return {
                ...state,
                email: action.payload,
            }
        }
        case constants.SET_USERNAME: {
            return {
                ...state,
                username: action.payload,
            }
        }
        case constants.SET_PASSWORD: {
            return {
                ...state,
                password: action.payload,
            }
        }
        case constants.SET_REMEMBER_ME: {
            return {
                ...state,
                rememberMe: action.payload,
            }
        }
        case constants.LOGIN: {
            return {
                ...state,
                username: action.payload.user.username,
                password: action.payload.user.password,
                permissions: action.payload.permissions
            }
        }
        case constants.IS_LOGIN: {
            return {
                ...state,
                isLogin: action.payload
            }
        }
        case constants.IS_AUTHENTICATED: {
            return {
                ...state,
                isAuthenticated: action.payload
            }
        }
        case constants.RESET_PASSWORD: {
            return {
                ...state,
                email: action.payload
            }
        }
        case constants.LOG_OUT: {
            localStorage.removeItem("mikaBearerToken");
        }
    }
    return state;
}

export default user;