import React from 'react';
import albanian from '../constants/langs/al.json'
import english from '../constants/langs/en.json'

export function translatedText({id, defaultMessage}){
    return `${JSON.parse(localStorage?.getItem('mikaSelectedLanguage')) ? JSON.parse(localStorage?.getItem('mikaSelectedLanguage'))?.value === 1 ? (albanian[id] || defaultMessage) : (english[id] ||  defaultMessage) : defaultMessage}`
}

const TranslatedText = ({id, defaultMessage}) => {
    return <>{JSON.parse(localStorage?.getItem('mikaSelectedLanguage')) ? JSON.parse(localStorage?.getItem('mikaSelectedLanguage'))?.value === 1 ? (albanian[id] || defaultMessage) : (english[id] || defaultMessage) : defaultMessage}</>
}

export default TranslatedText