import React, {useState, useEffect} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import {withRouter} from 'react-router';
import FontAwesome from 'react-fontawesome'
import {EInputTextType, Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';
import {axiosCaller} from '../../utils/apiCaller';
import {toast} from 'react-toastify';
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {FileUpload} from "../../types/fileUpload.type";
import {BLUEPRINTING, isMobile, pauseReasons, pauseReasonsEnum, POSTPROCESING} from "../../constants/constants";
import PlusIconLink from "../../assets/icons/plus.svg";
import TimeTracker from "../../components/TimeTracker/TimeTracker";
import arrowDown from "../../assets/icons/arrow-down.svg"
import {setButtonLoading} from "../../actions/generalActions";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import SimpleLoader from "../../components/Spinner/SimpleLoader";
import {SelectOption} from "../../types/selectOption.type";
import ReplayIcon from "@material-ui/icons/Replay";
import ModalComponent from '../../components/Modal/ModalComponent';
import DeleteModalComponent from '../../components/Modal/DeleteModal';
import moment from "moment";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import io from 'socket.io-client';
import Pusher from "pusher-js";
import {control} from "leaflet";
import zoom = control.zoom;
import {ProductionFilters} from "../../types/productionFilters.type";
import {stat} from "fs";
import Sleppyeppy from '../../assets/images/dreaming.png'


interface Props {
    history: any;
    productionActions: any;
    production: any;
    userResponsibilities: any;
    adminDepartments: any;
    intl: any;
    permissions: any
    user: any
    buttonLoader: boolean,
    departments: SelectOption[],
    actions: any
    doneStatus: number
}

const ProductStatuses = (props: Props) => {
    const statuses = {
        1: 'completed',
        2: 'toDo',
        3: 'late',
        4: 'inProcess',
        5: 'paused',
        6: 'paused',
        7: 'otherWork',
        8: 'paused',
        9: 'nonActive'
    }

    const mikaSelectStatusOptions = [
        {
            value: 1,
            label: translatedText({id:'completed', defaultMessage: 'Perfunduar'})
        },
        {
            value: 2,
            label: translatedText({id:'toDo', defaultMessage: 'Per tu realizuar'})
        },
        {
            value: 9,
            label: translatedText({id:'nonActive', defaultMessage: 'Ne proces jo aktiv'})
        },
        {
            value: 3,
            label: translatedText({id:'late', defaultMessage: 'Me vonese'})
        },
        {
            value: 4,
            label: translatedText({id:'inProcess', defaultMessage: 'Ne proces'})
        },
        {
            value: 5,
            label: translatedText({id:'paused', defaultMessage: 'Pauzuar'})
        },
        {
            value: 6,
            label: translatedText({id:'endOfShift', defaultMessage: 'Perfundimi i orarit'})
        },
        {
            value: 7,
            label: translatedText({id:'otherResponsibility', defaultMessage: 'Kalim ne punen tjeter'})
        },
        {
            value: 8,
            label: translatedText({id:'other', defaultMessage: 'Arsye tjeter'})
        }
    ]

    const sortOptions = [
        {
            label: 'Sipas dates se fillimit',
            value: 'start'
        },
        {
            label: 'Sipas dates se mbarimit',
            value: 'end'
        }
    ]

    const filterBy = [
        {
            label: 'Sipas dates se fillimit',
            value: '1'
        },
        {
            label: 'Sipas dates se mbarimit',
            value: '2'
        }
    ]


    let socket = null;

    const currentUserId = localStorage.getItem('mikaUserId')
    const [seconds, setSeconds] = useState(0)
    const [paused, setPaused] = useState(true)
    const [productsWithStatuses, setProductsWithStatuses] = useState([])
    const [selectedResponsibilityId, setSelectedResponsibilityId] = useState<number>()
    const [selectedResponsibilityLabel, setSelectedResponsibilityLabel] = useState<string>()
    const [selectedResponsibilityUser, setSelectedResponsibilityUser] = useState<string>()
    const [selectedProductId, setSelectedProductId] = useState<number>()
    const [selectedProductStatusRow, setSelectedProductStatusRow] = useState<any>()
    const [selectedStatusId, setSelectedStatusId] = useState<number>()
    const [alreadySetStatusId, setAlreadySetStatusId] = useState<number>()
    const [selectedProductClientId, setSelectedProductClientId] = useState<number>()
    const [searchedClient, setSearchedClient] = useState<string>('')
    const [comment, setComment] = useState<string>()
    const [otherComment, setOtherComment] = useState<string>()
    const [existingComment, setExistingComment] = useState<string>()
    const [materialIds, setMaterialIds] = useState<number[]>([])
    const [calendarComment, setCalendarComment] = useState('')
    const [projectUploads, setProjectUploads] = useState<FileUpload[]>([] as FileUpload[])
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [selectedProductIndex, setSelectedProductIndex] = useState(null)
    const [selectedMaterial, setSelectedMaterial] = useState(null)
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
    const [responsibilityTimeTrackerInfo, setResponsibilityTimeTrackerInfo] = useState<any>()
    const [isTrackerExtraInfoModalOpen, setIsTrackerExtraInfoModalOpen] = useState(false)
    const [productionFilterOpened, setProductionFilterOpened] = useState(false)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [materialsOfProduct, setMaterialsOfProduct] = useState([])
    const [selectedAdminWarning, setSelectedAdminWarning] = useState('')
    const [buttonLoader, setButtonLoader] = useState(false)
    const [passiveProductModal, setPassiveProductModal] = useState(false)
    const [productStartDate, setProductStartDate] = useState<any>('')
    const [productEndDate, setProductEndDate] = useState<any>('')
    const [tempMaterialStatus, setTempMaterialStatus] = useState<any>()
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [waitingDuration, setWaitingDuration] = useState<any>(0);
    const [showSeconds, setShowSeconds] = useState<any>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedPauseReason, setSelectedPauseReason] = useState<SelectOption>({} as SelectOption)
    const [zoomLvl, setZoomLvl] = useState(100)
    const [productionFilters, setProductionFilters] = useState<ProductionFilters>({})
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [selectedAssignee, setSelectedAssignee] = useState<SelectOption>({} as SelectOption)
    const [timeEstimation, setTimeEstimation] = useState('')

    useEffect(() => {
        props.productionActions.fetchResponsibilities()
        props.actions.getAllDepartments()
        return () => {
            localStorage.removeItem('mikaProductionFilters')
            localStorage.removeItem('mikaProductionEndDate')
            localStorage.removeItem('mikaProductionStartDate')
            localStorage.removeItem('mikaSelectedDepartmentId')
        };
    }, [])

    useEffect(() => {
        if(props?.doneStatus == 1){
            // Get the current date
            let currentDate = new Date();

            // Calculate the start date six months back
            let startDate = new Date();
            startDate.setMonth(currentDate.getMonth() - 1);

            // End date is the current date
            let endDate = currentDate;

            // Format the dates as strings (optional)
            let formattedStartDate = startDate.toISOString().split('T')[0];
            let formattedEndDate = endDate.toISOString().split('T')[0];

            setStartDate(formattedStartDate)
            setEndDate(formattedEndDate)
            localStorage?.setItem('mikaProductionStartDate',formattedStartDate)
            localStorage?.setItem('mikaProductionEndDate',formattedEndDate)
            // setTimeout(()=>{
            //     callProductStatuses()
            // },0)
        }else{
            let interval = setInterval(function () {
                if (window.location.href.includes('dashboard/production/create')) {
                    console.log('1')
                    callProductStatuses('1')
                } else {
                    localStorage.removeItem('mikaProductionFilters')
                    localStorage.removeItem('mikaProductionEndDate')
                    localStorage.removeItem('mikaProductionStartDate')
                    localStorage.removeItem('mikaSelectedDepartmentId')
                    clearInterval(interval)
                }
            }, 7000)
        }
    }, [props?.doneStatus]);

    useEffect(() => {
        if(props?.permissions?.fullPrivileges){
            setIsAdmin(true)
        }
        props?.adminDepartments?.forEach((adminDepartment)=>{
            if(adminDepartment?.isAdmin){
                setSelectedDepartmentId(adminDepartment?.departmentId)
            }
        })
    }, [props?.adminDepartments]);

    function callProductStatuses(where) {
        props.productionActions.fetchProductStatuses(localStorage?.getItem('mikaSelectedDepartmentId'), localStorage?.getItem('mikaProductionStartDate'), localStorage?.getItem('mikaProductionEndDate'), props?.doneStatus, localStorage?.getItem('mikaSearchClient'), JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedResponsibility?.id, JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedStatus?.value,JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedSort?.value, JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedFilterDatesBy?.value).then(()=>{
        })
        if (localStorage?.getItem('mikaSelectedProductId') || selectedProductId) {
            props?.productionActions?.getMaterialsOfProduct(selectedProductId ? selectedProductId : localStorage?.getItem('mikaSelectedProductId'))
        }
    }

    useEffect(() => {
        if (productsWithStatuses?.length !== 0 && props?.doneStatus != 1) {
            if (!selectedDepartmentId) {
                productsWithStatuses?.forEach((product, index) => {
                    removeUnnecessaryRows(index)
                })
            }
        }
    }, [productsWithStatuses])

    useEffect(() => {
        if (projectUploads.length !== 0) {
            let payload = new FormData()

            projectUploads?.forEach((upload) => {
                payload.append('blueprint[]', upload.file)
                payload.append('projectId', JSON.stringify(selectedProjectId))
                payload.append('productId', JSON.stringify(selectedProductId))
            })

            props.productionActions.createBlueprint(payload).then(() => {
                props.productionActions?.getBlueprintFiles(selectedProductId)
            })
        }
    }, [projectUploads])

    useEffect(() => {
        if (seconds) {
            if ((!paused) && seconds && selectedResponsibilityId) {
                setTimeout(() => {
                    if ((!paused) && seconds && selectedResponsibilityId) {
                        let tempSeconds = seconds
                        if (!paused) {
                            setSeconds(tempSeconds + 1)
                        }
                    } else {
                        setSeconds(0)
                    }
                }, 1000)
            } else {
                if (selectedStatusId === 4) {
                    setSeconds(0)
                }
            }
        }
    }, [seconds, paused])


    // useEffect(() => {
    //     // Pusher.logToConsole = true
    //     //
    //     // const pusher = new Pusher('4c29d035581d0ae3fae3', {
    //     //     cluster: 'eu'
    //     // })
    //     //
    //     // const channel = pusher.subscribe('chat')
    //     // channel.bind('message', function (data){
    //     // })
    //
    //     socket = io('http://127.0.0.1:6379');
    //
    //     socket?.on('connect', () => {
    //         // setTimeout(()=>{
    //         //         socket.emit('message', 'leka');
    //         //         io('http://127.0.0.1:6379').emit('message', {payload: 'leka', token: localStorage.getItem('mikaBearerToken')});
    //         //     },3000)
    //     });
    //
    //     socket?.on('response-event', (data) => {
    //         replaceUpdatedData(data)
    //     });
    //
    //     return () => {
    //         socket.disconnect();
    //     };
    //
    // }, []);
    useEffect(() => {
        if (!searchedClient) {
            localStorage.removeItem('mikaSearchClient')
        }
    }, [searchedClient])

    function replaceUpdatedData(responsibilityStatus) {
        //setProductStatuses
    }

    function convertDateForIos(date) {
        let arr = date?.split(/[- :]/);
        let convertedDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        return convertedDate;
    }

    useEffect(() => {
        let tempProductsStatusArray = []
        let statusRowData = {
            productId: null,
            clientName: '',
            productName: '',
            statuses: [],
            clientId: '',
            start: '',
            end: '',
            projectName: '',
            projectId: null,
            projectFiles: [],
            measureDone: false,
            responsibilities: [],
            quantity: 0,
            unit: '',
            measureUnit: '',
            allMaterialStatuses: [],
            calendarComment: '',
            productCode: '',
            offerBy: ''
        }

        if (props.production.productStatuses?.length !== 0) {
            props.production.productStatuses?.forEach((productStatus) => {
                let tempResponsibilities = []
                productStatus.statuses?.forEach((status) => {
                    tempResponsibilities?.push({
                        id: status?.responsibility?.id,
                        label: status?.responsibility?.label
                    })
                })
                statusRowData = {
                    ...statusRowData,
                    clientId: productStatus?.client?.id,
                    productId: productStatus?.id,
                    clientName: productStatus?.client?.name,
                    productName: productStatus?.designation?.name,
                    statuses: productStatus?.statuses,
                    responsibilities: tempResponsibilities,
                    start: productStatus?.start,
                    calendarComment: productStatus?.calendarComment,
                    end: productStatus?.end,
                    projectName: productStatus?.project?.name,
                    projectId: productStatus?.project?.id,
                    projectFiles: productStatus?.project?.files,
                    quantity: productStatus?.quantity,
                    unit: productStatus?.designation?.unit?.name,
                    measureUnit: productStatus?.designation?.unit?.name,
                    measureDone: productStatus?.measureStatus === 2,
                    productCode: productStatus?.type,
                    offerBy: productStatus?.offerCreated?.userName,
                    allMaterialStatuses: productStatus?.materialStatuses || []
                }
                if(productStatus?.statuses?.length !== 0){
                    tempProductsStatusArray.push(statusRowData)
                }
            })
            // tempProductsStatusArray.sort(function compare(a, b) {
            //     let firstMilliseconds = new Date(a[productionFilters?.selectedSort?.value]).getTime();
            //     let secondMilliseconds = new Date(b[productionFilters?.selectedSort?.value]).getTime();
            //     return firstMilliseconds - secondMilliseconds;
            // });
        }
        setProductsWithStatuses(tempProductsStatusArray)
    }, [props.production.productStatuses])


    useEffect(() => {
        let tempArray = []
        props.production?.productMaterials?.forEach((material) => {
            if (material?.isDone) {
                tempArray.push(JSON.stringify(material?.value))
            }
        })
        setMaterialIds(tempArray)
    }, [props.production.productMaterials])

    useEffect(() => {
        if (props?.production?.productMaterials?.length !== 0) {
            if (materialIds.length === props.production?.productMaterials?.length) {
                setSelectedStatusId(1)
            }
        }
    }, [materialIds])

    useEffect(() => {
        setLoading(true)
        localStorage.setItem('mikaSelectedDepartmentId', selectedDepartmentId)
        let tempAdminDepartment = props?.adminDepartments?.find((e)=> selectedDepartmentId === e?.departmentId)
        if(props?.permissions?.fullPrivileges){
            setIsAdmin(true)
        }else{
            if(tempAdminDepartment?.isAdmin){
                setIsAdmin(true)
            }else{
                setIsAdmin(false)
            }
        }
        props.productionActions.fetchProductStatuses(selectedDepartmentId, null, null, props?.doneStatus).then(() => {
            setSearchedClient('')
            setLoading(false)
        })
    }, [selectedDepartmentId])

    useEffect(() => {
        if(productionFilters?.selectedSort || productionFilters?.selectedResponsibility || productionFilters?.selectedStatus || (startDate && endDate)){
            if(startDate && endDate){
                let startDateTime = new Date(startDate)?.getTime()
                let endDateTime = new Date(endDate)?.getTime()

                if (startDateTime > endDateTime) {
                    localStorage.removeItem('mikaProductionStartDate')
                    localStorage.removeItem('mikaProductionEndDate')
                    toast.error('Data e fillimit duhet te jete para dates se mbarimit!')
                }else{
                    localStorage.setItem('mikaProductionEndDate', endDate)
                    localStorage.setItem('mikaProductionStartDate', startDate)
                    callProductStatuses('2')
                }
            }else{
                localStorage.removeItem('mikaProductionStartDate')
                localStorage.removeItem('mikaProductionEndDate')
                callProductStatuses('3')
            }
        }else{
            localStorage.removeItem('mikaProductionStartDate')
            localStorage.removeItem('mikaProductionEndDate')

        }
    }, [startDate, endDate, productionFilters])

    async function checkIfUserCanUpdateThisStatus(givenResponsibilityId, givenProductId) {
        let payload = {
            responsibilityId: givenResponsibilityId,
            productId: givenProductId
        }

        const userAvailability = await axiosCaller('POST', `update-status/user-availability`, payload)
        return userAvailability?.data
    }

    function filterProducts() {
        props.productionActions.fetchProductStatuses(localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, props?.doneStatus, searchedClient)
    }

    function statusBoxGenerator(responsibility, productStatuses, productId, clientId, productIndex, projectId, productResponsibilities, materialStatuses, givenAdminWarning, responsibilityStatusIndex) {
        // let isAnyoneWorkingHere = true
        let isAnyoneWorkingHere = false

        // if(materialStatuses?.length != 0){
            materialStatuses?.forEach((materialStatus)=>{
                if(materialStatus?.responsibilityStatusId === 4){
                    isAnyoneWorkingHere = true
                }
            })
        // }else{
        //     isAnyoneWorkingHere = true
        // }
        let statusBoxArray = []
        let statusClassName = 'noStatus'
        // let assignedUserResponsibility = props.userResponsibilities?.find(item => item == responsibility.id)
        let status = productStatuses?.find(item => item.responsibility.id === responsibility.id)
        if (status) {
            statusClassName = statuses[status?.responsibilityStatusId]
            statusBoxArray.push(status)
        }

        return (
            <div data-toggle="tooltip" data-placement="top"
                 title={`${status?.responsibility?.label}${status?.responsibility?.userName && status?.responsibility?.userName != 'null' && status?.responsibility?.userName != 'undefined' ? ` nga ${status?.responsibility?.userName || ''}` : ''}`}
                 className={`status-box ${isAdmin ? 'admin' : 'notAdmin'} ${allChecksResponsibilityOnClick(productId, responsibility.id, clientId, status?.responsibilityStatusId, productStatuses, status?.responsibility?.label, productIndex, projectId) ? 'isValid' : ``} ${statusClassName}`}
                 onClick={() => openUpdatingStatusModal(productId, responsibility.id, clientId, status?.responsibilityStatusId, productStatuses, status?.responsibility?.label, productIndex, projectId, responsibilityStatusIndex === 0 ? null : productStatuses[responsibilityStatusIndex - 1]?.responsibility?.id)}>
                <p className={'m-0'}>{status?.responsibility?.label}</p>
                {givenAdminWarning && isAdmin &&
                <div data-toggle="tooltip"
                     data-placement="top"
                     title={givenAdminWarning}
                     className={'adminWarningSign'}>
                    <FontAwesome
                        name="info"
                    />
                </div>}
            </div>
        )
    }

    function checkPreviousMaterial(productId, responsibilityId, materialId, materialName, materialsOfProduct, statusResponsibilityLabel, responsibilityIndex, materialIndex, productStatuses) {
        let returnResult = true
        let tempArray = []
        if (productStatuses?.length !== 0) {
            productStatuses?.forEach((productStatus) => {
                materialsOfProduct[productStatus?.responsibility?.label]?.find((material) => {
                    if (material?.materialId === materialId) {
                        tempArray.push(material)
                    }
                })
            })
        }
        tempArray?.forEach((material, index) => {
            if ((material?.responsibilityId === responsibilityId) && (index !== 0)) {
                if (tempArray[index - 1]?.responsibilityStatusId === 1 || tempArray[index - 1]?.responsibilityStatusId === 4) {
                    returnResult = true
                } else {
                    returnResult = false;
                }
            }
        })
        return returnResult;
    }

    function allChecksResponsibilityOnClick(productId: number, responsibilityId: number, clientId: number, statusId: number, productStatuses, responsibilityLabel, productIndex, projectId, allMaterialStatuses?) {
        let isValid: any = true
        if (!checkIfUserHasThatResponsibility(responsibilityId)) {
            isValid = false
        }

        let status = productStatuses?.find(item => item.responsibility?.id === responsibilityId)
        // if (!isAdmin) {
        //     if (status.responsibility.userId && (currentUserId != status.responsibility.userId)) {
        //         isValid = true
        //     } else {
        //         // if(!checkIfPreviousProductIsDone(responsibilityId, productIndex)){
        //         //     isValid = false
        //         // }
        //     }
        //     let checkPreviousResponsibilityStatusIdLet = checkPreviousResponsibilityStatusId(responsibilityId, productIndex)
        //
        //     if(checkPreviousResponsibilityStatusIdLet === 4 || checkPreviousResponsibilityStatusIdLet === 9 || checkPreviousResponsibilityStatusIdLet === 8 || checkPreviousResponsibilityStatusIdLet === 7 || checkPreviousResponsibilityStatusIdLet === 6 || checkPreviousResponsibilityStatusIdLet === 5){
        //         // isValid = true
        //         isValid = true
        //     }
        //
        //     if (status?.responsibilityStatusId === 4 || status?.responsibilityStatusId === 9) {
        //         return true
        //     }
        //     if ((status.responsibility.userId) && status?.responsibilityStatusId === 4) {
        //         isValid = false
        //     }
        // }

        if (!isAdmin && (status?.responsibilityStatusId === 1)) {
            isValid = false
        }

        // let indexOfStatusBox = productStatuses.indexOf(status)
        // if (productStatuses.indexOf(status) !== 0) {
        //     if (productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 1 && productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 4 && productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 9) {
        //         isValid = false
        //     }
        // }


        // if (!isValid) {
        //     if (checkIfResponsibilityHasClickableMaterial(responsibilityId, responsibilityLabel, allMaterialStatuses, productStatuses, indexOfStatusBox)) {
        //         isValid = {
        //             hasClickableMaterials: true
        //         }
        //     }
        // }
        return isValid
    }

    function allChecksMaterialOnClick(productId, responsibilityId, materialId, materialName, materialsOfProduct, statusResponsibilityLabel, responsibilityIndex, materialIndex, productStatuses, material, productIndex, responsibility) {
        // if (material?.temporaryMaterial) {
        //     return true
        // }
        // if(!checkIfPreviousProductIsDone(responsibilityId, productInde\)){
        //     return false
        // }

        // if (!checkIfPreviousMaterialProcessIsDone(responsibilityId, productIndex, responsibilityIndex, material)) {
        //     return false
        // }
        //
        // if (!isAdmin && (material?.responsibilityStatusId === 1)) {
        //     return false
        // }
        //
        // if (!isAdmin) {
        //     if (responsibility?.userId && (currentUserId != responsibility?.userId)) {
        //         return false
        //     }
        //     if ((!responsibility.userId) && responsibility?.responsibilityStatusId === 4) {
        //         return false
        //     }
        // }
        //
        // let checkResponse = checkPreviousMaterial(productId, responsibilityId, materialId, materialName, materialsOfProduct, statusResponsibilityLabel, responsibilityIndex, materialIndex, productStatuses)
        //
        // if (!checkResponse) {
        //     return false
        // }

        return true
    }

    async function openUpdatingStatusModal(productId: number, responsibilityId: number, clientId: number, statusId: number, productStatuses, responsibilityLabel, productIndex, projectId, previousResponsibilityId) {
        let checkObjectIfCanUpdateStatus: any = {}
        setProductionFilterOpened(false)

        if (!checkIfUserHasThatResponsibility(responsibilityId)) {
            return;
        }

        // if (!isAdmin) {
        //     checkObjectIfCanUpdateStatus = await checkIfUserCanUpdateThisStatus(responsibilityId, productId)
        //     if (!checkObjectIfCanUpdateStatus?.userAvailability) {
        //         toast.error('Perfundoni procesin e nisur!')
        //         return;
        //     } else {
        //         if (checkObjectIfCanUpdateStatus?.hasMaterial) {
        //             toast.error('Ju lutem punoni me materiale!')
        //             return;
        //         }
        //     }
        // }
        if (props?.userResponsibilities.find((e) => e === responsibilityId) || isAdmin) {
            let status = productStatuses?.find(item => item.responsibility?.id === responsibilityId)
            if (!isAdmin) {
                // if (status.responsibility.userId && (currentUserId != status.responsibility.userId)) {
                //     toast.info('Ky proces eshte marrur nga dikush tjeter!')
                //     return;
                // } else {
                    // if(!(status?.responsibilityStatusId === 4)){
                    //     if(!checkIfPreviousProductIsDone(responsibilityId, productIndex)){
                    //         toast.error('Procesi ne produktin paraprak nuk ka perfunduar!')
                    //         return;
                    //     }
                    // }
                // }

                // if ((!status.responsibility.userId) && status?.responsibilityStatusId === 4) {
                //     toast.info('Kontaktoni menagjerin sepse eshte duke punuar!')
                //     return;
                // }
            }

            if (!isAdmin && (status?.responsibilityStatusId === 1)) {
                toast.info('Ky process ka perfunduar, kontakto menaxherin!')
                return;
            }

            // let indexOfStatusBox = productStatuses.indexOf(status)
            // if (!isAdmin && statusId !== 4) {
            //     if (productStatuses.indexOf(status) !== 0) {
            //
            //         if (productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 1 && productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 4) {
            //             toast.info('Faza paraprake duhet te perfundohet me sukses!')
            //             return
            //         }
            //     }
            // }
            axiosCaller('GET', `products/responsibility-and-status?productId=${productId}&responsibilityId=${responsibilityId}${previousResponsibilityId ? `&previousResponsibilityId=${previousResponsibilityId}` : ''}`).then((res) => {
                setComment(res.data?.data?.warning)
                setOtherComment(res.data?.data?.otherComment)
                setExistingComment(res.data?.data?.warning)
                setResponsibilityTimeTrackerInfo({
                    hasMaterials: res.data?.data?.hasMaterialStatuses,
                    duration: res.data?.data?.totalDuration,
                    trackersStart: res.data?.data?.trackersStart,
                    trackersEnd: res.data?.data?.trackersEnd
                })
                setWaitingDuration(res.data?.data?.waitingDuration ? res.data?.data?.waitingDuration : 0)
                if (res?.data?.data?.trackers?.length !== 0) {
                    if (res?.data?.data?.trackers[0]) {
                        let isPaused = true
                        let detailedTrackerCombinedDuration = 0

                        let selectedTracker = res?.data?.data?.trackers?.length !== 0 ? res?.data?.data?.trackers[0] : null

                        if (selectedTracker !== null) {
                            selectedTracker?.detailedTracker?.forEach(detailedTracker => {
                                detailedTrackerCombinedDuration = detailedTrackerCombinedDuration + detailedTracker?.duration
                            })

                            let pausedDetailedTracker = selectedTracker?.detailedTracker?.find((detailedTracker) => detailedTracker?.pause === null)
                            if (pausedDetailedTracker) {
                                let eventStartTime = new Date(convertDateForIos(pausedDetailedTracker?.start)).getTime();
                                let eventEndTime = new Date().getTime()
                                let addedDuration = (+eventEndTime - +eventStartTime) / 1000
                                detailedTrackerCombinedDuration = detailedTrackerCombinedDuration + addedDuration - 7200
                                isPaused = false
                            }

                            setPaused(isPaused)
                            setShowSeconds(true)
                            setSeconds(detailedTrackerCombinedDuration)
                        }
                    } else {
                        setShowSeconds(true)
                        setSeconds(0)
                        setPaused(true)
                    }
                } else {
                    setShowSeconds(true)
                    setSeconds(0)
                    setPaused(true)
                }
            })

            props.productionActions?.getBlueprintFiles(productId)
            props.productionActions.setSelectedTrack({})
            setSelectedResponsibilityId(responsibilityId)
            localStorage.setItem('mikaSelectedProductId', `${productId}`)
            setSelectedProductId(productId)
            setSelectedAdminWarning(status?.adminWarning)
            setSelectedProductClientId(clientId)
            setSelectedStatusId(statusId)
            setSelectedPauseReason(pauseReasonsEnum[statusId])
            setAlreadySetStatusId(statusId)
            setSelectedResponsibilityLabel(responsibilityLabel)
            setSelectedResponsibilityUser(status?.responsibility?.userName)
            setSelectedProjectId(projectId)
        }
    }

    async function openUpdatingMaterialStatusModal(productId, responsibilityId, materialId, materialName, materialsOfProduct, statusResponsibilityLabel, responsibilityIndex, materialIndex, productStatuses, material, productIndex, responsibility) {
        let checkObjectIfCanUpdateStatus: any = {}
        setProductionFilterOpened(false)
        // if(!checkIfPreviousProductIsDone(responsibilityId, productIndex)){
        //     toast.error('Procesi ne produktin paraprak nuk ka perfunduar!')
        //     return;
        // }

        // if (!material?.temporaryMaterial) {
        //     if(!isAdmin){
        //         if (!checkIfPreviousMaterialProcessIsDone(responsibilityId, productIndex, responsibilityIndex, material)) {
        //             toast.error('Procesi paraprak nuk ka perfunduar!')
        //             return;
        //         }
        //     }
        // }


        if (!isAdmin && (material?.responsibilityStatusId === 1)) {
            toast.info('Ky material eshte perfunduar, kontakto menaxherin!')
            return;
        }

        // if (!isAdmin) {
        //     // if (responsibility?.userId && (currentUserId != responsibility?.userId)) {
        //     //     toast.info('Ky proces eshte marrur nga dikush tjeter!')
        //     //     return;
        //     // }
        //     if ((!responsibility.userId) && responsibility?.responsibilityStatusId === 4) {
        //         toast.info('Kontaktoni menagjerin sepse eshte duke punuar!')
        //         return;
        //     }
        // }

        // if (!isAdmin) {
        //     checkObjectIfCanUpdateStatus = await checkIfUserCanUpdateThisStatus(responsibilityId, productId)
        //     if (!checkObjectIfCanUpdateStatus?.userAvailability) {
        //         toast.error('Perfundoni procesin e nisur!')
        //         return;
        //     }
        // }
        if (material?.trackers) {
            let materialTracker = material?.trackers[Object.keys(material?.trackers)[0]]
            if (materialTracker) {
                let isPaused = true
                let detailedTrackerCombinedDuration = 0
                let selectedTracker = materialTracker
                if (selectedTracker !== null) {
                    selectedTracker?.detailedTracker?.forEach(detailedTracker => {
                        detailedTrackerCombinedDuration = detailedTrackerCombinedDuration + detailedTracker?.duration
                    })

                    let pausedDetailedTracker = selectedTracker?.detailedTracker?.find((detailedTracker) => detailedTracker?.pause === null)
                    if (pausedDetailedTracker && pausedDetailedTracker?.start !== null) {
                        let eventStartTime = pausedDetailedTracker?.start ? new Date(convertDateForIos(pausedDetailedTracker?.start)).getTime() : new Date()
                        let eventEndTime = new Date().getTime()
                        let addedDuration = (+eventEndTime - +eventStartTime) / 1000
                        detailedTrackerCombinedDuration = detailedTrackerCombinedDuration + addedDuration - 7200
                        isPaused = false
                    }

                    setPaused(isPaused)
                    setShowSeconds(true)
                    setSeconds(detailedTrackerCombinedDuration)
                }

            } else {
                setShowSeconds(true)
                setSeconds(0)
                setPaused(true)
            }
        } else {
            setShowSeconds(true)
            setSeconds(0)
            setPaused(true)
        }

        // let checkResponse = checkPreviousMaterial(productId, responsibilityId, materialId, materialName, materialsOfProduct, statusResponsibilityLabel, responsibilityIndex, materialIndex, productStatuses)
        //
        // if ((!checkResponse && !material?.temporaryMaterial) && !isAdmin) {
        //     toast.error('Procesi paraprak i ketij material nuk eshte perfunduar!')
        //     return
        // } else {
            if (!isDeleteModalOpen) {
                setSelectedProductId(productId)
                localStorage.setItem('mikaSelectedProductId', `${productId}`)
                props.productionActions?.getBlueprintFiles(productId)
                setSelectedResponsibilityId(responsibilityId)
                setComment(material?.warning)
                setOtherComment(material?.otherComment)
                setExistingComment(material?.warning)
                setSelectedResponsibilityLabel(statusResponsibilityLabel)
                setSelectedResponsibilityUser(responsibility?.userName)
                setAlreadySetStatusId(material?.responsibilityStatusId)
                setSelectedStatusId(material?.responsibilityStatusId)
                setSelectedPauseReason(pauseReasonsEnum[material?.responsibilityStatusId])
                setSelectedMaterial({
                    materialName,
                    responsibility,
                    temporaryMaterialId: material?.temporaryMaterialId,
                    materialId: materialId ? materialId : material?.temporaryMaterialId
                })
                setSelectedAdminWarning(material?.adminWarning)
                // setSelectedProjectId(projectId)
            }
        // }
    }

    function closeUpdatingStatusModal() {
        setProductionFilterOpened(false)
        setCalendarComment('')
        setSelectedResponsibilityId(null)
        setSelectedProductClientId(null)
        setSelectedStatusId(null)
        setProjectUploads([])
        // setSelectedProductId(null)
        // localStorage.removeItem('mikaSelectedProductId')
        setSelectedResponsibilityLabel('')
        setSelectedResponsibilityUser('')
        setSelectedProjectId(null)
        setComment('')
        setSelectedPauseReason({} as SelectOption)
        setOtherComment('')
        setSelectedMaterial(null)
        setShowSeconds(false)
        setSeconds(0)
        setPaused(true)
        setIsTrackerExtraInfoModalOpen(false)
    }

    function selectTemporaryMaterialStatus(product, status, materialName?, materialStatusId?) {
        let temp = {
            materialStatusId: materialStatusId,
            productId: product?.productId,
            responsibilityId: status?.responsibility,
            materialName: materialName
        }
        setTempMaterialStatus(temp)
    }

    function saveTemporaryMaterial() {
        setButtonLoader(true)
        let payload = {
            productId: tempMaterialStatus?.productId,
            responsibilityId: tempMaterialStatus?.responsibilityId?.id,
            materialName: tempMaterialStatus?.materialName,
        }

        if (tempMaterialStatus?.materialStatusId) {
            let editPayload = {
                ...payload,
                materialName: tempMaterialStatus?.materialName,
                responsibilityStatusId: 2
            }
            delete editPayload.productId
            delete editPayload.responsibilityId
            //    edit material-status
            axiosCaller('POST', `material-status/${tempMaterialStatus?.materialStatusId}`, editPayload).then((res) => {
                setButtonLoader(false)
                if (res?.status == 200) {
                    toast.success('Materiali i perkohshem u editua me sukses!')
                    props?.productionActions?.getMaterialsOfProduct(tempMaterialStatus?.productId)
                    closeTemporaryMaterialModal()
                }
            })
        } else {
            //    create material-status
            axiosCaller('POST', `material-status`, payload).then((res) => {
                setButtonLoader(false)
                if (res?.status == 200) {
                    toast.success('Materiali i perkohshem u krijua me sukses!')
                    props?.productionActions?.getMaterialsOfProduct(tempMaterialStatus?.productId)
                    closeTemporaryMaterialModal()
                }
            })
        }
    }

    function deleteTemporaryMaterial(materialStatus) {
        setTempMaterialStatus({...materialStatus, materialStatusId: materialStatus?.id})
        setDeleteModal(true)
    }

    function deleteTemporaryMaterialConfirmation() {
        axiosCaller('DELETE', `material-status/${tempMaterialStatus?.materialStatusId}`).then((res) => {
            if (res.status == 200) {
                props?.productionActions?.getMaterialsOfProduct(tempMaterialStatus?.productId)
                setDeleteModal(false)
                setTempMaterialStatus({})
            }
        })
    }

    function closeTemporaryMaterialModal() {
        setTempMaterialStatus({})
    }

    function checkIfUserHasThatResponsibility(responsibilityId) {
        const hasResponsibility = props.userResponsibilities?.includes(responsibilityId);
        if (hasResponsibility || isAdmin) {
            return true;
        } else {
            return false;
        }
    }

    function checkTheProcessBeforeTaken(responsibilityId, productIndex) {
        // if (!isAdmin) {
        let productStatuses = productsWithStatuses[productIndex - 1]?.statuses


        let foundStatus = productStatuses?.find(item => item?.responsibility?.id === responsibilityId)

        if (foundStatus?.responsibilityStatusId === 4) {
            if (foundStatus?.responsibility?.userId) {

            }
        }
        // let indexOfStatusBox = productStatuses?.indexOf(foundStatus)
        // // if (productStatuses[indexOfStatusBox]?.responsibilityStatusId === 1) {
        // //     return false
        // // }
        // if (indexOfStatusBox !== 0) {
        //     if (productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId === 2) {
        //         return false
        //     } else {
        //         return true
        //     }
        // } else {
        //     return true
        // }
        // } else {
        //     return true
        // }
    }

    function checkTheProcessBeforeIfItsDone(status, index) {
        if (!isAdmin) {
            let productStatuses = productsWithStatuses[index]?.statuses
            let foundStatus = productStatuses?.find(item => item?.responsibility?.id === status?.responsibility?.id)
            let indexOfStatusBox = productStatuses?.indexOf(foundStatus)
            // if (productStatuses[indexOfStatusBox]?.responsibilityStatusId === 1) {
            //     return false
            // }
            if (indexOfStatusBox !== 0) {
                if (productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId === 2) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    function checkIfPreviousProductIsDone(responsibilityId, productIndex) {
        // return true
        if (productIndex !== 0) {
            if (isAdmin) {
                return true
            } else {
                let previousProductResponsibility = productsWithStatuses[productIndex - 1]?.statuses?.find((status) => status?.responsibility?.id === responsibilityId)
                if (productsWithStatuses[productIndex - 1]?.measureDone) {
                    if (previousProductResponsibility) {

                        if (previousProductResponsibility?.responsibilityStatusId === 4) {
                            if (previousProductResponsibility?.responsibility?.userId === currentUserId) {
                                return previousProductResponsibility?.responsibilityStatusId === 1
                            } else {
                                return true
                            }
                        } else if (previousProductResponsibility?.responsibilityStatusId === 3 || previousProductResponsibility?.responsibilityStatusId === 1) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return true
                    //provo mmi thirr checkIfPreviousProductIsDone functionin prap qe mi gjet deri te cili e ka measure done
                }
            }
        } else {
            return true
        }
    }

    function checkPreviousResponsibilityStatusId(responsibilityId, productIndex) {
        if (productIndex !== 0) {
            let previousProductResponsibility = productsWithStatuses[productIndex - 1]?.statuses?.find((status) => status?.responsibility?.id === responsibilityId)
            if (previousProductResponsibility) {
                return previousProductResponsibility?.responsibilityStatusId
            }
        }
    }

    function checkIfMaterialPreviousProcessIsDone(foundMaterialInPreviousProcess) {
        return foundMaterialInPreviousProcess?.responsibilityStatusId === 1;
    }


    function checkIfResponsibilityHasClickableMaterial(responsibilityId, responsibilityLabel, allMaterialStatuses, productStatuses, indexOfStatusBox) {
        const groupByResponsibilityId = allMaterialStatuses?.reduce((group, materials) => {
            const {responsibilityId} = materials;
            group[responsibilityId] = group[responsibilityId] ?? [];
            group[responsibilityId].push(materials);
            return group;
        }, {});

        let returnedValue = false;

        for (let i = indexOfStatusBox; i >= 0; i--){
            let temporaryResponsibilityId = productStatuses[indexOfStatusBox]?.responsibility?.id
            if(groupByResponsibilityId && temporaryResponsibilityId && groupByResponsibilityId[temporaryResponsibilityId]){
                let materialsOfResponsibility = groupByResponsibilityId[temporaryResponsibilityId]

                materialsOfResponsibility?.forEach((materialOfResponsibility)=>{
                    for (let i = (indexOfStatusBox-1); i >= 0; i--){
                        let temporaryPreviousResponsibilityId = productStatuses[indexOfStatusBox - 1]?.responsibility?.id
                        if(groupByResponsibilityId && temporaryPreviousResponsibilityId && groupByResponsibilityId[temporaryPreviousResponsibilityId]){
                            let temporaryMaterialId = materialOfResponsibility?.materialId
                            let previousResponsibilityMaterial = groupByResponsibilityId[temporaryPreviousResponsibilityId]?.find((elementMaterialStatus:any)=>elementMaterialStatus?.materialId === temporaryMaterialId)
                            if(previousResponsibilityMaterial){
                                if(previousResponsibilityMaterial?.responsibilityStatusId === 2 || previousResponsibilityMaterial?.responsibilityStatusId === 4){
                                    returnedValue = true

                                    // return returnedValue
                                }else{
                                    returnedValue = false
                                }
                                if(returnedValue){
                                    return
                                }

                            }
                            if(returnedValue){
                                return
                            }
                        }
                    }
                })
            }
        }

        return returnedValue;
    }


    function checkIfPreviousMaterialProcessIsDone(responsibilityId, productIndex, responsibilityIndex, material) {
        if (responsibilityIndex !== 0) {
            const previousResponsibility = productsWithStatuses[productIndex]?.responsibilities[responsibilityIndex - 1];

            if (materialsOfProduct[previousResponsibility?.label]) {
                const foundMaterialInPreviousProcess = materialsOfProduct[previousResponsibility?.label]?.find(materialStatus => materialStatus?.materialId === material?.materialId);

                if (foundMaterialInPreviousProcess) {
                    return checkIfMaterialPreviousProcessIsDone(foundMaterialInPreviousProcess);
                } else {
                    let isValid = true;

                    for (let i = responsibilityIndex - 1; i > 0; i--) {
                        const anotherFoundMaterialInPreviousProcess = materialsOfProduct[productsWithStatuses[productIndex]?.responsibilities[i]?.label]?.find(materialStatus => materialStatus?.materialId === material?.materialId);

                        if (anotherFoundMaterialInPreviousProcess) {
                            isValid = checkIfMaterialPreviousProcessIsDone(anotherFoundMaterialInPreviousProcess);
                        }
                    }

                    if (!isValid) {
                        for (let i = responsibilityIndex - 1; i > 0; i--) {
                            if (!materialsOfProduct[productsWithStatuses[productIndex]?.responsibilities[i]?.label]) {
                                if (productsWithStatuses[productIndex]?.statuses[i]?.responsibilityStatusId === 1) {
                                    isValid = true;
                                }
                            }
                        }
                    }

                    return isValid;
                }
            } else {
                return productsWithStatuses[productIndex]?.statuses[responsibilityIndex - 1]?.responsibilityStatusId !== 2;
            }
        } else {
            return true;
        }
    }


    function checkIfPreviousProcessIsDone(responsibilityId, productIndex, responsibilityIndex, statusResponsibilityLabel, material) {
        if (responsibilityIndex !== 0) {
            let previousResponsibility = productsWithStatuses[productIndex]?.responsibilities[responsibilityIndex - 1]
            if (previousResponsibility?.materialStatuses?.length !== 0) {
                let foundMaterialInPreviousProcess = materialsOfProduct[previousResponsibility?.label]?.find((materialStatus) => materialStatus?.materialId === material?.materialId)
                if (foundMaterialInPreviousProcess) {
                    if (foundMaterialInPreviousProcess?.responsibilityStatusId === 1) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            } else {

                return true
            }
        } else {
            return true
        }
    }

    function removeUnnecessaryRows(index) {

        let selectedRowDocument = document.getElementById(`row${index}`)

        let selectedStatusesBooleans = []

        for (let i = 0; i < selectedRowDocument?.getElementsByClassName('status-container').length; i++) {

            selectedStatusesBooleans.push(selectedRowDocument?.getElementsByClassName('status-container')[i].classList.contains('d-none'))
        }
        let removeRow = true
        selectedStatusesBooleans.forEach((boolean) => {
            if (boolean === false) {
                removeRow = false
            }
        })


        if (removeRow) {
            document.getElementById(`row${index}`)?.classList.add("d-none")
        }
    }

    function applyAllChecks(status, index, from?) {
        // if(from === 'material'){
        //     return checkIfUserHasThatResponsibility(status?.responsibility?.id)
        // }else{
        //     return checkIfUserHasThatResponsibility(status?.responsibility?.id) && checkTheProcessBeforeIfItsDone(status, index)
        // }
        return checkIfUserHasThatResponsibility(status?.responsibility?.id)
    }

    function makeProductPassive() {
        setButtonLoader(true)

        let payload = {
            productId: selectedProductId,
            active: 0
        }

        axiosCaller('POST', `responsibilities/clients/statuses`, payload).then(() => {
            props.productionActions.fetchProductStatuses(selectedDepartmentId ? selectedDepartmentId : localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, props?.doneStatus, searchedClient ? searchedClient : localStorage?.getItem('mikaSearchClient'))
            setButtonLoader(false)
            setPassiveProductModal(false)
        })
    }

    function updateStatus(from) {
        if (!selectedStatusId) {
            toast.error("Duhet te zgjidhni statusin per ruajtje!")
            return;
        }
        if (selectedStatusId === 8 && !otherComment) {
            toast.info("Ju lutem pershkruani arsyen!")
            return;
        }
        if (isAdmin) {
            if (selectedStatusId === 3 && !comment && selectedStatusId === 3 && !selectedAdminWarning) {
                toast.info('Shto verejtjen apo arsyen e voneses!')
                return
            } else if (selectedStatusId === 3 && !selectedAdminWarning) {
                if (comment === existingComment) {
                    toast.info('Shto verejtjen apo arsyen e voneses!')
                    return
                }
            }
        } else {
            if (selectedStatusId === 3 && !comment) {
                toast.info('Shto verejtjen apo arsyen e voneses!')
                return
            } else if (selectedStatusId === 3) {
                if (comment === existingComment) {
                    toast.info('Shto verejtjen apo arsyen e voneses!')
                    return
                }
            }
        }

        // let payload = {
        //     productId: selectedProductId,
        //     responsibilityStatusId: selectedStatusId,
        //     responsibilityId: selectedResponsibilityId,
        //     warning: comment,
        //     adminWarning: selectedAdminWarning
        // }

        // setTimeout(()=>{
        //     socket.emit('message', {payload, token: localStorage.getItem('mikaBearerToken')});
        // },3000)

        // io('http://127.0.0.1:6379').emit('message', {payload, token: localStorage.getItem('mikaBearerToken')});
        // return;
        setButtonLoader(true)
        if (from === 'responsibilityStatus') {
            let payload = {
                productId: selectedProductId,
                responsibilityStatusId: selectedStatusId,
                responsibilityId: selectedResponsibilityId,
                warning: comment,
                adminWarning: selectedAdminWarning,
                otherComment: otherComment
            }


            // io('http://127.0.0.1:6379').emit('message', {payload, token: localStorage.getItem('mikaBearerToken')});

            // toggleTimer()

            axiosCaller('POST', `responsibilities/clients/statuses`, payload).then((res) => {
                if(res?.data?.data){
                    props.productionActions.fetchProductStatuses(selectedDepartmentId ? selectedDepartmentId : localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, props?.doneStatus, searchedClient ? searchedClient : localStorage?.getItem('mikaSearchClient'))
                    props?.productionActions?.getMaterialsOfProduct(selectedProductId)
                    toast.success('Statusi u ndryshua me sukses!')

                    let payload = {
                        productId: selectedProductId,
                        clientId: selectedProductClientId,
                        moduleId: 5
                    }

                    axiosCaller('POST', 'clients/move-product-to-warehouse', payload).then((res) => {
                        if (res.data === true) {
                            toast.success('Produkti u shtua ne depo')
                        }
                    })
                }else{
                    toast.info('Nuk mund te editoni ketu!')
                }
                setSelectedResponsibilityId(null)
                setButtonLoader(false)
            })
        } else {

            let payload = {
                productId: selectedProductId,
                responsibilityStatusId: selectedStatusId,
                responsibilityId: selectedResponsibilityId,
                warning: comment,
                materialId: selectedMaterial?.temporaryMaterialId ? null : selectedMaterial?.materialId,
                temporaryMaterialId: selectedMaterial?.temporaryMaterialId,
                adminWarning: selectedAdminWarning,
                otherComment: otherComment
            }

            axiosCaller('POST', `responsibilities/clients/material/statuses`, payload).then((res) => {
                if(res?.data?.data){
                    toast.success('Statusi u ndryshua me sukses!')
                }else{
                    toast.info('Nuk mund te editoni ketu!')
                }
                props?.productionActions?.getMaterialsOfProduct(selectedProductId).then((allMaterials) => {
                    props.productionActions.fetchProductStatuses(selectedDepartmentId ? selectedDepartmentId : localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, props?.doneStatus, searchedClient ? searchedClient : localStorage?.getItem('mikaSearchClient'))
                    closeUpdatingStatusModal()
                    setButtonLoader(false)
                })
            })
        }

        setButtonLoading(true)
    }

    function deleteBlueprintFile(fileId) {
        props.productionActions.deleteBlueprintFile(fileId).then(() => {
            props.productionActions?.getBlueprintFiles(selectedProductId)
        })
    }

    function changeStatusId(statusId) {
        // if (isAdmin) {
        //     setSelectedStatusId(statusId)
        // } else {
            if (alreadySetStatusId === 1) {
                if (statusId === 2 || statusId === 3) {
                    setSelectedStatusId(statusId)
                } else if (!selectedAdminWarning) {
                    toast.info('Nese doni te ktheni nje pune te perfunduar, ktheni me vonese( me koment nga admini ) ose per tu realizuar!')
                    return
                }
            }
            if (statusId === 1) {
                if (alreadySetStatusId !== 4) {
                    // if(props?.permissions?.fullPrivileges){
                    //     setSelectedStatusId(statusId)
                    // }else{
                        toast.error('Fillimisht duhet te jete ne process!')
                    // }
                } else {
                    setSelectedStatusId(statusId)
                }
            } else {
                setSelectedStatusId(statusId)
            }
        // }
        setSelectedPauseReason({} as SelectOption)
    }


    function getCorrespondingMaterials(productId, index, measureDone) {

        if (!measureDone) {
            return
        }

        if (selectedProductIndex === index) {
            localStorage.removeItem('mikaSelectedProductId')
        } else {
            localStorage.setItem('mikaSelectedProductId', `${productId}`)
        }
        setButtonLoader(true)
        setSelectedProductId(productId)
        setSelectedProductIndex(selectedProductIndex === index ? null : index)
        // if(selectedProductIndex === index ? null : index){
        props?.productionActions?.getMaterialsOfProduct(productId).then(() => {
            setButtonLoader(false)
        })
        // }
    }

    useEffect(() => {
        const groupByResponsibilityName = props?.production?.materialsOfProduct.reduce((group, materials) => {
            const {responsibilityName} = materials;
            group[responsibilityName] = group[responsibilityName] ?? [];
            group[responsibilityName].push(materials);
            return group;
        }, {});
        setMaterialsOfProduct(groupByResponsibilityName)
    }, [props?.production?.materialsOfProduct])

    function openSelectedProductModal(productData) {
        props.productionActions?.getBlueprintFiles(productData?.productId)
        // setProductStartDate(new Date(productData?.start))
        // setProductEndDate(new Date(productData?.end))
        console.log(productData,'productData,')
        setSelectedProductStatusRow(productData)
    }

    function closeSelectedProductModal() {
        setSelectedProductStatusRow(undefined)
        setProductEndDate('')
        setProductStartDate('')
        setCalendarComment('')
    }

    function saveProductDates() {
        if (productStartDate || productEndDate) {
            if (!calendarComment) {
                toast.info('Ju lutem vendosni nje koment per ndryshime te datave!')
                return;
            }
        }

        let productStartDateMs = new Date(productStartDate)?.getTime()
        let productEndDateMs = new Date(productEndDate)?.getTime()
        if (productStartDateMs > productEndDateMs) {
            toast.error('Data e mbarimit smund te jete para fillimit!')
            return;
        }

        if ((productStartDate || selectedProductStatusRow?.start) && productEndDate) {
            if (new Date(productStartDate).getTime() + 86400000 < new Date()?.getTime()) {
                toast.info('Data e fillimit duhet te jete ne te ardhmen!')
                return
            }

            let payload = {
                productId: Number(selectedProductStatusRow?.productId),
                start: productStartDate ? moment(productStartDate).format('YYYY-MM-DD') : moment(selectedProductStatusRow?.start).format('YYYY-MM-DD'),
                end: moment(productEndDate).format('YYYY-MM-DD'),
                calendarComment: calendarComment
            }

            props.productionActions.updateProductDates(payload).then((res) => {
                if (res?.data?.data) {
                    props.productionActions.fetchProductStatuses(selectedDepartmentId ? selectedDepartmentId : localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, props?.doneStatus, searchedClient ? searchedClient : localStorage?.getItem('mikaSearchClient')).then(() => {
                        toast.success('Data eshte edituar')
                        closeSelectedProductModal()
                    })
                } else {
                    toast.error('Keto data jane te zena!')
                }
            })
        } else {
            toast.info('Ju lutem mbushni fushat e datave!')
        }
    }

    function checkIfRowNeededToBeRemoved(product) {
        if(props?.doneStatus == 1){
            return
        }

        let hideRow = true
        product?.statuses?.forEach((status) => {
            if (checkIfUserHasThatResponsibility(status?.responsibility?.id)) {
                hideRow = false
            }
        })
        // if(hideRow){
        //     setSelectedProductIndex(null)
        // }
        return hideRow
    }

    function checkIfRowNeedsToBeRemovedForSimpleUsers(product, index) {
        let hideRow = true

        if (isAdmin) {
            return false
        }

        product?.statuses?.forEach((status) => {
            let assignedUserResponsibility = props.userResponsibilities?.find(item => item == status?.responsibility.id)

            if (status?.responsibility.id === assignedUserResponsibility) {
                if (allChecksResponsibilityOnClick(product.productId, status?.responsibility.id, product.clientId, status?.responsibilityStatusId, product.statuses, status?.responsibility?.label, index, product?.projectId, product?.allMaterialStatuses)) {
                    hideRow = false
                }
            }
        })


        return hideRow
    }

    function pauseWork(selectOption) {
        if (alreadySetStatusId === 2) {
            toast.info('Fillimisht duhet ta nisni procesin!')
            return
        }
        setSelectedStatusId(selectOption?.value)
        setSelectedPauseReason(selectOption)
    }

    function controlZoom(increace) {
        if (increace) {
            if (zoomLvl === 150) {
                return
            } else {
                setZoomLvl(zoomLvl + 10)
            }
        } else {
            if (zoomLvl === 10) {
                return
            } else {
                setZoomLvl(zoomLvl - 10)
            }
        }
    }

    function setProductionFiltersToLocalStorage(key, value){
        let tempProductionFilters = {
            ...productionFilters,
            [key]: value[key]
        }

        if(value[key]?.value === null){
            delete tempProductionFilters[key]
        }
        localStorage.setItem('mikaProductionFilters', JSON?.stringify(tempProductionFilters))

        setProductionFilters(tempProductionFilters)
    }

    function convertFileToBase64(fileUrl, callback) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };

        xhr.send();
    }

    return (
        <div className="product-statuses-container">
            <div className={'mobileAlign'}>
                <p className="title">
                    <TranslatedText id="production.responsibilityAssigment" defaultMessage="Kontrolli i proceseve"/>
                </p>
                <div className="searchByClientContainer">
                    <input className={'searchBar'} type="text" placeholder={'Kerko sipas klientit!'}
                           value={searchedClient} onChange={(e) => {
                        setSearchedClient(e.target.value)
                        setSelectedProductIndex(null)
                        localStorage.setItem('mikaSearchClient', e.target.value)
                    }} onKeyDown={(e) => e.key === 'Enter' ? filterProducts() : ''}/>
                    <div className={'searchIcon'} onClick={filterProducts}>
                        <FontAwesome name={'search'}/>
                    </div>
                </div>

            </div>
            <div className={'departmentFilterContainer'}>
                <div className={`departmentFilter ${selectedDepartmentId === null ? 'active' : ''}`}
                     onClick={() => setSelectedDepartmentId(null)}>
                    <p><TranslatedText id="all" defaultMessage="Te gjitha"/></p>
                </div>
                {props?.departments?.map((department) => {
                    return (<div
                        className={`departmentFilter ${selectedDepartmentId === department?.value ? 'active' : ''}`}
                        onClick={() => setSelectedDepartmentId(department?.value)}>
                        <p>{department?.label}</p>
                    </div>)
                })}
            </div>
            <div className={'departmentFilterContainer'}>
                {/*<MikaSelect options={props?.departments} value={{value: selectedDepartmentId, label: selectedDepartmentLabel}} setEntireOptionFunction={(selectedDepartment)=>{setSelectedDepartmentId(selectedDepartment?.value); selectedDepartmentLabel(selectedDepartment?.label)}}/>*/}
            </div>
            <div className="status-table w-100" style={{zoom: `${zoomLvl}%`}}>
                <div className={'zoomContainer'}>
                    <div className={'minus'} onClick={() => controlZoom(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path
                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM136 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H280c13.3 0 24-10.7 24-24s-10.7-24-24-24H136z"/>
                        </svg>
                    </div>
                    <div className={'divider'}></div>
                    <div className={'plus'} onClick={() => controlZoom(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path
                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/>
                        </svg>
                    </div>
                </div>
                <div className="status-table-header">
                    <div className="header-title col-margin checkbox">
                    </div>
                    <div className="header-title col-margin client">
                        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"
                                                           defaultMessage="Klienti"/></p>
                    </div>
                    <div className="header-title col-margin product">
                        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"
                                                           defaultMessage="Produkti"/></p>
                    </div>
                    <div
                        className={`header-title col-margin product ${isAdmin ? 'admin' : 'notAdmin'}`}>
                        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"
                                                           defaultMessage="Projekti"/></p>
                    </div>
                    <div className={'productionFilterIconContainer'}>
                        <HotkeyButton
                            keyCode="Escape"
                            modifiers={[]}
                            isActive={false}
                            onStateChange={(state) => {
                                if (state) {
                                    setProductionFilterOpened(false)
                                }
                            }}
                        >
                            <div className={'productionFilterIcon'}
                                 onClick={() => setProductionFilterOpened(!productionFilterOpened)}>
                                <FontAwesome
                                    name={'filter'}
                                />
                            </div>
                        </HotkeyButton>
                        <div className={`productionFilterWrappers ${productionFilterOpened ? 'opened' : 'hidden'}`}>
                            <div
                                className={`productionFilter`}>
                                <MikaSelect options={filterBy} placeholder={'Filtrimi sipas:'}
                                            value={productionFilters?.selectedFilterDatesBy}
                                            setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedFilterDatesBy', {
                                                selectedFilterDatesBy: value
                                            })}/>
                            </div>
                            <div
                                className={`productionFilter dateContainer`}>
                                <p className={'label'}>{<TranslatedText id="startDate"
                                                                        defaultMessage={'Data e fillimit'}/>}</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                         className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`productionFilter dateContainer`}>
                                <p className={'label'}>{<TranslatedText id="endDate"
                                                                        defaultMessage={'Data e mbarimit'}/>}</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setEndDate('')}
                                         className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`productionFilter`}>
                                <MikaSelect options={sortOptions} placeholder={'Renditja'}
                                            value={productionFilters?.selectedSort}
                                            setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedSort', {
                                                selectedSort: value
                                            })}/>
                            </div>
                            <div
                                className={`productionFilter`}>
                                <MikaSelect options={props.production.responsibilities} placeholder={'Procesi'}
                                            value={productionFilters?.selectedResponsibility}
                                            setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedResponsibility', {
                                                selectedResponsibility: value
                                            })}/>
                            </div>
                            <div
                                className={`productionFilter`}>
                                <MikaSelect options={mikaSelectStatusOptions} placeholder={'Statusi'}
                                            value={productionFilters?.selectedStatus}
                                            setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedStatus', {
                                                selectedStatus: value
                                            })}/>
                            </div>
                        </div>
                    </div>
                    {props?.permissions?.fullPrivileges && <div className={"isAdminSwitch"}>
                        <p>Admin:</p>
                        <label className="switch">
                            <input type="checkbox" checked={isAdmin} onChange={() => {
                                setIsAdmin(!isAdmin)
                            }}/>
                            <span className="slider round"></span>
                        </label>
                    </div>}
                </div>
                <>
                    {loading ? <SimpleLoader/> : <>
                        {productsWithStatuses && <div className="status-table-content">
                            {productsWithStatuses?.length !== 0 ?
                                <>
                                    {productsWithStatuses?.map((product, index) => {
                                        if (product?.statuses?.length === 0) {
                                            return
                                        }
                                        return (
                                            <>

                                                <div
                                                    className={`status-row ${product?.measureDone ? '' : 'opacityLow'} ${checkIfRowNeededToBeRemoved(product) ? 'd-none' : ''} ${checkIfRowNeedsToBeRemovedForSimpleUsers(product, index) ? 'd-none' : ''}`}
                                                    id={`row${index}`}
                                                    key={`${Math.random()}${product.id}${index}${product.id}`}>
                                                    {!props.permissions.observer ? <div
                                                        className={`client-name checkbox hoverable col-margin ${isAdmin ? '' : 'd-none'}`}
                                                        data-toggle="tooltip" data-placement="top"
                                                        title={'Ktheni produktin pasiv?'} onClick={() => {
                                                        setPassiveProductModal(true);
                                                        setSelectedProductId(product?.productId)
                                                    }}>
                                                        <ReplayIcon/>
                                                    </div> : <div
                                                        className={`client-name checkbox hoverable col-margin ${isAdmin ? '' : 'd-none'}`}>
                                                    </div>
                                                    }
                                                    <div className="client-name col-margin nameContainer"
                                                         data-toggle="tooltip"
                                                         data-placement="top" title={product.clientName}>
                                                        <p className="m-0"
                                                           onClick={() => openSelectedProductModal(product)}>{product?.clientName}</p>
                                                    </div>
                                                    <div className="product-name col-margin quantityWrapper"
                                                         data-toggle="tooltip"
                                                         data-placement="top" title={product?.productName}>
                                                        <p className="m-0"
                                                           onClick={() => openSelectedProductModal(product)}>{product?.productName}({product?.productCode})</p>
                                                        {product?.quantity && <p className={'quantityContainer'}>{parseFloat(product?.quantity)?.toFixed(2)} {product?.unit}</p>}
                                                    </div>
                                                    <div
                                                        className={`product-name project-name col-margin ${isAdmin ? 'admin' : 'notAdmin'}`}
                                                        data-toggle="tooltip" data-placement="top"
                                                        title={product?.projectName}>
                                                        <p className="m-0">{product?.projectName}</p>
                                                        <img src={arrowDown}
                                                             onClick={() => getCorrespondingMaterials(product?.productId, index, product?.measureDone)}
                                                             className={`toggleArrow ${selectedProductIndex === index && selectedProductId === product?.productId ? 'toggled' : ''}`}
                                                             alt=""/>
                                                    </div>
                                                    {product?.statuses?.map((status, responsibilityStatusIndex) => {
                                                        let allChecksResponsibilityOnClickVariable: any = allChecksResponsibilityOnClick(product.productId, status?.responsibility.id, product.clientId, status?.responsibilityStatusId, product.statuses, status?.responsibility?.label, index, product?.projectId, product?.allMaterialStatuses)

                                                        return <div
                                                            // className={`status-container col-margin ${applyAllChecks(status, index, 'responsibility') ? '' : 'd-none'} ${isAdmin ? '' : 'simpleUser'} ${ (!isAdmin) && status?.responsibilityStatusId === 1 ?  'd-none' : ''} ${allChecksResponsibilityOnClick(product.productId, status?.responsibility.id, product.clientId, status?.responsibilityStatusId, product.statuses, status?.responsibility?.label, index, product?.projectId) ? 'isValid' : ``}`}
                                                            className={`status-container col-margin ${applyAllChecks(status, index, 'responsibility') ? '' : 'd-none'} ${isAdmin ? '' : 'simpleUser'} ${(!isAdmin) && status?.responsibilityStatusId === 1 ? 'd-none' : `${statuses[status?.responsibilityStatusId]}`} ${allChecksResponsibilityOnClickVariable ? (allChecksResponsibilityOnClickVariable?.hasClickableMaterials ? 'hasClickableMaterials' : 'isValid') : ``}`}
                                                            // id={`statusContainer${status?.responsibility?.id}${}`}
                                                            key={`${Math.random()}${status?.responsibility?.id}${index}`}>
                                                            {statusBoxGenerator(status?.responsibility, product.statuses, product.productId, product.clientId, index, product?.projectId, product?.responsibilities, product?.allMaterialStatuses, status?.adminWarning, responsibilityStatusIndex)}
                                                        </div>
                                                    })}
                                                </div>
                                                {selectedProductIndex === index  && selectedProductId === product?.productId && <>
                                                    <div className={'materialStatusesRow'}>
                                                        <div
                                                            className={`status-row noBorder ${product?.measureDone ? '' : 'opacityLow'}`}
                                                            id={`row${index}`}
                                                            key={`${Math.random()}${product?.productId}${index}`}>
                                                            {buttonLoader ?
                                                                <div
                                                                    className={"mb-3 w-100 d-flex justify-content-center"}>
                                                                    <SimpleLoader/></div> : <>
                                                                    <div
                                                                        className={`header-title col-margin checkbox ${isAdmin ? '' : 'd-none'}`}>
                                                                    </div>
                                                                    <div className="client-name col-margin">
                                                                    </div>
                                                                    <div className="product-name col-margin">
                                                                    </div>
                                                                    <div
                                                                        className={`product-name project-name col-margin`}>
                                                                    </div>
                                                                    {product?.statuses?.map((status, statusIndex) => {

                                                                        return <div
                                                                            className={`status-container materialsWrapper col-margin ${isAdmin ? 'admin' : 'notAdmin'} ${applyAllChecks(status, statusIndex, 'material') && applyAllChecks(status, index, 'responsibility') ? '' : 'd-none'} ${status?.responsibilityStatusId === 4 ? 'isValid' : ''} ${allChecksResponsibilityOnClick(product.productId, status?.responsibility.id, product.clientId, status?.responsibilityStatusId, product.statuses, status?.responsibility?.label, index, product?.projectId) ? 'isValid' : `status-box toDo`}`}
                                                                            key={`${Math.random()}${status?.responsibility?.id}${index}${status?.responsibility?.id}`}>
                                                                            <div className={'materialContainer'}>
                                                                                {materialsOfProduct && materialsOfProduct[status?.responsibility?.label]?.map((material, materialIndex) => {
                                                                                    return (<div
                                                                                        className={'position-relative'}>
                                                                                        <div data-toggle="tooltip"
                                                                                             data-placement="top"
                                                                                             title={`${material?.materialName}${material?.userName && material?.userName != 'null' && material?.userName != 'undefined' ? ` nga ${material?.userName || ''}` : ''}`}
                                                                                             className={`${material?.userName} status-box ${isAdmin ? 'admin' : 'notAdmin'} ${allChecksMaterialOnClick(product?.productId, status?.responsibility?.id, material?.materialId, material?.materialName, materialsOfProduct, status?.responsibility?.label, statusIndex, materialIndex, statusIndex === 0 ? [] : product.statuses, material, index, status?.responsibility) ? 'isValid' : ''} ${statuses[material?.responsibilityStatusId]} ${material?.adminWarning ? 'hasWarning' : ''}`}
                                                                                             onClick={() => openUpdatingMaterialStatusModal(product?.productId, status?.responsibility?.id, material?.materialId, material?.materialName, materialsOfProduct, status?.responsibility?.label, statusIndex, materialIndex, statusIndex === 0 ? [] : product.statuses, material, index, status?.responsibility)}>
                                                                                            <p className={'m-0'}>{material?.materialName}</p>
                                                                                            {material?.adminWarning && isAdmin &&
                                                                                            <div
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title={material?.adminWarning}
                                                                                                className={'adminWarningSign'}>
                                                                                                <FontAwesome
                                                                                                    name="info"
                                                                                                />
                                                                                            </div>}
                                                                                        </div>
                                                                                        {material?.temporaryMaterial && ((!props.permissions.observer) || isAdmin) && isAdmin ? <>
                                                                                            <div
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title={"Fshij"}
                                                                                                onClick={() => deleteTemporaryMaterial(material)}
                                                                                                className={'deleteTemporaryMaterial'}>
                                                                                                <FontAwesome
                                                                                                    name="trash"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                data-toggle="tooltip"
                                                                                                data-placement="top"
                                                                                                title={'Edito'}
                                                                                                onClick={() => selectTemporaryMaterialStatus(product, status, material?.materialName, material?.id)}
                                                                                                className={'editTemporaryMaterial'}>
                                                                                                <FontAwesome
                                                                                                    name="edit"
                                                                                                />
                                                                                            </div>
                                                                                        </> : ''}
                                                                                    </div>)
                                                                                })}
                                                                                {((!props.permissions.observer) || isAdmin) &&
                                                                                <div
                                                                                    className={`createTemporaryMaterialStatus`}
                                                                                    onClick={() => selectTemporaryMaterialStatus(product, status)}>
                                                                                    <img src={DifferentColoredPlus}
                                                                                         alt=""/>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </>}
                                                        </div>
                                                    </div>
                                                </>}
                                            </>
                                        )
                                    })}
                                </>
                                :
                                <p className={'m-0 text-center'}><TranslatedText id="app.noData"
                                                                                 defaultMessage="Nuk ka te dhena !"/>
                                </p>
                            }
                        </div>}
                    </>}

                </>
            </div>
            {selectedResponsibilityId && !(selectedMaterial?.materialId) &&
            <div className="update-status-modal-container">
                <div className="update-status-modal">
                    <div className="titleAndTrackerContainer">
                        <p className="modal-title">{selectedResponsibilityLabel} {selectedResponsibilityUser ? `(${selectedResponsibilityUser})` : ''}</p>
                        {responsibilityTimeTrackerInfo?.hasMaterials ? <div className={'infoDatesAndTimesContainer'}>
                            <div className={'infoIconContainer'}
                                 onClick={() => setIsTrackerExtraInfoModalOpen(!isTrackerExtraInfoModalOpen)}>
                                <FontAwesome
                                    name="info"
                                />
                            </div>
                            <div
                                className={`datesAndTimesContainer ${isTrackerExtraInfoModalOpen ? 'open' : 'hidden'}`}>
                                {showSeconds && <>
                                    <div className={'dates'}>
                                        {responsibilityTimeTrackerInfo?.trackersStart && <p><span>{translatedText({
                                            id: "app.startDate",
                                            defaultMessage: "Data e fillimit"
                                        })}:</span> <br/> {responsibilityTimeTrackerInfo?.trackersStart}</p>}
                                        {alreadySetStatusId === 1 && responsibilityTimeTrackerInfo?.trackersEnd &&
                                        <p><span>{translatedText({
                                            id: "app.endDate",
                                            defaultMessage: "Data e mbarimit"
                                        })}:</span><br/> {responsibilityTimeTrackerInfo?.trackersEnd}</p>}
                                    </div>
                                    {alreadySetStatusId === 1 && responsibilityTimeTrackerInfo?.duration != 0 &&
                                    <TimeTracker seconds={responsibilityTimeTrackerInfo?.duration}
                                                 title={translatedText({
                                                     id: "app.productiveTime",
                                                     defaultMessage: "Koha produktive"
                                                 })}/>}
                                    {waitingDuration !== 0 && <>
                                        <TimeTracker seconds={waitingDuration} title={translatedText({
                                            id: "app.waitingTime",
                                            defaultMessage: "Koha e pritjes"
                                        })}/>
                                    </>}
                                </>}
                            </div>
                        </div> : <>
                            {showSeconds && <>
                                <TimeTracker seconds={seconds}/>
                                {waitingDuration !== 0 && <>
                                    <p className="modal-title"><TranslatedText id="app.waitingTime"
                                                                               defaultMessage="Koha e pritjes"/></p>
                                    <TimeTracker seconds={waitingDuration}/>
                                </>}
                            </>}
                        </>}
                        <div className={'extimationWrapper d-none'}>
                            <div className={'timeTrackerContainer estimation'}>
                                <Input
                                    name="estimation"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-100 estimationWrapper m-0"}
                                    type="text"
                                    placeholder={'Kohezgjatja'}
                                    value={timeEstimation || ''}
                                    onChange={(name, value) => setTimeEstimation(value)}
                                >
                                </Input>
                            </div>
                            <MikaSelect selectWrapperClassName={'assigneeUser'} placeholder={'Perdoruesi:'} options={[{value: '2', label: 'Ilir Peci'}]} value={selectedAssignee}
                                        setEntireOptionFunction={setSelectedAssignee}/>
                        </div>
                        {(!responsibilityTimeTrackerInfo?.hasMaterials) && <div className={'selectedPauseReasonContainer'}>
                            <MikaSelect color={'orange'}
                                        selectWrapperClassName={`${selectedPauseReason?.value ? 'paused' : ''}`}
                                        placeholder={'Pauza:'} options={pauseReasons} value={selectedPauseReason}
                                        setEntireOptionFunction={pauseWork}/>

                        </div>}

                    </div>
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                closeUpdatingStatusModal()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                closeUpdatingStatusModal()
                            }}/>
                        </div>
                    </HotkeyButton>
                    {((!responsibilityTimeTrackerInfo?.hasMaterials) || props?.permissions?.fullPrivileges) && <div className="status-container">
                        <div className="status-box-holder">
                            <div className={`status-box completed ${selectedStatusId === 1 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(1)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment"
                                               defaultMessage="Perfundoi me sukses"/></p>
                        </div>
                        {isAdmin && <div className="status-box-holder">
                            <div className={`status-box toDo admin ${selectedStatusId === 2 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(2)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment"
                                               defaultMessage="Per tu realizuar"/></p>
                        </div>}
                        <div className="status-box-holder">
                            <div className={`status-box late ${selectedStatusId === 3 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(3)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p>
                                <TranslatedText id="production.responsibilityAssigment" defaultMessage="Me vonese"/>
                            </p>
                        </div>
                        {(!responsibilityTimeTrackerInfo?.hasMaterials) && <div className="status-box-holder">
                            <div className={`status-box inProcess ${selectedStatusId === 4 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(4)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment" defaultMessage="Ne proces"/>
                            </p>
                        </div>}
                    </div>}
                    <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                        <div className={`comment-section p-2 ${selectedStatusId === 3 ? 'w-50' : 'w-100'}`}>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper m-0"}
                                type="textarea"
                                value={otherComment || ''}
                                onChange={(name, value) => setOtherComment(value)}
                            >
                                <p>Koment</p>
                            </Input>
                        </div>
                        {selectedStatusId === 3 && <div className="comment-section p-2 w-50">
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper m-0"}
                                type="textarea"
                                value={comment || ''}
                                onChange={(name, value) => setComment(value)}
                            >
                                <p>Komenti i voneses</p>
                            </Input>
                        </div>}
                    </div>
                    {isAdmin && <div className="comment-section">
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"w-100 textarea-wrapper m-0"}
                            type="textarea"
                            onChange={(name, value) => {
                                setSelectedAdminWarning(value);
                                setSelectedStatusId(3)
                            }}
                            value={selectedAdminWarning || ''}
                        ><p>Komenti i Adminit</p>
                        </Input>
                    </div>}
                    <div className={'blueprintsFileUploadsContainer d-flex justify-content-between'}>
                        <div className={'w-50'}>
                            <MikaFileInput value={projectUploads}
                                           multiple
                                           setFiles={setProjectUploads}/>
                        </div>
                        <div className={`${isMobile ? 'w-100' : 'w-50 '} allFileContainer`}>
                            {props.production?.blueprintFiles?.length !== 0 && props.production?.blueprintFiles?.map((projectUpload) => {
                                let tempBase64Text = ''
                                convertFileToBase64(projectUpload?.blueprint,(base64Data)=>{
                                    tempBase64Text = base64Data
                                })
                                return (
                                    <div className={'blueprint-file-container'}>
                                        <a target={'_blank'}
                                           href={projectUpload?.blueprint}>{projectUpload?.nameOfBlueprint}</a>
                                        <a href={tempBase64Text} target={'_blank'} className={'downloadButton'} download={projectUpload?.nameOfBlueprint}>
                                            <FontAwesome name={'download'}/>
                                        </a>
                                        {(isAdmin || selectedResponsibilityLabel === BLUEPRINTING || selectedResponsibilityLabel === POSTPROCESING) &&
                                        <div className={'deleteButton'}
                                             onClick={() => deleteBlueprintFile(projectUpload?.id)}>
                                            <img src={PlusIconLink} alt="plus icon link"/>
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        {((!props.permissions.observer) || isAdmin) && <Button
                            isLoading={buttonLoader}
                            isDisabled={buttonLoader}
                            submit={true}
                            className="mt-3 w-25"
                            onClick={() => updateStatus('responsibilityStatus')}
                            buttonType={EButtonType.GreyButton}>
                            <TranslatedText id="app.navigation.next" defaultMessage="Ruaj"/>:
                        </Button>}
                    </div>
                </div>
            </div>}
            <ModalComponent
                isModalHeader
                isOpen={passiveProductModal}
                close={setPassiveProductModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setPassiveProductModal(false)}
                    deleteFunction={() => makeProductPassive()}/>
            </ModalComponent>
            {selectedMaterial?.materialId && <div className="update-status-modal-container">
                <div className="update-status-modal">
                    <div className="titleAndTrackerContainer">
                        <p className="modal-title">{selectedMaterial?.materialName}({selectedMaterial?.responsibility?.label})</p>
                        <TimeTracker seconds={seconds}/>
                        <div className={'extimationWrapper d-none'}>
                            <div className={'timeTrackerContainer estimation'}>
                                <Input
                                    name="estimation"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-100 estimationWrapper m-0"}
                                    type="text"
                                    placeholder={'Kohezgjatja'}
                                    value={timeEstimation || ''}
                                    onChange={(name, value) => setTimeEstimation(value)}
                                >
                                </Input>
                            </div>
                            <MikaSelect selectWrapperClassName={'assigneeUser'} placeholder={'Perdoruesi:'}
                                        options={[{value: '2', label: 'Ilir Peci'}]} value={selectedAssignee}
                                        setEntireOptionFunction={setSelectedAssignee}/>
                        </div>
                        <div className={'selectedPauseReasonContainer'}>
                            <MikaSelect color={'orange'}
                                        selectWrapperClassName={`${selectedPauseReason?.value ? 'paused' : ''}`}
                                        placeholder={'Pauza:'} options={pauseReasons} value={selectedPauseReason}
                                        setEntireOptionFunction={pauseWork}/>
                        </div>
                    </div>
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                closeUpdatingStatusModal()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                closeUpdatingStatusModal()
                            }}/>
                        </div>
                    </HotkeyButton>
                    <div className="status-container">
                        <div className="status-box-holder">
                            <div className={`status-box completed ${selectedStatusId === 1 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(1)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment"
                                               defaultMessage="Perfundoi me sukses"/></p>
                        </div>
                        {isAdmin && <div className="status-box-holder">
                            <div className={`status-box toDo admin ${selectedStatusId === 2 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(2)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment"
                                               defaultMessage="Per tu realizuar"/></p>
                        </div>}
                        <div className="status-box-holder">
                            <div className={`status-box late ${selectedStatusId === 3 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(3)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p>
                                <TranslatedText id="production.responsibilityAssigment" defaultMessage="Me vonese"/>
                            </p>
                        </div>
                        <div className="status-box-holder">
                            <div className={`status-box inProcess ${selectedStatusId === 4 ? 'selected' : ''}`}
                                 onClick={() => changeStatusId(4)}>
                                <div className="info-container">
                                    <FontAwesome
                                        name="info"
                                    />
                                </div>
                            </div>
                            <p><TranslatedText id="production.responsibilityAssigment" defaultMessage="Ne proces"/>
                            </p>
                        </div>
                    </div>
                    <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                        <div className={`comment-section p-2 ${selectedStatusId === 3 ? 'w-50' : 'w-100'}`}>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper m-0"}
                                type="textarea"
                                value={otherComment || ''}
                                onChange={(name, value) => setOtherComment(value)}
                            >
                                <p>{<TranslatedText id="comment" defaultMessage={'Koment'}/>}</p>
                            </Input>
                        </div>
                        {selectedStatusId === 3 && <div className="comment-section p-2 w-50">
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper m-0"}
                                type="textarea"
                                value={comment || ''}
                                onChange={(name, value) => setComment(value)}
                            >
                                <p>{<TranslatedText id="delaysComment" defaultMessage={'Komenti i voneses'}/>}</p>
                            </Input>
                        </div>}
                    </div>
                    {isAdmin && <div className="comment-section">
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"w-100 textarea-wrapper m-0"}
                            type="textarea"
                            onChange={(name, value) => {
                                setSelectedAdminWarning(value);
                                setSelectedStatusId(3)
                            }}
                            value={selectedAdminWarning || ''}
                        >
                            <p>{<TranslatedText id="adminComment" defaultMessage={'Komenti i adminit'}/>}</p>
                        </Input>
                    </div>}
                    <div className={'blueprintsFileUploadsContainer d-flex justify-content-between'}>
                        <div className={'w-50'}>
                            <MikaFileInput value={projectUploads}
                                           multiple
                                           setFiles={setProjectUploads}/>
                        </div>
                        <div className={`${isMobile ? 'w-100' : 'w-50 '} allFileContainer`}>
                            {props.production?.blueprintFiles?.length !== 0 && props.production?.blueprintFiles?.map((projectUpload) => {
                                let tempBase64Text = ''
                                convertFileToBase64(projectUpload?.blueprint, (base64Data) => {
                                    tempBase64Text = base64Data
                                })
                                return (
                                    <div className={'blueprint-file-container'}>
                                        <a target={'_blank'}
                                           href={projectUpload?.blueprint}>{projectUpload?.nameOfBlueprint}</a>
                                        <a href={tempBase64Text} target={'_blank'} className={'downloadButton'}
                                           download={projectUpload?.nameOfBlueprint}>
                                            <FontAwesome name={'download'}/>
                                        </a>
                                        {(isAdmin || selectedResponsibilityLabel === BLUEPRINTING || selectedResponsibilityLabel === POSTPROCESING) &&
                                            <div className={'deleteButton'}
                                                 onClick={() => deleteBlueprintFile(projectUpload?.id)}>
                                                <img src={PlusIconLink} alt="plus icon link"/>
                                            </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        {((!props.permissions.observer) || isAdmin) && <Button
                            isLoading={buttonLoader}
                            isDisabled={buttonLoader}
                            submit={true}
                            className="mt-3 w-25"
                            onClick={() => updateStatus('materialStatus')}
                            buttonType={EButtonType.GreyButton}>
                            <TranslatedText id="app.navigation.next" defaultMessage="Ruaj"/>:
                        </Button>}
                    </div>
                </div>
            </div>}
            {selectedProductStatusRow && <div className="update-status-modal-container selectedProductStatusRow">
                <div className="update-status-modal">
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                closeSelectedProductModal()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                closeSelectedProductModal()
                            }}/>
                        </div>
                    </HotkeyButton>
                    <div className={'selectedProductStatusRowData'}>
                        <p><span>{<TranslatedText id="name"
                                                  defaultMessage={'Emri'}/>}:</span> {selectedProductStatusRow?.clientName}
                        </p>
                        <p><span>{<TranslatedText id="product"
                                                  defaultMessage={'Produkti'}/>}:</span> {selectedProductStatusRow?.productName} ( {selectedProductStatusRow?.productCode} )
                        </p>
                        <p><span>{<TranslatedText id="project"
                                                  defaultMessage={'Projekti'}/>}:</span> {selectedProductStatusRow?.projectName}
                        </p>
                        <p><span>{<TranslatedText id="offerBy"
                                                  defaultMessage={'Oferta nga'}/>}:</span> {selectedProductStatusRow?.offerBy}
                        </p>
                        {isAdmin ?
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <Input
                                    name="date"
                                    value={productStartDate ? new Date(productStartDate) || '' : new Date(selectedProductStatusRow?.start) || ''}
                                    type="date"
                                    onChange={(name, value) => setProductStartDate(value)}
                                    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${productStartDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/>
                                </Input>
                                <Input
                                    name="date"
                                    value={productEndDate ? new Date(productEndDate) || '' : new Date(selectedProductStatusRow?.end) || ''}
                                    type="date"
                                    onChange={(name, value) => setProductEndDate(value)}
                                    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${productEndDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="client.scanDocument" defaultMessage="Data e mbarimit *"/>
                                </Input>
                            </div> :
                            <>
                                <p>
                                    <span>{<TranslatedText id="startDate"
                                                           defaultMessage={''}/>}:</span> {moment(selectedProductStatusRow?.start).format('DD-MM-YYYY')}
                                </p>
                                <p>
                                    <span>{<TranslatedText id="endDate"
                                                           defaultMessage={''}/>}:</span> {moment(selectedProductStatusRow?.end).format('DD-MM-YYYY')}
                                </p>
                            </>}
                        <div>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 m-0 textarea-wrapper"}
                                type="textarea"
                                value={calendarComment ? calendarComment || '' : selectedProductStatusRow?.calendarComment}
                                onChange={(name, value) => setCalendarComment(value)}
                            >
                                <TranslatedText id="app.agreements.appendFinalAgreement"
                                                defaultMessage="Koment per ndyshim te datave"/>
                            </Input>
                        </div>
                        <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                            <div className={`${isMobile ? 'w-100' : 'w-50 '} allFileContainer`}>
                                {selectedProductStatusRow?.projectFiles?.length !== 0 && selectedProductStatusRow?.projectFiles?.map((projectUpload) => {
                                    let tempBase64Text = ''
                                    convertFileToBase64(projectUpload?.blueprint, (base64Data) => {
                                        tempBase64Text = base64Data
                                    })
                                    return (
                                        <div className={'blueprint-file-container'}>
                                            <a target={'_blank'}
                                               href={projectUpload?.fileUrl}>{projectUpload?.fileName}</a>
                                            <a href={tempBase64Text} target={'_blank'} className={'downloadButton'}
                                               download={projectUpload?.fileName}>
                                                <FontAwesome name={'download'}/>
                                            </a>
                                            {(selectedResponsibilityLabel === BLUEPRINTING || selectedResponsibilityLabel === POSTPROCESING || isAdmin) &&
                                                <div className={'deleteButton'}
                                                     onClick={() => deleteBlueprintFile(projectUpload?.id)}>
                                                    <img src={PlusIconLink} alt="plus icon link"/>
                                                </div>}
                                        </div>
                                    )
                                })}
                                <hr></hr>
                                {props.production?.blueprintFiles?.length !== 0 && props.production?.blueprintFiles?.map((projectUpload) => {
                                    let tempBase64Text = ''
                                    convertFileToBase64(projectUpload?.blueprint, (base64Data) => {
                                        tempBase64Text = base64Data
                                    })
                                    return (
                                        <div className={'blueprint-file-container'}>
                                            <a target={'_blank'}
                                               href={projectUpload?.blueprint}>{projectUpload?.nameOfBlueprint}</a>
                                            <a href={tempBase64Text} target={'_blank'} className={'downloadButton'}
                                               download={projectUpload?.nameOfBlueprint}>
                                                <FontAwesome name={'download'}/>
                                            </a>
                                            {(selectedResponsibilityLabel === BLUEPRINTING || selectedResponsibilityLabel === POSTPROCESING || isAdmin) &&
                                                <div className={'deleteButton'}
                                                     onClick={() => deleteBlueprintFile(projectUpload?.id)}>
                                                    <img src={PlusIconLink} alt="plus icon link"/>
                                                </div>}
                                        </div>
                                    )
                                })}
                            </div>
                            {isAdmin && <div
                                className={`d-flex justify-content-end align-items-end ${isMobile ? 'w-100' : 'w-50'}`}>
                                {((!props.permissions.observer) || isAdmin) && <Button
                                    isLoading={props?.buttonLoader}
                                    className={`my-4 ${isMobile ? 'w-100' : 'w-50'}`}
                                    onClick={(e) => saveProductDates()}
                                    buttonType={EButtonType.GreyButton}>
                                    <TranslatedText id="app.save" defaultMessage="Ruaj datat"/>
                                </Button>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {tempMaterialStatus?.productId && <div className={'createTemporaryMaterialStatusModalWrapper'}>
                <div className={'createTemporaryMaterialStatusModal'}>
                    {/*<h1><TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/></h1>*/}
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                closeTemporaryMaterialModal()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                closeTemporaryMaterialModal()
                            }}/>
                        </div>
                    </HotkeyButton>
                    <Input
                        name="materialName"
                        value={tempMaterialStatus?.materialName}
                        type="text"
                        onChange={(name, value) => setTempMaterialStatus({...tempMaterialStatus, materialName: value})}
                        inputWrapperClass={`mt-0 heightFitContent ${productStartDate ? " " : 'does-not-have-data'}`}>
                        <TranslatedText id="client.scanDocument" defaultMessage="Emri i materialit te perkohshem *"/>
                    </Input>
                    {((!props.permissions.observer) || isAdmin) && <Button
                        isLoading={buttonLoader}
                        className={`my-4 w-100`}
                        onClick={(e) => saveTemporaryMaterial()}
                        buttonType={EButtonType.GreyButton}>
                        <TranslatedText id="app.saveMaterial" defaultMessage="Ruaj materialin"/>
                    </Button>}
                </div>
            </div>}
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteTemporaryMaterialConfirmation()}/>
            </ModalComponent>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        user: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ProductStatuses as any)));