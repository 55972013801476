import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import en from './constants/langs/en.json';
import createRootReducer, { RootState } from './reducers/index';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import 'leaflet/dist/leaflet.css';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import App from './App';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory();

let middleware = applyMiddleware(thunk, routerMiddleware(history));

const i18nInitialState = {
    locale: 'en',
    messages: {
        ...en,
    },
};

export const store = createStore(
    createRootReducer(history) as any,
    { intl: i18nInitialState } as any,
    middleware as any
) as Store<RootState>;

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale="en" defaultLocale="en">
            <ConnectedRouter history={history}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <App
                    history={history}
                    // echo={echo}
                /> {/* Pass the 'echo' instance as a prop */}
            </ConnectedRouter>
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
export {};