import React, {useEffect} from 'react';
import './timeTracker.scss'
import {RootState} from "../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../actions/generalActions";
import * as productionActions from "../../actions/production";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {convertHMSToNumber, convertNumberToHMS} from "../../containers/Admin/globalFunctions";

interface Props {
    saveTime?: any //SetStateFunction
    time?: any
    paused?: boolean
    productionActions: any
    responsibilityId?: number
    productId?: number
    production: any,
    seconds: number
    title?: string
}

const TimeTracker = (props: Props) => {

    return (
        <div className={`timeTrackerContainer ${props?.title ? 'hasTitle' : ''}`}>
            {props?.title && <p className={'timeTrackerTitle'}>{props?.title}</p>}
            <p className={'time'}>{convertNumberToHMS(props.seconds)}</p>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(TimeTracker as any)));