import * as constants from '../constants/constants';
import { apiCaller, axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading, setButtonLoading }  from './generalActions'
import { toast } from 'react-toastify';


export const fetchClientMeasure = (payload: any) => wrap(async(dispatch:any) => {
    try {
        // dispatch(setLoading(true))
        // dispatch(setButtonLoading(true)); Dont setLoader to true for this request
        return await apiCaller('GET', `measures/${payload}`).then(res => {
            dispatch({ "type": constants.FETCH_CLIENT_MEASURE, "payload": res });
            // dispatch(setLoading(false))
            // dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_CLIENT_MEASURE_ERROR')

export const getClientMeasureByProjectId = (projectId) => wrap(async(dispatch:any) => {
    try {
        // dispatch(setLoading(true))
        // dispatch(setButtonLoading(true)); Dont setLoader to true for this request
        return await apiCaller('GET', `measures/${projectId}/project`).then(res => {
            dispatch({ "type": constants.FETCH_CLIENT_MEASURE, "payload": res });
            // dispatch(setLoading(false))
            // dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka nuk shkoi mire');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_CLIENT_MEASURE_ERROR')

export const clearAssets = (payload: []): ACTION => ({
    type: constants.CLEAR_ASSETS,
    payload
});

export const createMeasure = (payload:any) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        const headers = {
            "Accept": "multipart/form-data",
            "Content-Type": "multipart/form-data"
        }

        return axiosCaller('POST', 'measures', payload, headers, true).then((response:any) => {
            dispatch({ "type": constants.CREATE_MEASURE, payload: response })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return response      
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'CREATE_MEASURE_ERROR');


export const editMeasure = (id:number, payload:any) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('POST', `measures/${id}`, payload).then((response:any) => {
            dispatch({ "type": constants.EDIT_MEASURE, payload: response })
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'EDIT_MEASURE_ERROR');

export const getAssets = (payload: any) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return apiCaller('GET', `measures/assets/${payload}`).then(res => {
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            dispatch({ "type": constants.GET_ASSETS, "payload": res })
        })
    }
    catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka nuk shkoi mire');
    }
}, 'GET_MEASURE_ASSETS_ERROR')


export const createMeasureTemplate = (payload: object) => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true))
        return axiosCaller('POST', 'measurement-templates', payload).then((res: any) => {
            dispatch({ "type": constants.CREATE_MEASURE_TEMPLATE, payload: res.data.data[0]})
            toast.success('Template per matje u krijua me sukses!')
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error(err.message)
    }
}, 'CREATE_MEASURE_TEMPLATE')


export const getAllMeasureTemplates = () => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `measurement-templates`).then((res: any) => {
            dispatch({ type: constants.GET_ALL_MEASURE_TEMPLATES, payload: res.data.data})
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error(err.message)
    }
}, 'GET_ALL_MEASURE_TEMPLATES')

export const getMeasureTemplate = (id: number) => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('GET', `measurement-templates/${id}`).then((res: any) => {
            dispatch({ type: constants.GET_MEASURE_TEMPLATE, payload: res.data.data})
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error(err.message)
    }
}, 'GET_ALL_MEASURE_TEMPLATES')

export const removeMeasureTemplate = (id: number) => wrap((dispatch: any, state: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true))
        return axiosCaller('DELETE', `measurement-templates/${id}`).then((res: any) => {
            if (res.data.data === true) {
                dispatch(setLoading(false))
                dispatch(setButtonLoading(false));
                toast.info('Template per matje u fshi me sukses!')
                // dispatch(getAllMeasureTemplates())
            }
        })
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error(err.message)
    }
}, 'DELETE_MEASURE_TEMPLATE')