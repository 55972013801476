import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormattedMessage } from 'react-intl';
import { RootState } from '../../reducers';
import './Page404.scss';
import * as actions from '../../actions/generalActions';
// import { Input } from '../../components/Input';
import Error404Svg from '../../assets/images/404-error-3702359-3119148.webp'

interface State {
}

interface Props {
    history: any;
    isLoading: boolean;
    location: any;
    actions: any;
    intl: any;
}

class Page404 extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        document.documentElement.classList.add('notFound');
    }
    
    componentWillUnmount() {
        document.documentElement.classList.remove('notFound');
    }

    renderPage404() {
        return (
            <div className='pageContainer page404'>
                {/*<TranslatedText*/}
                {/*    id="ec.navigation.pageDoesNotExist"*/}
                {/*    defaultMessage="Kjo faqe nuk egziston"*/}
                {/*/>*/}
                <img src={Error404Svg} alt=""/>
            </div>
        )
    }

    render() {
        return (
            <div className="container-fluid journey1 p-0">
                <div>{this.renderPage404()}</div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
