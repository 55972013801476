import * as React from 'react';
import { IInputConfig } from '../Input';
import { defined } from '../../../utils/variableEvaluation';
import { InputText } from '../Text/TextInput';
import { InputSelect } from '../Select/input';
import { InputCheckbox } from '../Checkbox';
import { renderSwitch } from '../../../utils/react';
import { InputRadio } from '../Radio';
import { EInputTextType } from '../Input';
import {InputFile} from "../File";
import {Textarea} from "../Textarea/TextareaInput";
import { InputDate } from "../Date";


export enum EInputType {
    Textarea = 'Textarea',
    File = 'File',
    Text = 'Text',
    Checkbox = 'Checkbox',
    Radio = 'Radio',
    Select = 'Select',
    Custom = 'Custom',
    Date = 'Date',
}

interface IProps {
    inputConfig: IInputConfig;
}

export class InputContent extends React.Component<IProps, {}> {
    private inputType(): EInputType {
        const {
            type,
            selectOptions,
            radioOptions,
            customComponent
        } = this.props.inputConfig;
        if(type === 'textarea') {
            return EInputType.Textarea;
        } else if(type === 'file') {
            return EInputType.File;
        } else if (type === 'checkbox') {
            return EInputType.Checkbox;
        } else if (type === 'date') {
            return EInputType.Date;
        } else if (defined(selectOptions)) {
            return EInputType.Select;
        } else if (defined(radioOptions)) {
            return EInputType.Radio;
        } else if (defined(customComponent)) {
            return EInputType.Custom;
        } else {
            return EInputType.Text;
        }
    }

    handleBlur = () => {
        this.props.inputConfig.onBlur();
    }

    public render(): JSX.Element {
        const {
            checkboxLabel,
            checked,
            customComponent,
            disabled,
            forcedValue,
            inputTextType,
            isFocused,
            isLoading,
            max,
            maxLength,
            min,
            name,
            onBlur,
            valueNeedeed,
            onChange,
            minDate,
            maxDate,
            placeholder,
            radioOptions,
            readOnly,
            step,
            type,
            value,
            filtering,
            id,
            cols,
            rows,
            intl,
            children,
            hasFormikValidation,
            clearable,
            onDropdownOpen,
            onSelected,
            selectOptions,
            isOpen,
            onFocus,
            sort,
            multiple,
            isContact,
            accept
        } = this.props.inputConfig;

        // @ts-ignore
        return renderSwitch(this.inputType(), {
            [EInputType.Custom]: () => customComponent!,
            [EInputType.Checkbox]: () => (
                <InputCheckbox
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    value={value}
                    checked={checked}
                    readOnly={readOnly}
                    label={checkboxLabel}
                    type={type!}
                    isDisabled={disabled}
                    onChange={onChange!}
                    onBlur={onBlur}
                    valueNeedeed={valueNeedeed}
                />
            ),
            [EInputType.Radio]: () => <InputRadio
                radioOptions={radioOptions}
                readOnly={readOnly}
                forcedValue={forcedValue}
                onBlur={onBlur}
            />,
            [EInputType.Select]: () => (
                <InputSelect
                    clearable={clearable}
                    inputTextType={inputTextType || EInputTextType.Material}
                    isDisabled={disabled}
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name!}
                    onBlur={onBlur}
                    onChange={onChange}
                    onDropdownOpen={onDropdownOpen!}
                    onSelected={onSelected}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    selectOptions={selectOptions}
                    type={type!}
                    value={value}
                    filtering={filtering}
                    isOpen={isOpen}
                    onFocus={onFocus}
                    sort={sort}
                />
            ),
            [EInputType.Text]: () => (
                <InputText
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    min={min}
                    max={max}
                    step={step}
                    maxLength={maxLength}
                    value={value}
                    placeholder={placeholder}
                    type={type}
                    readOnly={readOnly}
                    isDisabled={disabled}
                    inputTextType={inputTextType || EInputTextType.Material}
                    onChange={onChange}
                    onBlur={onBlur}
                    filtering={filtering}
                    hasFormikValidation={hasFormikValidation}
                />
            ),
            [EInputType.File]: () => (
                <InputFile
                    id={id}
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    type={type}
                    readOnly={readOnly}
                    isDisabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    intl={intl}
                    children={children}
                    multiple={multiple}
                    isContact
                    accept={accept}
                />
            ),
            [EInputType.Textarea]: () => (
                <Textarea
                    id={id}
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    type={type}
                    readOnly={readOnly}
                    isDisabled={disabled}
                    onChange={onChange}
                    cols={cols}
                    rows={rows}
                    onBlur={onBlur}
                    hasFormikValidation={hasFormikValidation}
                />
            ),
            [EInputType.Date]: () => (
                <InputDate
                    name={name}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    isDisabled={disabled}
                    onChange={onChange}
                />
            ),
        }) as any;
    }
}
