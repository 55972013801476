import {useEffect, useState} from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import { withRouter } from 'react-router';
import { Client, ClientObject } from '../../types/client.type';
import {
    OPTIONS,
    PASSIVE_PRODUCTS
} from '../../components/Table/TableConstants';
import TableBbros from '../../components/Table/TableBbros';
import React from 'react';
import ReplayIcon from "@material-ui/icons/Replay";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {axiosCaller} from "../../utils/apiCaller";
import {fetchPassiveProductStatuses} from "../../actions/production";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import {toast} from "react-toastify";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {Input} from "../../components/Input/Input";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    client: any;
    clientActions: any;
    productionActions: any;
    actions: any,
    offers: any,
    measures: any,
    letter: string,
    clientsByModuleData: Array<Client>
    production: any
    departments: any
    buttonLoader: boolean
}  

const PassiveProducts = (props: Props) => {

    const language:any = TranslatedText

    const [passiveProducts, setPassiveProducts] = useState([])
    const [passiveProductModal, setPassiveProductModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)
    const [productStartDate, setProductStartDate] = useState<any>('')
    const [productEndDate, setProductEndDate] = useState<any>('')
    const [selectedProduct, setSelectedProduct] = useState<any>()


    useEffect(()=>{
        props.productionActions.fetchPassiveProductStatuses()
        props.actions.getAllDepartments()
    },[])

    useEffect(() => {
        let tempProductsStatusArray = []

        let statusRowData = {
            productId: null,
            clientName: '',
            productName: '',
            statuses: [],
            clientId: '',
            start: '',
            end: '',
            projectName: '',
            projectId: null,
            measureDone: false,
            responsibilities: [],
            quantity: 0,
            measureUnit: '',
            allMaterialStatuses: []
        }


        props.production.passiveProducts?.map((productStatus) => {
            let tempResponsibilities = []
            productStatus.statuses?.forEach((status)=>{
                tempResponsibilities?.push({
                    id: status?.responsibility?.id,
                    label: status?.responsibility?.label
                })
            })
            statusRowData = {
                ...statusRowData,
                clientId: productStatus?.client?.id,
                productId: productStatus?.id,
                clientName: productStatus?.client?.name,
                productName: productStatus?.designation?.name,
                statuses: productStatus?.statuses,
                responsibilities: tempResponsibilities,
                start: productStatus?.start,
                end: productStatus?.end,
                projectName: productStatus?.project?.name,
                projectId: productStatus?.project?.id,
                quantity: productStatus?.quantity,
                measureUnit: productStatus?.designation?.unit?.name,
                measureDone: productStatus?.measureStatus === 2,
                allMaterialStatuses: productStatus?.materialStatuses || []
            }

            tempProductsStatusArray.push(statusRowData)
        })

        tempProductsStatusArray.sort(function compare(a, b) {
            let dateA: any = new Date(a.start);
            let dateB: any = new Date(b.start);
            return dateA - dateB;
        });

        setPassiveProducts(tempProductsStatusArray)
    }, [props.production.passiveProducts])


    const actions = [
        {
            icon: ReplayIcon,
            tooltip: 'Ktheni produktin ne aktiv?',
            onClick: (event: Event, rowData) => {saveProductDates(rowData)}
        }
    ]

    function saveProductDates(rowData){
        setSelectedProduct(rowData)
        setPassiveProductModal(true);
        // setStartDate(moment(rowData?.start))
        // setEndDate(moment(rowData?.end))
        setSelectedProductId(rowData?.productId)
    }

    function makeProductActive() {
        if(productStartDate || productEndDate){
            if(productStartDate && productEndDate){
                let productStartDateMs = new Date(productStartDate)?.getTime()
                let productEndDateMs = new Date(productEndDate)?.getTime()
                if(productStartDateMs > productEndDateMs){
                    toast.error('Data e mbarimit smund te jete para fillimit!')
                    return;
                }
            }else{
                toast?.info('Ju lutem mbusni te dyja datat!')
                return;
            }
        }

        let payload = {
            productId: selectedProductId,
            active: 1,
            start: productStartDate,
            end: productEndDate
        }

        axiosCaller('POST', `admin/products/change-start-end-date-and-active`, payload).then(() => {
            props.productionActions.fetchPassiveProductStatuses()
            setPassiveProductModal(false)
        })
    }

    function filterData(){
        if(selectedDepartment?.value || (startDate && endDate)){
            props.productionActions.fetchPassiveProductStatuses(selectedDepartment?.value ? selectedDepartment?.value : null, startDate, endDate)
            setFiltersOpened(false)
        }else{
            if(!selectedDepartment?.value){
                props.productionActions.fetchPassiveProductStatuses()
                setFiltersOpened(false)
                return
            }
            props.productionActions.fetchPassiveProductStatuses()
        }
    }

    return (
        <div>
            <div className="wrapper">
                <div className={`productionFilterButton`}>
                    <div className={'w-100 h-100 position-relative'}>
                        <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                            <FontAwesome name={'filter'}/>
                        </div>
                        <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                            <div className={'filterWrapper'}>
                                <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                    <FontAwesome name={'close'}/>
                                </div>
                                <MikaSelect options={props?.departments} placeholder={'Reparti'} value={selectedDepartment}
                                            setEntireOptionFunction={setSelectedDepartment}/>
                                <div
                                    className={`dateContainer`}>
                                    <p className={'label'}>{translatedText({
                            id: "startDate",
                            defaultMessage: "Data e fillimit"
                        })}</p>
                                    <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                       className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={startDate}
                                               onChange={(e) => setStartDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`dateContainer`}>
                                    <p className={'label'}>{translatedText({
                            id: "endDate",
                            defaultMessage: "Data e mbarimit"
                        })}t</p>
                                    <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {endDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    isLoading={props?.buttonLoader}
                                    isDisabled={props?.buttonLoader}
                                    className="m-auto w-100"
                                    onClick={filterData}
                                    buttonType={EButtonType.GreyButton}>
                                    Filtro
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productionTable">
                    <TableBbros
                        title={translatedText({ id:"table.production", defaultMessage:"Prodhimi"})}
                        data={passiveProducts}
                        columns={PASSIVE_PRODUCTS}
                        options={OPTIONS}
                        actions={actions}
                        isLoading={props.loading}
                    />
                </div>
                <ModalComponent
                    isModalHeader
                    isOpen={passiveProductModal}
                    close={setPassiveProductModal}
                >
                    <DeleteModalComponent
                        history={props.history}
                        close={() => setPassiveProductModal(false)}
                        deleteFunction={() => makeProductActive()} >
                        <div className={'d-flex'}>
                            <Input
                                name="date"
                                value={productStartDate ? new Date(productStartDate) : ''}
                                type="date"
                                onChange={(name, value) => setProductStartDate(value)}
                                inputWrapperClass={`simple-date-input pr-12 mt-0 heightFitContent ${productStartDate ? " " : 'does-not-have-data'}`}>
                                <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/>{selectedProduct?.start ? `: (${moment(selectedProduct?.start)?.format('DD/MM/YYYY') })` : ''}
                            </Input>
                            <Input
                                name="date"
                                value={productEndDate ? new Date(productEndDate) : '' }
                                type="date"
                                onChange={(name, value) => setProductEndDate(value)}
                                inputWrapperClass={`simple-date-input pl-12 mt-0 heightFitContent ${productEndDate ? " " : 'does-not-have-data'}`}>
                                <TranslatedText id="client.scanDocument" defaultMessage="Data e mbarimit *"/>{selectedProduct?.end ? `: (${moment(selectedProduct?.end)?.format('DD/MM/YYYY') })` : ''}
                            </Input>
                        </div>
                    </DeleteModalComponent>
                </ModalComponent>
            </div>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        user: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(PassiveProducts as any)));